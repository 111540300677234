import { CLEAR_ALL_COMMITMENTS, RECEIVE_COMMITMENT_EVENT } from '../util/actionTypes';

const commitmentEventsReducer = (state = null, { type, commitmentInfoState }) => {
  switch (type) {
    case RECEIVE_COMMITMENT_EVENT:
      const commitmentDetails = {};
      const tournamentList = [];

      for (let infoObject of Object.values(commitmentInfoState)) {
        if (infoObject != null) {
          const id = infoObject.playerId;
          const name = infoObject.playerName;
          for (let tournamentItem of infoObject.playerCommitmentTournamentList) {
            const tournamentId = tournamentItem.tournamentID;
            if (commitmentDetails[tournamentId]) {
              commitmentDetails[tournamentId].push({ id, name, item: tournamentItem });
            } else {
              commitmentDetails[tournamentId] = [{ id, name, item: tournamentItem }];
            }

            if (!tournamentList.map((t) => t.id).includes(tournamentId)) {
              tournamentList.push({
                id: tournamentId,
                name: tournamentItem.tournamentScheduleName,
                startDate: tournamentItem.startDate,
                endDate: tournamentItem.endDate,
                tourCode: tournamentItem.tourCode,
              });
            }
          }
        }
      }
      return {
        commitmentDetails,
        tournamentList,
      };
    case CLEAR_ALL_COMMITMENTS:
      return {};
    default:
      return state;
  }
};

export default commitmentEventsReducer;
