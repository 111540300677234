import React, { useEffect, useState } from 'react';
import styles from './Reports.module.css';
import TourApi from '../../api/tourApi';
import Loader from '../Common/Loader/Loader';
import { useSelector } from 'react-redux';
import { TOUR_CODE_BY_SHORTNAME } from '../../util/titles';
import { intlDateFormat } from '../../util/date';
import { useParams } from 'react-router-dom';

function ReorderReport({ globalStyles }) {
  const { tourName, exemptionNumber } = useParams();
  const [loadingState, setLoadingState] = useState(0);
  const [reorderStandings, setReorderStandings] = useState(null);
  const savedStandings = useSelector((state) => state.reorderStandings);

  useEffect(() => {
    const savedExemption = savedStandings[tourName].find((s) => s?.exemption_number?.toString() === exemptionNumber);

    if (savedStandings && savedExemption) {
      setReorderStandings(savedExemption);
      setLoadingState(1);
    } else {
      TourApi.getReorderStandings(TOUR_CODE_BY_SHORTNAME[tourName], exemptionNumber).then((result) => {
        if (result && result[0]) {
          setReorderStandings(result[0]);
          setLoadingState(1);
        } else {
          setLoadingState(-1);
        }
      });
    }
  }, [exemptionNumber, savedStandings, tourName]);

  const minHeight = globalStyles.windowHeight + (globalStyles.isMobileSize ? 140 : 120);
  if (loadingState === 0) {
    return <Loader withWrapper />;
  } else if (loadingState === -1) {
    return (
      <div className={styles.MainContainer}>
        <div className={styles.ReportContainer} style={{ minHeight }}>
          <p>There is no data available for the selected reorder at this time. Please try again later.</p>
        </div>
      </div>
    );
  } else if (loadingState === 1) {
    return (
      <div className={styles.MainContainer}>
        <div className={styles.ReportContainer} style={{ minHeight }}>
          <h1>{reorderStandings.exemption_name}</h1>
          <p className={styles.Info}>Last updated on {intlDateFormat(reorderStandings.last_updated, 'yn-mn-dn-hn-tn')}</p>
          <div className={styles.ReportContent}>
            <table>
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Player Name</th>
                  <th>Points</th>
                </tr>
              </thead>
              <tbody>
                {reorderStandings.players.map((player) => (
                  <tr key={`${player.rank}-${player.playerId}`}>
                    <td>{player.rank}</td>
                    <td>
                      {player.first_name} {player.last_name}
                    </td>
                    <td>{player.points}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default ReorderReport;
