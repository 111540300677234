import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Navigation.module.css';
import DropdownMenu from './DropdownMenu';
import MegaMenu from './MegaMenu';
import MobileMenu from './MobileMenu';
import { connect } from 'react-redux';
import { PGA_TOUR, CHAMPIONS_TOUR, KORN_FERRY_TOUR, PGA_TOUR_AMERICAS } from '../../../util/titles';
import { CODE_LOOKUP, getTabMap } from '../../../util/tabMap';
import { withLocation } from '../../../util/routerHooks';

class Navigation extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      showAccountDropdown: false,
      showPlayers: false,
      showPgaTour: false,
      showChampionsTour: false,
      showKornFerryTour: false,
      showAmericasTour: false,
      showMobileMenu: false,
    };

    this.menuClickHandler = this.menuClickHandler.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    if (this.props.dataToken !== '' && this.props.dataToken !== undefined) {
      this.setState({ token: this.props.dataToken });
    }
  }

  render() {
    const navStyle = {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: this.props.globalStyles.navigationHeight,
      zIndex: '900',
    };

    if (['/dashboard', '/ye-statements'].includes(this.props.location.pathname) || !this.props.profile) {
      return null;
    } else {
      let topGridColumns;
      if (this.props.globalStyles.isMobileSize) {
        topGridColumns = '50% auto 50px 50px';
        if (this.props.profile.type === 'player') {
          return (
            <nav style={navStyle} className={styles.NavigationContainer}>
              <div
                className={styles.TopContainer}
                style={{
                  gridTemplateColumns: topGridColumns,
                  alignItems: 'center',
                }}
              >
                <div
                  className={styles.NavigationItem}
                  style={{
                    justifySelf: 'left',
                    borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                  }}
                >
                  <Link to="/dashboard/player">home</Link>
                </div>
                <div></div>
                {!this.state.showAccountDropdown ? (
                  <div
                    className={styles.NavigationItem}
                    style={{
                      justifySelf: 'center',
                      padding: '0.75rem 1rem',
                      borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                      borderLeft: `1px solid rgba(255, 255, 255, 0.17)`,
                    }}
                    onClick={() => this.displayDropdown('account')}
                  >
                    <i className="fas fa-user-circle" />
                  </div>
                ) : (
                  <div
                    className={[styles.NavigationItem, styles.TopNav_Active].join(' ')}
                    style={{
                      justifySelf: 'center',
                      padding: '0.75rem 1rem',
                      borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                      borderLeft: `1px solid rgba(255, 255, 255, 0.17)`,
                    }}
                    onClick={() => this.displayDropdown('account')}
                  >
                    <i className="fas fa-user-circle" />
                  </div>
                )}

                {this.state.showMobileMenu ? (
                  <div
                    className={[styles.NavigationItem, styles.TopNav_Active].join(' ')}
                    style={{ justifySelf: 'center', padding: '0.75rem 1rem' }}
                    onClick={() => this.displayDropdown('mobilemenu')}
                  >
                    <i className="fas fa-times" />
                  </div>
                ) : (
                  <div
                    className={styles.NavigationItem}
                    style={{ justifySelf: 'center', padding: '0.75rem 1rem' }}
                    onClick={() => this.displayDropdown('mobilemenu')}
                  >
                    <i className="fas fa-bars" />
                  </div>
                )}
              </div>
              {this.state.showAccountDropdown && (
                <DropdownMenu
                  type="player-account"
                  globalStyles={this.props.globalStyles}
                  logout={this.props.logout}
                  clickHandler={this.menuClickHandler}
                />
              )}
              {this.state.showMobileMenu && (
                <div>
                  <MobileMenu globalStyles={this.props.globalStyles} role="player" clickHandler={this.menuClickHandler} />
                </div>
              )}
            </nav>
          );
        } else if (this.props.profile.type === 'agent') {
          return (
            <nav style={navStyle} className={styles.NavigationContainer}>
              <div
                className={styles.TopContainer}
                style={{
                  gridTemplateColumns: topGridColumns,
                  alignItems: 'center',
                }}
              >
                <div
                  className={styles.NavigationItem}
                  style={{
                    justifySelf: 'left',
                    borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                  }}
                >
                  <Link to="/dashboard/agent">home</Link>
                </div>
                <div></div>
                {!this.state.showAccountDropdown ? (
                  <div
                    className={styles.NavigationItem}
                    style={{
                      justifySelf: 'center',
                      padding: '0.75rem 1rem',
                      borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                      borderLeft: `1px solid rgba(255, 255, 255, 0.17)`,
                    }}
                    onClick={() => this.displayDropdown('account')}
                  >
                    <i className="fas fa-user-circle"></i>
                  </div>
                ) : (
                  <div
                    className={[styles.NavigationItem, styles.TopNav_Active].join(' ')}
                    style={{
                      justifySelf: 'center',
                      padding: '0.75rem 1rem',
                      borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                      borderLeft: `1px solid rgba(255, 255, 255, 0.17)`,
                    }}
                    onClick={() => this.displayDropdown('account')}
                  >
                    <i className="fas fa-user-circle"></i>
                  </div>
                )}

                {this.state.showMobileMenu ? (
                  <div
                    className={[styles.NavigationItem, styles.TopNav_Active].join(' ')}
                    style={{ justifySelf: 'center', padding: '0.75rem 1rem' }}
                    onClick={() => this.displayDropdown('mobilemenu')}
                  >
                    <i className="fas fa-times"></i>
                  </div>
                ) : (
                  <div
                    className={styles.NavigationItem}
                    style={{ justifySelf: 'center', padding: '0.75rem 1rem' }}
                    onClick={() => this.displayDropdown('mobilemenu')}
                  >
                    <i className="fas fa-bars"></i>
                  </div>
                )}
              </div>
              {this.state.showAccountDropdown && (
                <DropdownMenu
                  type="agent-account"
                  globalStyles={this.props.globalStyles}
                  logout={this.props.logout}
                  clickHandler={this.menuClickHandler}
                />
              )}
              {this.state.showMobileMenu && <MobileMenu globalStyles={this.props.globalStyles} role="agent" clickHandler={this.menuClickHandler} />}
            </nav>
          );
        } else if (this.props.profile.type === 'financial') {
          topGridColumns = '50% auto 50px';
          return (
            <nav style={navStyle} className={styles.NavigationContainer}>
              <div
                className={styles.TopContainer}
                style={{
                  gridTemplateColumns: topGridColumns,
                  alignItems: 'center',
                }}
              >
                <div
                  className={styles.NavigationItem}
                  style={{
                    justifySelf: 'left',
                    borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                  }}
                >
                  <Link to="/dashboard/financial">home</Link>
                </div>
                <div></div>
                {!this.state.showAccountDropdown ? (
                  <div
                    className={styles.NavigationItem}
                    style={{
                      justifySelf: 'center',
                      padding: '0.75rem 1rem',
                      borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                      borderLeft: `1px solid rgba(255, 255, 255, 0.17)`,
                    }}
                    onClick={() => this.displayDropdown('account')}
                  >
                    <i className="fas fa-user-circle"></i>
                  </div>
                ) : (
                  <div
                    className={[styles.NavigationItem, styles.TopNav_Active].join(' ')}
                    style={{
                      justifySelf: 'center',
                      padding: '0.75rem 1rem',
                      borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                      borderLeft: `1px solid rgba(255, 255, 255, 0.17)`,
                    }}
                    onClick={() => this.displayDropdown('account')}
                  >
                    <i className="fas fa-user-circle"></i>
                  </div>
                )}
              </div>
              {this.state.showAccountDropdown && (
                <DropdownMenu
                  type="financial-account"
                  globalStyles={this.props.globalStyles}
                  logout={this.props.logout}
                  clickHandler={this.menuClickHandler}
                />
              )}
              {this.state.showMobileMenu && (
                <MobileMenu globalStyles={this.props.globalStyles} role="financial" clickHandler={this.menuClickHandler} />
              )}
            </nav>
          );
        } else {
          return <></>;
        }
      } else if (this.props.globalStyles.isTabletSize) {
        topGridColumns = 'fit-content(200px) fit-content(250px) auto 50px 50px';
        if (this.props.profile.type === 'player') {
          return (
            <nav style={navStyle} className={styles.Container}>
              <div
                className={styles.TopContainer}
                style={{
                  gridTemplateColumns: topGridColumns,
                  alignItems: 'end',
                }}
              >
                <Link to="/dashboard/player">
                  <div
                    className={styles.NavigationItem}
                    style={{
                      justifySelf: 'left',
                      paddingTop: '1rem',
                      borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                    }}
                  >
                    home
                  </div>
                </Link>
                <Link to="/worksheet">
                  <div
                    className={styles.NavigationItem}
                    style={{
                      justifySelf: 'left',
                      paddingTop: '1rem',
                      borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                    }}
                  >
                    my commitments
                  </div>
                </Link>
                <div></div>
                {!this.state.showAccountDropdown ? (
                  <div
                    className={styles.NavigationItem}
                    style={{
                      justifySelf: 'center',
                      borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                      borderLeft: `1px solid rgba(255, 255, 255, 0.17)`,
                      padding: '0.75rem 1rem',
                    }}
                    onClick={() => this.displayDropdown('account')}
                  >
                    <i className="fas fa-user-circle"></i>
                  </div>
                ) : (
                  <div
                    className={[styles.NavigationItem, styles.TopNav_Active].join(' ')}
                    style={{
                      justifySelf: 'center',
                      borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                      borderLeft: `1px solid rgba(255, 255, 255, 0.17)`,
                    }}
                    onClick={() => this.displayDropdown('account')}
                  >
                    <i className="fas fa-user-circle"></i>
                  </div>
                )}

                {this.state.showMobileMenu ? (
                  <div
                    className={[styles.NavigationItem, styles.TopNav_Active].join(' ')}
                    style={{ justifySelf: 'center' }}
                    onClick={() => this.displayDropdown('mobilemenu')}
                  >
                    <i className="fas fa-times"></i>
                  </div>
                ) : (
                  <div className={styles.NavigationItem} style={{ justifySelf: 'center' }} onClick={() => this.displayDropdown('mobilemenu')}>
                    <i className="fas fa-bars"></i>
                  </div>
                )}
              </div>
              {this.state.showAccountDropdown && (
                <DropdownMenu
                  type="player-account"
                  globalStyles={this.props.globalStyles}
                  logout={this.props.logout}
                  clickHandler={this.menuClickHandler}
                />
              )}
              {this.state.showMobileMenu && <MobileMenu globalStyles={this.props.globalStyles} role="player" clickHandler={this.menuClickHandler} />}
            </nav>
          );
        } else if (this.props.profile.type === 'agent') {
          return (
            <nav style={navStyle} className={styles.Container}>
              <div
                className={styles.TopContainer}
                style={{
                  gridTemplateColumns: topGridColumns,
                  alignItems: 'end',
                }}
              >
                <Link to="/dashboard/agent">
                  <div
                    className={styles.NavigationItem}
                    style={{
                      justifySelf: 'left',
                      paddingTop: '1rem',
                      borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                    }}
                  >
                    home
                  </div>
                </Link>
                {!this.state.showPlayers ? (
                  <div
                    className={styles.NavigationItem}
                    style={{
                      justifySelf: 'center',
                      paddingTop: '1rem',
                      borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                    }}
                    onClick={() => this.displayDropdown('player')}
                  >
                    my player's commitments
                    <i className={[styles.ArrowIcon, 'fas fa-chevron-down'].join(' ')}></i>
                  </div>
                ) : (
                  <div
                    className={[styles.NavigationItem, styles.TopNav_Active].join(' ')}
                    style={{
                      justifySelf: 'center',
                      paddingTop: '1rem',
                      borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                    }}
                    onClick={() => this.displayDropdown('player')}
                  >
                    my player's commitments
                    <i className={['fas fa-chevron-up', styles.ArrowIcon].join(' ')}></i>
                  </div>
                )}
                <div></div>
                {!this.state.showAccountDropdown ? (
                  <div
                    className={styles.NavigationItem}
                    style={{
                      justifySelf: 'center',
                      borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                      borderLeft: `1px solid rgba(255, 255, 255, 0.17)`,
                      padding: '0.75rem 1rem',
                    }}
                    onClick={() => this.displayDropdown('account')}
                  >
                    <i className="fas fa-user-circle"></i>
                  </div>
                ) : (
                  <div
                    className={[styles.NavigationItem, styles.TopNav_Active].join(' ')}
                    style={{
                      justifySelf: 'center',
                      borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                      borderLeft: `1px solid rgba(255, 255, 255, 0.17)`,
                    }}
                    onClick={() => this.displayDropdown('account')}
                  >
                    <i className="fas fa-user-circle"></i>
                  </div>
                )}

                {this.state.showMobileMenu ? (
                  <div
                    className={[styles.NavigationItem, styles.TopNav_Active].join(' ')}
                    style={{ justifySelf: 'center' }}
                    onClick={() => this.displayDropdown('mobilemenu')}
                  >
                    <i className="fas fa-times"></i>
                  </div>
                ) : (
                  <div className={styles.NavigationItem} style={{ justifySelf: 'center' }} onClick={() => this.displayDropdown('mobilemenu')}>
                    <i className="fas fa-bars"></i>
                  </div>
                )}
              </div>
              {this.state.showAccountDropdown && (
                <DropdownMenu
                  type="agent-account"
                  globalStyles={this.props.globalStyles}
                  logout={this.props.logout}
                  clickHandler={this.menuClickHandler}
                />
              )}
              {this.state.showPlayers && <DropdownMenu type="player" clickHandler={this.menuClickHandler} globalStyles={this.props.globalStyles} />}
              {this.state.showMobileMenu && <MobileMenu globalStyles={this.props.globalStyles} role="agent" clickHandler={this.menuClickHandler} />}
            </nav>
          );
        } else {
          topGridColumns = '50px auto 50px';
          return (
            <nav style={navStyle} className={styles.Container}>
              <div
                className={styles.TopContainer}
                style={{
                  gridTemplateColumns: topGridColumns,
                  alignItems: 'end',
                }}
              >
                <Link to="/dashboard/financial">
                  <div
                    className={styles.NavigationItem}
                    style={{
                      justifySelf: 'left',
                      paddingTop: '1rem',
                      borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                    }}
                  >
                    home
                  </div>
                </Link>

                <div></div>
                {!this.state.showAccountDropdown ? (
                  <div
                    className={styles.NavigationItem}
                    style={{
                      justifySelf: 'center',
                      borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                      borderLeft: `1px solid rgba(255, 255, 255, 0.17)`,
                      padding: '0.75rem 1rem',
                    }}
                    onClick={() => this.displayDropdown('account')}
                  >
                    <i className="fas fa-user-circle"></i>
                  </div>
                ) : (
                  <div
                    className={[styles.NavigationItem, styles.TopNav_Active].join(' ')}
                    style={{
                      justifySelf: 'center',
                      borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                      borderLeft: `1px solid rgba(255, 255, 255, 0.17)`,
                    }}
                    onClick={() => this.displayDropdown('account')}
                  >
                    <i className="fas fa-user-circle"></i>
                  </div>
                )}
              </div>
              {this.state.showAccountDropdown && (
                <DropdownMenu
                  type="financial-account"
                  globalStyles={this.props.globalStyles}
                  logout={this.props.logout}
                  clickHandler={this.menuClickHandler}
                />
              )}
              {this.state.showPlayers && <DropdownMenu type="player" clickHandler={this.menuClickHandler} globalStyles={this.props.globalStyles} />}
              {this.state.showMobileMenu && <MobileMenu globalStyles={this.props.globalStyles} role="agent" clickHandler={this.menuClickHandler} />}
            </nav>
          );
        }
      } else {
        topGridColumns = `fit-content(150px) fit-content(230px) auto fit-content(80px)
            fit-content(200px)`;
        var tourContent;
        if (this.props.profile.type === 'agent') {
          tourContent = ['R', 'H', 'S', 'Y'].map((item, i) => {
            switch (item) {
              case 'R':
                return (
                  <div key={i}>
                    {!this.state.showPgaTour ? (
                      <div className={styles.NavigationItemB} onClick={() => this.displayDropdown('pga')}>
                        {PGA_TOUR}
                        <i className={[styles.ArrowIconSecond, 'fas fa-chevron-down'].join(' ')}></i>
                      </div>
                    ) : (
                      <div className={styles.NavigationItemB} onClick={() => this.displayDropdown('pga')}>
                        {PGA_TOUR}
                        <i className={[styles.ArrowIconSecond, 'fas fa-chevron-up'].join(' ')}></i>
                        <div className={styles.NavigationItem_ActivePGA}></div>
                      </div>
                    )}
                  </div>
                );

              case 'S':
                return (
                  <div key={i}>
                    {!this.state.showChampionsTour ? (
                      <div className={styles.NavigationItemB} onClick={() => this.displayDropdown('champions')}>
                        {CHAMPIONS_TOUR}
                        <i className={[styles.ArrowIconSecond, 'fas fa-chevron-down'].join(' ')}></i>
                      </div>
                    ) : (
                      <div className={styles.NavigationItemB} onClick={() => this.displayDropdown('champions')}>
                        {CHAMPIONS_TOUR}
                        <i className={[styles.ArrowIconSecond, 'fas fa-chevron-up'].join(' ')}></i>
                        <div className={styles.NavigationItem_ActiveCHAMP}></div>
                      </div>
                    )}
                  </div>
                );

              case 'H':
                return (
                  <div key={i}>
                    {' '}
                    {!this.state.showKornFerryTour ? (
                      <div className={styles.NavigationItemB} onClick={() => this.displayDropdown('kornFerry')}>
                        {KORN_FERRY_TOUR}
                        <i className={[styles.ArrowIconSecond, 'fas fa-chevron-down'].join(' ')}></i>
                      </div>
                    ) : (
                      <div className={styles.NavigationItemB} onClick={() => this.displayDropdown('kornFerry')}>
                        {KORN_FERRY_TOUR}
                        <i className={[styles.ArrowIconSecond, 'fas fa-chevron-up'].join(' ')}></i>
                        <div className={styles.NavigationItem_ActiveKF}></div>
                      </div>
                    )}
                  </div>
                );

              case 'Y':
                return (
                  <div key={i}>
                    {!this.state.showCanadaTour ? (
                      <div className={styles.NavigationItemB} onClick={() => this.displayDropdown('americas')}>
                        {PGA_TOUR_AMERICAS}
                        <i className={[styles.ArrowIconSecond, 'fas fa-chevron-down'].join(' ')}></i>
                      </div>
                    ) : (
                      <div className={styles.NavigationItemB} onClick={() => this.displayDropdown('americas')}>
                        {PGA_TOUR_AMERICAS}
                        <i className={[styles.ArrowIconSecond, 'fas fa-chevron-up'].join(' ')}></i>
                        <div className={styles.NavigationItem_ActivePGAC}></div>
                      </div>
                    )}
                  </div>
                );
              default:
                return null;
            }
          });
        } else if (this.props.profile.type === 'financial') {
          tourContent = ['R', 'H', 'S', 'Y'].map((item, i) => {
            switch (item) {
              case 'R':
                return (
                  <div key={i}>
                    {!this.state.showPgaTour ? (
                      <div className={styles.NavigationItemB} onClick={() => this.displayDropdown('pga')}>
                        {PGA_TOUR}
                        <i className={[styles.ArrowIconSecond, 'fas fa-chevron-down'].join(' ')}></i>
                      </div>
                    ) : (
                      <div className={styles.NavigationItemB} onClick={() => this.displayDropdown('pga')}>
                        {PGA_TOUR}
                        <i className={[styles.ArrowIconSecond, 'fas fa-chevron-up'].join(' ')}></i>
                        <div className={styles.NavigationItem_ActivePGA}></div>
                      </div>
                    )}
                  </div>
                );

              case 'S':
                return (
                  <div key={i}>
                    {!this.state.showChampionsTour ? (
                      <div className={styles.NavigationItemB} onClick={() => this.displayDropdown('champions')}>
                        {CHAMPIONS_TOUR}
                        <i className={[styles.ArrowIconSecond, 'fas fa-chevron-down'].join(' ')}></i>
                      </div>
                    ) : (
                      <div className={styles.NavigationItemB} onClick={() => this.displayDropdown('champions')}>
                        {CHAMPIONS_TOUR}
                        <i className={[styles.ArrowIconSecond, 'fas fa-chevron-up'].join(' ')}></i>
                        <div className={styles.NavigationItem_ActiveCHAMP}></div>
                      </div>
                    )}
                  </div>
                );

              case 'H':
                return (
                  <div key={i}>
                    {' '}
                    {!this.state.showKornFerryTour ? (
                      <div className={styles.NavigationItemB} onClick={() => this.displayDropdown('kornFerry')}>
                        {KORN_FERRY_TOUR}
                        <i className={[styles.ArrowIconSecond, 'fas fa-chevron-down'].join(' ')}></i>
                      </div>
                    ) : (
                      <div className={styles.NavigationItemB} onClick={() => this.displayDropdown('kornFerry')}>
                        {KORN_FERRY_TOUR}
                        <i className={[styles.ArrowIconSecond, 'fas fa-chevron-up'].join(' ')}></i>
                        <div className={styles.NavigationItem_ActiveKF}></div>
                      </div>
                    )}
                  </div>
                );

              case 'Y':
                return (
                  <div key={i}>
                    {!this.state.showCanadaTour ? (
                      <div className={styles.NavigationItemB} onClick={() => this.displayDropdown('americas')}>
                        {PGA_TOUR_AMERICAS}
                        <i className={[styles.ArrowIconSecond, 'fas fa-chevron-down'].join(' ')}></i>
                      </div>
                    ) : (
                      <div className={styles.NavigationItemB} onClick={() => this.displayDropdown('americas')}>
                        {PGA_TOUR_AMERICAS}
                        <i className={[styles.ArrowIconSecond, 'fas fa-chevron-up'].join(' ')}></i>
                        <div className={styles.NavigationItem_ActivePGAC}></div>
                      </div>
                    )}
                  </div>
                );
              default:
                return null;
            }
          });
        } else {
          const tabMap = getTabMap(this.props.profile.tourCodes.filter((code) => !!CODE_LOOKUP[code]));

          tourContent = tabMap.map((item, i) => {
            switch (item.tourCode) {
              case 'R':
                return (
                  <div key={i}>
                    {!this.state.showPgaTour ? (
                      <div
                        className={styles.NavigationItemB}
                        onMouseEnter={() => this.displayDropdown('pga')}
                        onMouseLeave={() => this.displayDropdown('pga')}
                      >
                        {PGA_TOUR}
                        <i className={[styles.ArrowIconSecond, 'fas fa-chevron-down'].join(' ')}></i>
                      </div>
                    ) : (
                      <div
                        className={styles.NavigationItemB}
                        onMouseEnter={() => this.displayDropdown('pga')}
                        onMouseLeave={() => this.displayDropdown('pga')}
                      >
                        {PGA_TOUR}
                        <i className={[styles.ArrowIconSecond, 'fas fa-chevron-up'].join(' ')}></i>
                        <div className={styles.NavigationItem_ActivePGA}></div>
                        <MegaMenu type="pga" role="player" clickHandler={this.menuClickHandler} dataToken={this.props.dataToken} />
                      </div>
                    )}
                  </div>
                );

              case 'S':
                return (
                  <div key={i}>
                    {!this.state.showChampionsTour ? (
                      <div
                        className={styles.NavigationItemB}
                        onMouseEnter={() => this.displayDropdown('champions')}
                        onMouseLeave={() => this.displayDropdown('champions')}
                      >
                        {CHAMPIONS_TOUR}
                        <i className={[styles.ArrowIconSecond, 'fas fa-chevron-down'].join(' ')}></i>
                      </div>
                    ) : (
                      <div
                        className={styles.NavigationItemB}
                        onMouseEnter={() => this.displayDropdown('champions')}
                        onMouseLeave={() => this.displayDropdown('champions')}
                      >
                        {CHAMPIONS_TOUR}
                        <i className={[styles.ArrowIconSecond, 'fas fa-chevron-up'].join(' ')}></i>
                        <div className={styles.NavigationItem_ActiveCHAMP}></div>
                        <MegaMenu type="champions" role="player" clickHandler={this.menuClickHandler} />
                      </div>
                    )}
                  </div>
                );

              case 'H':
                return (
                  <div key={i}>
                    {' '}
                    {!this.state.showKornFerryTour ? (
                      <div
                        className={styles.NavigationItemB}
                        onMouseEnter={() => this.displayDropdown('kornFerry')}
                        onMouseLeave={() => this.displayDropdown('kornFerry')}
                      >
                        {KORN_FERRY_TOUR}
                        <i className={[styles.ArrowIconSecond, 'fas fa-chevron-down'].join(' ')}></i>
                      </div>
                    ) : (
                      <div
                        className={styles.NavigationItemB}
                        onMouseEnter={() => this.displayDropdown('kornFerry')}
                        onMouseLeave={() => this.displayDropdown('kornFerry')}
                      >
                        {KORN_FERRY_TOUR}
                        <i className={[styles.ArrowIconSecond, 'fas fa-chevron-up'].join(' ')}></i>
                        <div className={styles.NavigationItem_ActiveKF}></div>

                        <MegaMenu type="kornFerry" role="player" clickHandler={this.menuClickHandler} />
                      </div>
                    )}
                  </div>
                );

              case 'Y':
                return (
                  <div key={i}>
                    {!this.state.showAmericasTour ? (
                      <div
                        className={styles.NavigationItemB}
                        onMouseEnter={() => this.displayDropdown('americas')}
                        onMouseLeave={() => this.displayDropdown('americas')}
                      >
                        {PGA_TOUR_AMERICAS}
                        <i className={[styles.ArrowIconSecond, 'fas fa-chevron-down'].join(' ')}></i>
                      </div>
                    ) : (
                      <div
                        className={styles.NavigationItemB}
                        onMouseEnter={() => this.displayDropdown('americas')}
                        onMouseLeave={() => this.displayDropdown('americas')}
                      >
                        {PGA_TOUR_AMERICAS}
                        <i className={[styles.ArrowIconSecond, 'fas fa-chevron-up'].join(' ')}></i>
                        <div className={styles.NavigationItem_ActivePGAC}></div>

                        <MegaMenu type="americas" role="player" clickHandler={this.menuClickHandler} />
                      </div>
                    )}
                  </div>
                );
              default:
                return null;
            }
          });
        }
      }
      if (this.props.profile.type === 'player') {
        return (
          <nav style={navStyle} className={styles.Container}>
            <div
              className={styles.TopContainer}
              style={{
                gridTemplateColumns: topGridColumns,
                alignItems: 'end',
              }}
            >
              <Link to="/dashboard/player">
                <div
                  className={styles.NavigationItem}
                  style={{
                    borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                  }}
                >
                  home
                </div>
              </Link>
              <Link to="/worksheet">
                <div
                  className={styles.NavigationItem}
                  style={{
                    borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                  }}
                >
                  my commitments
                </div>
              </Link>
              <div></div>
              <Link to="/contact">
                <div
                  className={styles.NavigationItem}
                  style={{
                    borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                    borderLeft: `1px solid rgba(255, 255, 255, 0.17)`,
                  }}
                >
                  contact
                </div>
              </Link>
              {!this.state.showAccountDropdown ? (
                <div
                  className={styles.NavigationItem}
                  onMouseEnter={() => this.displayDropdown('account')}
                  onMouseLeave={() => this.displayDropdown('account')}
                >
                  hello {this.props.profile.firstName}
                  <i className={[styles.ArrowIcon, 'fas fa-chevron-down'].join(' ')}></i>
                </div>
              ) : (
                <div
                  className={[styles.NavigationItem, styles.TopNav_Active].join(' ')}
                  onMouseEnter={() => this.displayDropdown('account')}
                  onMouseLeave={() => this.displayDropdown('account')}
                >
                  hello {this.props.profile.firstName}
                  <i className={[styles.ArrowIcon, 'fas fa-chevron-up'].join(' ')}></i>
                  <DropdownMenu
                    type="player-account"
                    globalStyles={this.props.globalStyles}
                    logout={this.props.logout}
                    clickHandler={this.menuClickHandler}
                  />
                </div>
              )}
            </div>

            {!this.state.isLoading && <div className={styles.BottomContainer}>{tourContent}</div>}
          </nav>
        );
      } else if (this.props.profile.type === 'financial') {
        return (
          <nav style={navStyle} className={styles.Container}>
            <div
              className={styles.TopContainer}
              style={{
                gridTemplateColumns: topGridColumns,
                alignItems: 'end',
              }}
            >
              <Link to="/dashboard/financial">
                <div
                  className={styles.NavigationItem}
                  style={{
                    borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                  }}
                >
                  home
                </div>
              </Link>
              <div></div>
              <div></div>
              <div></div>

              {!this.state.showAccountDropdown ? (
                <div
                  className={styles.NavigationItem}
                  onMouseEnter={() => this.displayDropdown('account')}
                  onMouseLeave={() => this.displayDropdown('account')}
                >
                  hello {this.props.profile.firstName}
                  <i className={[styles.ArrowIcon, 'fas fa-chevron-down'].join(' ')}></i>
                </div>
              ) : (
                <div
                  className={[styles.NavigationItem, styles.TopNav_Active].join(' ')}
                  onMouseEnter={() => this.displayDropdown('account')}
                  onMouseLeave={() => this.displayDropdown('account')}
                >
                  hello {this.props.profile.firstName}
                  <i className={[styles.ArrowIcon, 'fas fa-chevron-up'].join(' ')}></i>
                  <DropdownMenu
                    type="agent-account"
                    globalStyles={this.props.globalStyles}
                    logout={this.props.logout}
                    clickHandler={this.menuClickHandler}
                  />
                </div>
              )}
            </div>

            {this.state.showPlayers && <DropdownMenu type="player" clickHandler={this.menuClickHandler} globalStyles={this.props.globalStyles} />}

            <div className={styles.BottomContainer}></div>
          </nav>
        );
      } else {
        return (
          <nav style={navStyle} className={styles.Container}>
            <div
              className={styles.TopContainer}
              style={{
                gridTemplateColumns: topGridColumns,
                alignItems: 'end',
              }}
            >
              <Link to="/dashboard/agent">
                <div
                  className={styles.NavigationItem}
                  style={{
                    borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                  }}
                >
                  home
                </div>
              </Link>

              {!this.state.showPlayers ? (
                <div
                  className={styles.NavigationItem}
                  style={{
                    borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                  }}
                  onClick={() => this.displayDropdown('player')}
                >
                  my player's commitments
                  <i className={[styles.ArrowIcon, 'fas fa-chevron-down'].join(' ')}></i>
                </div>
              ) : (
                <div
                  className={[styles.NavigationItem, styles.TopNav_Active].join(' ')}
                  style={{
                    borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                  }}
                  onClick={() => this.displayDropdown('player')}
                >
                  my player's commitments
                  <i className={[styles.ArrowIcon, 'fas fa-chevron-up'].join(' ')}></i>
                </div>
              )}

              <div></div>

              <Link to="/contact">
                <div
                  className={styles.NavigationItem}
                  style={{
                    borderRight: `1px solid rgba(255, 255, 255, 0.17)`,
                    borderLeft: `1px solid rgba(255, 255, 255, 0.17)`,
                  }}
                >
                  contact
                </div>
              </Link>

              {!this.state.showAccountDropdown ? (
                <div
                  className={styles.NavigationItem}
                  onMouseEnter={() => this.displayDropdown('account')}
                  onMouseLeave={() => this.displayDropdown('account')}
                >
                  hello {this.props.profile.firstName}
                  <i className={[styles.ArrowIcon, 'fas fa-chevron-down'].join(' ')}></i>
                </div>
              ) : (
                <div
                  className={[styles.NavigationItem, styles.TopNav_Active].join(' ')}
                  onMouseEnter={() => this.displayDropdown('account')}
                  onMouseLeave={() => this.displayDropdown('account')}
                >
                  hello {this.props.profile.firstName}
                  <i className={[styles.ArrowIcon, 'fas fa-chevron-up'].join(' ')}></i>
                  <DropdownMenu
                    type="agent-account"
                    globalStyles={this.props.globalStyles}
                    logout={this.props.logout}
                    clickHandler={this.menuClickHandler}
                  />
                </div>
              )}
            </div>

            {this.state.showPlayers && <DropdownMenu type="player" clickHandler={this.menuClickHandler} globalStyles={this.props.globalStyles} />}

            <div className={styles.BottomContainer}>
              {!this.state.showPgaTour ? (
                <div
                  className={styles.NavigationItemB}
                  onMouseEnter={() => this.displayDropdown('pga')}
                  onMouseLeave={() => this.displayDropdown('pga')}
                >
                  {PGA_TOUR}
                  <i className={[styles.ArrowIconSecond, 'fas fa-chevron-down'].join(' ')}></i>
                </div>
              ) : (
                <div
                  className={styles.NavigationItemB}
                  onMouseEnter={() => this.displayDropdown('pga')}
                  onMouseLeave={() => this.displayDropdown('pga')}
                >
                  {PGA_TOUR}
                  <i className={[styles.ArrowIconSecond, 'fas fa-chevron-up'].join(' ')}></i>
                  <div className={styles.NavigationItem_ActivePGA}></div>
                  <MegaMenu type="pga" role="agent" clickHandler={this.menuClickHandler} dataToken={this.props.dataToken} />
                </div>
              )}

              {!this.state.showChampionsTour ? (
                <div
                  className={styles.NavigationItemB}
                  onMouseEnter={() => this.displayDropdown('champions')}
                  onMouseLeave={() => this.displayDropdown('champions')}
                >
                  {CHAMPIONS_TOUR}
                  <i className={[styles.ArrowIconSecond, 'fas fa-chevron-down'].join(' ')}></i>
                </div>
              ) : (
                <div
                  className={styles.NavigationItemB}
                  onMouseEnter={() => this.displayDropdown('champions')}
                  onMouseLeave={() => this.displayDropdown('champions')}
                >
                  {CHAMPIONS_TOUR}
                  <i className={[styles.ArrowIconSecond, 'fas fa-chevron-up'].join(' ')}></i>
                  <div className={styles.NavigationItem_ActiveCHAMP}></div>
                  <MegaMenu type="champions" role="agent" clickHandler={this.menuClickHandler} />
                </div>
              )}

              {!this.state.showKornFerryTour ? (
                <div
                  className={styles.NavigationItemB}
                  onMouseEnter={() => this.displayDropdown('kornFerry')}
                  onMouseLeave={() => this.displayDropdown('kornFerry')}
                >
                  {KORN_FERRY_TOUR}
                  <i className={[styles.ArrowIconSecond, 'fas fa-chevron-down'].join(' ')}></i>
                </div>
              ) : (
                <div
                  className={styles.NavigationItemB}
                  onMouseEnter={() => this.displayDropdown('kornFerry')}
                  onMouseLeave={() => this.displayDropdown('kornFerry')}
                >
                  {KORN_FERRY_TOUR}
                  <i className={[styles.ArrowIconSecond, 'fas fa-chevron-up'].join(' ')}></i>
                  <div className={styles.NavigationItem_ActiveKF}></div>
                  <MegaMenu type="kornFerry" role="agent" clickHandler={this.menuClickHandler} />
                </div>
              )}
              {!this.state.showAmericasTour ? (
                <div
                  className={styles.NavigationItemB}
                  onMouseEnter={() => this.displayDropdown('americas')}
                  onMouseLeave={() => this.displayDropdown('americas')}
                >
                  {PGA_TOUR_AMERICAS}
                  <i className={[styles.ArrowIconSecond, 'fas fa-chevron-down'].join(' ')}></i>
                </div>
              ) : (
                <div
                  className={styles.NavigationItemB}
                  onMouseEnter={() => this.displayDropdown('americas')}
                  onMouseLeave={() => this.displayDropdown('americas')}
                >
                  {PGA_TOUR_AMERICAS}
                  <i className={[styles.ArrowIconSecond, 'fas fa-chevron-up'].join(' ')}></i>
                  <div className={styles.NavigationItem_ActivePGAC}></div>
                  <MegaMenu type="americas" role="agent" clickHandler={this.menuClickHandler} />
                </div>
              )}
            </div>
          </nav>
        );
      }
    }
  }

  menuClickHandler() {
    if (this._isMounted) {
      this.setState({
        showAccountDropdown: false,
        showPlayers: false,
        showPgaTour: false,
        showChampionsTour: false,
        showKornFerryTour: false,
        showAmericasTour: false,
        showMobileMenu: false,
      });
    }
  }

  displayDropdown(type) {
    if (this._isMounted) {
      switch (type) {
        case 'account':
          this.setState({
            showAccountDropdown: !this.state.showAccountDropdown,
            showPlayers: false,
            showPgaTour: false,
            showChampionsTour: false,
            showKornFerryTour: false,
            showAmericasTour: false,
            showMobileMenu: false,
          });
          break;
        case 'player':
          this.setState({
            showAccountDropdown: false,
            showPlayers: !this.state.showPlayers,
            showPgaTour: false,
            showChampionsTour: false,
            showKornFerryTour: false,
            showAmericasTour: false,
            showMobileMenu: false,
          });
          break;
        case 'pga':
          this.setState({
            showAccountDropdown: false,
            showPlayers: false,
            showPgaTour: !this.state.showPgaTour,
            showChampionsTour: false,
            showKornFerryTour: false,
            showAmericasTour: false,
            showMobileMenu: false,
          });
          break;
        case 'champions':
          this.setState({
            showAccountDropdown: false,
            showPlayers: false,
            showPgaTour: false,
            showChampionsTour: !this.state.showChampionsTour,
            showKornFerryTour: false,
            showAmericasTour: false,
            showMobileMenu: false,
          });
          break;
        case 'kornFerry':
          this.setState({
            showAccountDropdown: false,
            showPlayers: false,
            showPgaTour: false,
            showChampionsTour: false,
            showKornFerryTour: !this.state.showKornFerryTour,
            showAmericasTour: false,
            showMobileMenu: false,
          });
          break;
        case 'americas':
          this.setState({
            showAccountDropdown: false,
            showPlayers: false,
            showPgaTour: false,
            showChampionsTour: false,
            showKornFerryTour: false,
            showAmericasTour: !this.state.showAmericasTour,
            showMobileMenu: false,
          });
          break;
        case 'mobilemenu':
          this.setState({
            showAccountDropdown: false,
            showPlayers: false,
            showPgaTour: false,
            showChampionsTour: false,
            showKornFerryTour: false,
            showAmericasTour: false,
            showMobileMenu: !this.state.showMobileMenu,
          });
          break;
        default:
          this.setState({
            showAccountDropdown: false,
            showPlayers: false,
            showPgaTour: false,
            showChampionsTour: false,
            showKornFerryTour: false,
            showAmericasTour: false,
            showMobileMenu: false,
          });
          break;
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
}

const mapStateToProps = (state) => {
  const profile = state.profile;
  return { profile };
};

export default withLocation(connect(mapStateToProps)(Navigation));
