import React from 'react';
import './Loader.css';

export default function Loader({ height = 80, width = 80, withWrapper, wrapperStyle }) {
  const svg = (
    <svg width={width} height={height} viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="#055eba">
      <circle cx="15" cy="15" r={15} className="svg-circle-1" />
      <circle cx="60" cy="15" r={9} attributeName="fillOpacity" className="svg-circle-2" />
      <circle cx="105" cy="15" r={15} className="svg-circle-3" />
    </svg>
  );

  if (withWrapper) {
    return <div style={wrapperStyle || { textAlign: 'center', padding: '25% 0' }}>{svg}</div>;
  } else {
    return svg;
  }
}
