import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './Login.module.css';
import pgaLogo from '../img/logo_pgabandw.png';
import championsLogo from '../img/logo_champions.png';
import kfLogo from '../img/logo_kornferry.png';
import americasLogo from '../img/logo_americas.png';
import StyledAlert from '../Common/Alerts/StyledAlert';
import StyledButton from '../Common/Buttons/StyledButton';
import SharepointApi from '../../api/sharepointApi';
import { useDispatch, useSelector } from 'react-redux';
import { CLEAR_ALL_COMMITMENTS, CLEAR_ALL_TOURNAMENT_PAGES, CLEAR_TOKENS } from '../../util/actionTypes';

export default function LandingPage({ globalStyles }) {
  const [alertContent, setAlertContent] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const loggedIn = useSelector((state) => !!state.token.accessToken);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: CLEAR_TOKENS });
    dispatch({ type: CLEAR_ALL_COMMITMENTS });
    dispatch({ type: CLEAR_ALL_TOURNAMENT_PAGES });
    SharepointApi.getContent('pages/eeb768d4-242b-49cf-a76d-b6e062c88724').then((result) => {
      const alertContent = result?.webParts[0]?.innerHtml;
      if (!result.error && alertContent?.length > 20) {
        setAlertContent(alertContent);
      }
    });
  }, [dispatch]);

  function handleEntry(e) {
    e.preventDefault();
    const returnPath = new URLSearchParams(location.search).get('returnPath');
    if (loggedIn) {
      navigate(returnPath ? returnPath : '/dashboard');
    } else {
      navigate(returnPath ? `/login?returnPath=${returnPath}` : '/login');
    }
  }

  return (
    <div className={styles.LoginContainer}>
      <div className={styles.BackgroundImage} />
      <div className={styles.Shade} />
      {alertContent && (
        <div className={styles.AlertWindow}>
          <StyledAlert type="primary" alertContent={alertContent} handler={() => setAlertContent('')} />
        </div>
      )}

      <h1 className={styles.HeaderText}>Welcome To PGA TOUR LINKS</h1>
      <StyledButton type="primary" title="Enter" handler={handleEntry} />
      <div className={styles.FooterContainer} style={{ padding: globalStyles.isMobileSize ? '0 2rem' : '0' }}>
        <div className={styles.LandingLogos} style={{ height: globalStyles.isMobileSize ? '80px' : '130px' }}>
          <img src={championsLogo} alt="The PGA Champions Tour logo" />
          <img src={kfLogo} alt="The PGA Korn Ferry Tour logo" />
          <img src={pgaLogo} alt="The PGA Tour logo" />
          <img src={americasLogo} alt="PGA Tour Americas logo" />
        </div>
      </div>
    </div>
  );
}
