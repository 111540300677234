const theFetchRetry = require('fetch-retry')(fetch);

function fetchRetry(url, options = {}) {
  return theFetchRetry(url, {
    retries: 3,
    retryDelay: 1000,
    retryOn: [400, 401, 402, 403, 404],
    ...options,
  });
}
export default fetchRetry;
