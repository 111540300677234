import React from 'react';
import PlayerLayout from './Dashboard/PlayerLayout';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

export default function YearEndStatements({ globalStyles }) {
  const profile = useSelector((state) => state.profile);
  if (profile?.type === 'player') {
    return <PlayerLayout globalStyles={globalStyles} directToYearEndStatement />;
  } else {
    return <Navigate to="/dashboard/agent" />;
  }
}
