import React from 'react';
import styles from './Reports.module.css';
import TourApi from '../../api/tourApi';
import { getFixedLength } from '../../util/generalUtils';
import { intlDateFormatNumeric } from '../../util/date';
import Loader from '../Common/Loader/Loader';
import { withParams } from '../../util/routerHooks';

class HoleLocations extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    const { eventId } = this.props.params;
    this._isMounted = true;

    TourApi.getTournamentDetails(eventId).then((res) => {
      if (this._isMounted) {
        this.setState({
          isLoading: false,
          courses: res[0].courses,
          tournamentId: res[0].id,
          tournamentName: res[0].Tournament_Name,
          rounds: res[0].rounds,
          tournamentStartDate: res[0].start_date,
          tournamentEndDate: res[0].end_date,
          eventId: eventId,
        });
      }
    });
  }

  render() {
    const { isLoading, courses, rounds, tournamentId, tournamentName, tournamentStartDate, tournamentEndDate, eventId } = this.state;
    if (isLoading) {
      return <Loader withWrapper />;
    } else if (courses !== undefined) {
      const tournamentNum = getFixedLength(tournamentId, 3);
      const locationLinks = courses.map((course) => (
        <ul key={course.id} className={styles.HoleList}>
          {rounds.map((round) => (
            <li key={round.short_name} className={styles.HoleLinkItem}>
              <a
                href={`/sites/PGATOURLINKS/SiteAssets/Hole%20Locations/holelocations-${eventId[0]}-${tournamentNum}-${getFixedLength(course.id, 3)}-${
                  round.short_name[1]
                }.pdf`}
                rel="noreferrer"
                target="_blank"
                tabIndex="0"
                className={styles.HoleLink}
              >
                Round #{round.short_name[1]} - {course.name}
              </a>
            </li>
          ))}
        </ul>
      ));
      const startDate = new Date(tournamentStartDate + 'Z');
      const endDate = new Date(tournamentEndDate + 'Z');

      return (
        <div className={styles.MainContainer}>
          <div
            style={{
              textAlign: 'center',
              minHeight: this.props.globalStyles.isMobileSize
                ? this.props.globalStyles.windowHeight + 140
                : this.props.globalStyles.windowHeight + 120,
            }}
          >
            <h1>Hole Locations for {tournamentName}</h1>
            <h2>
              Scheduled Dates: {intlDateFormatNumeric(startDate)} - {intlDateFormatNumeric(endDate)}
            </h2>
            <p>Hole Locations may not be published until the day before the round begins.</p>
            {locationLinks}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
}

export default withParams(HoleLocations);
