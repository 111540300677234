import React, { useEffect, useState } from 'react';
import styles from './WelcomeHeader.module.css';
import { Card, CardContent, Typography } from '@mui/material';

export default function WelcomeHeader({ title, tagline, bannerImage, globalStyles }) {
  const [theImageUrl, setTheImageUrl] = useState();

  useEffect(() => {
    setTheImageUrl(require(`../../img/banner_${bannerImage}.png`));
  }, [bannerImage]);

  const height = globalStyles.isMobileSize ? '200px' : '150px';
  return (
    <>
      <div className={styles.Container} style={{ height }}>
        <h1 className={styles.Heading}>{title}</h1>
        <p className={styles.SubHeading}>{tagline}</p>
        <div className={styles.BackgroundImage} style={{ height, backgroundImage: `url(${theImageUrl})` }} />
        <div className={styles.Shade} style={{ height }} />
      </div>
      {/* <Card
                style={{
                margin: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                }}
            >
            <CardContent>
                <Typography variant="subtitle1" color="error" align="center">
                    07/11/2023 - The Links website and mobile app are&nbsp;currently
                    experiencing technical problems. ​​​​​​​We're actively working
                    towards a solution. Please check back soon.
                </Typography>
            </CardContent>
            </Card> */}
    </>
  );
}
