import React from 'react';
import styles from './CommitmentWorkSheet.module.css';
import WelcomeHeader from '../../Common/Headers/WelcomeHeader';
import FieldAlert from '../../Common/Alerts/FieldAlert';
import ErrorModal from '../../Common/Modals/ErrorModal';
import { Link } from 'react-router-dom';
import CommitmentApi from '../../../api/commitmentApi';
import { connect } from 'react-redux';
import {
  ALL_TOUR_CODES,
  CHAMPIONS_TOUR,
  decodePlayer,
  encodePlayer,
  KORN_FERRY_TOUR,
  PGA_TOUR_AMERICAS,
  PGA_TOUR,
  TOUR_NAMES_BY_CODE,
} from '../../../util/titles';
import teamStyles from './TeamCommitment.module.css';
import { receiveCommitmentEvents, receiveCommitmentInfo, receiveTeamField } from '../../../actions/commitmentActions';
import { playerIdTourCodeLookup } from '../../../reducers/commitmentInfoReducer';
import { getDataFromField } from '../../../util/generalUtils';
import Loader from '../../Common/Loader/Loader';
import Modal from '../../Common/Modals/Modal';
import { canWithdrawFromTour, getCommitmentData, getPositionInFieldString, isCommitted } from '../../../util/commitmentUtils';
import { withParamsLocationNavigation } from '../../../util/routerHooks';
import { CODE_LOOKUP } from '../../../util/tabMap';

class CommitmentWorkSheet extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    const { playerId } = this.props.params;
    const playerIdString = decodePlayer(playerId);

    this.state = {
      isLoading: true,
      isError: false,
      token: '',
      tourCode: null,
      tourCodes: [],
      strengthOfField: false,
      playerId: playerIdString,
      hasZurichBeenPlayed: false,
      commitmentTournamentList: [],
      selectedTournamentName: '',
      view: 'player',
      commitToTournaments: [],
      showWithdrawConfirmModal: false,
    };

    this.handleCommitWithdrawClick = this.handleCommitWithdrawClick.bind(this);
    this.handleWithdrawToggle = this.handleWithdrawToggle.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchPlayerData(this.state.playerId, true);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const previousPlayerId = decodePlayer(prevProps.params.playerId);
    const currentPlayerId = decodePlayer(this.props.params.playerId);
    if (previousPlayerId !== currentPlayerId) {
      this.setState({ playerId: currentPlayerId }, () => this.fetchPlayerData(this.state.playerId, true));
    }
  }

  setStatePlayerView(newTourCode, newPlayerId = undefined) {
    const playerId = newPlayerId ? newPlayerId : this.state.playerId;
    const tourCodes = this.props.profile.players.find((player) => player.playerId === playerId)?.tourCodes || [];
    const tourCode = newTourCode || tourCodes[0];
    const data = this.props.commitmentInfo[playerIdTourCodeLookup(playerId, tourCode)] || {};
    if (this._isMounted) {
      this.setState({
        isLoading: false,
        strengthOfField: data.hasMetStrengthOfFieldRequirement,
        hasZurichBeenPlayed: data.hasZurichBeenPlayed,
        playerName: data.playerName,
        commitmentTournamentList: data.playerCommitmentTournamentList || [],
        tourCodes,
        tourCode,
        playerId,
      });
    }
  }

  getPromisesAndTeamEventIds(playerId, tourCodes, ignoreCacheAndCallTheApis = false) {
    let teamEventIds = [];
    let promises = [];
    const appendTournamentIds = (theList) => {
      for (let tournament of theList) {
        if (tournament.officialTeamFormat && !teamEventIds.includes(tournament.tournamentScheduleId)) {
          teamEventIds.push(tournament.tournamentScheduleId);
        }
      }
    };
    for (let tourCode of tourCodes) {
      const cachedData = this.props.commitmentInfo[playerIdTourCodeLookup(playerId, tourCode)];
      if (ignoreCacheAndCallTheApis || !cachedData) {
        promises.push(
          CommitmentApi.getPlayerCommitmentWorksheet(playerId, tourCode, this.props.token.id_token).then((response) => {
            if (response.data) {
              this.props.receiveCommitmentInfo(response);
              this.props.receiveCommitmentEvents();
              appendTournamentIds(response.data.playerCommitmentTournamentList);
            }
          }),
        );
      } else {
        appendTournamentIds(cachedData.playerCommitmentTournamentList);
      }
    }
    return { promises, teamEventIds };
  }

  fetchAllPlayersData() {
    const { players } = this.props.profile;
    let allPromises = [],
      allTeamEventIds = [];
    for (let player of players) {
      const { promises, teamEventIds } = this.getPromisesAndTeamEventIds(player.playerId, ALL_TOUR_CODES, true);
      allPromises.push(...promises);
      allTeamEventIds.push(...teamEventIds);
    }

    Promise.all(allPromises).then(() => {
      allTeamEventIds.forEach((tournamentScheduleId) => {
        const cachedTeamField = this.props.teamField[tournamentScheduleId];
        if (!cachedTeamField) {
          CommitmentApi.getTeamField(tournamentScheduleId, this.props.token.id_token).then((teamResp) => {
            this.props.receiveTeamField(tournamentScheduleId, teamResp.data);
          });
        }
      });
      this.setStateEventView();
    });
  }

  fetchPlayerData(playerId, ignoreCacheAndCallTheApis) {
    this.setState({ isLoading: true });
    const tourCodes = this.props.profile.players.find((player) => player.playerId === playerId)?.tourCodes || [];
    const { promises, teamEventIds } = this.getPromisesAndTeamEventIds(playerId, tourCodes, ignoreCacheAndCallTheApis);

    Promise.all(promises).then(() => {
      teamEventIds.forEach((tournamentScheduleId) => {
        const cachedTeamField = this.props.teamField[tournamentScheduleId];
        if (!cachedTeamField) {
          CommitmentApi.getTeamField(tournamentScheduleId, this.props.token.id_token).then((teamResp) => {
            this.props.receiveTeamField(tournamentScheduleId, teamResp.data);
          });
        }
      });
      this.setStatePlayerView();
      const tourCodes = this.props.profile.players.find((player) => player.playerId === this.state.playerId)?.tourCodes || [];
      const tourCode = this.state.tourCode || tourCodes[0];
      this.setState({ tourCode, tourCodes });
    });
  }

  setStateEventView(newTournamentId = undefined, newPlayerId = undefined, newTourCode = undefined) {
    const tourCode = newTourCode ? newTourCode : this.state.tourCode || this.state.players[0].tourCodes[0];
    let playerId = newPlayerId ? newPlayerId : this.props.profile.players[0].playerId;
    if (newTournamentId === undefined) {
      let commInfo = this.props.commitmentInfo[playerIdTourCodeLookup(playerId, tourCode)];
      let index = 1;
      while (!commInfo && index < this.props.profile.players.length) {
        playerId = this.props.profile.players[index].playerId;
        commInfo = this.props.commitmentInfo[playerIdTourCodeLookup(playerId, tourCode)];
        index++;
      }
      const commTournamentList = commInfo?.playerCommitmentTournamentList;
      newTournamentId = commTournamentList && commTournamentList[0]?.tournamentID;
    }

    const commitmentDetails = newTournamentId ? this.props.commitmentEvents.commitmentDetails[newTournamentId] : [];
    const tournamentList = this.props.commitmentEvents.tournamentList.filter((tournament) => tournament.tourCode === tourCode);
    const nextTournament = tournamentList.find((tournament) => tournament.id === newTournamentId);
    this.setState({
      selectedTournamentName: nextTournament?.name || '',
      selectedTournamentStartDate: nextTournament?.startDate || null,
      selectedTournamentEndDate: nextTournament?.endDate || null,
      tournamentId: newTournamentId,
      eventCommitmentList: commitmentDetails,
      eventTournamentList: tournamentList,
      tourCode,
      playerId,
    });
  }

  changeDataSource(type) {
    if (type === 'player') {
      if (this._isMounted) {
        this.setStatePlayerView();
      }
    } else if (type === 'event') {
      if (this._isMounted) {
        const { players } = this.props.profile;
        if (players === undefined) {
          alert('No players associated with agent account. Please contact support.');
        }
        this.fetchAllPlayersData();
      }
    }
  }

  redirectToPlayerView = (event) => {
    const newPlayerId = event.target.id;
    if (this._isMounted) {
      this.setState({
        view: 'player',
      });
      this.setStatePlayerView(this.state.tourCode, newPlayerId);
    }
  };

  errorClickHandler = () => {
    if (this._isMounted) {
      this.setState({
        isError: false,
      });
    }
  };

  handleWithdrawToggle(e) {
    e.target.blur();
    this.setState({ commitToTournaments: [] });
  }

  render() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const justCommittedSuccess = searchParams.get('commitSuccess') === 'true';
    const isWithdrawPage = searchParams.get('withdraw') === 'true';
    const {
      isLoading,
      commitmentTournamentList,
      eventTournamentList,
      eventCommitmentList,
      selectedTournamentName,
      selectedTournamentStartDate,
      selectedTournamentEndDate,
      view,
      isError,
      commitToTournaments,
      tourCodes,
      tourCode,
      playerId,
    } = this.state;
    const { profile } = this.props;
    const tCodes = tourCodes.filter((code) => !!CODE_LOOKUP[code]).filter((tCode) => !isWithdrawPage || canWithdrawFromTour(tCode));
    const tourOptions = tCodes.map((code) => (
      <option key={code} value={code}>
        {TOUR_NAMES_BY_CODE[code]}
      </option>
    ));

    if (isLoading || (view === 'event' && eventTournamentList === undefined)) {
      return <Loader withWrapper />;
    } else {
      let commitmentData;
      const lastUpdatedDate = new Intl.DateTimeFormat('en-US').format(new Date());
      if (view === 'player') {
        var playerOptions = profile.players.map((p) => (
          <option key={p.playerId} value={p.playerId}>
            {p.playerName}
          </option>
        ));

        commitmentData = getCommitmentData(commitmentTournamentList, isWithdrawPage, this.handleCommitmentChange, profile, tourCode, playerId);
      } else if (view === 'event') {
        var tournamentOptions = eventTournamentList.map((item, i) => {
          return (
            <option value={item.id} key={i}>
              {item.name}
            </option>
          );
        });

        commitmentData = eventCommitmentList.map((item, i) => {
          var commitmentControl;
          if (isCommitted(item.item.commitmentCode) && !item.item.officialTeamFormat) {
            if (item.item.satisfiesStrengthOfFieldRequirement) {
              if (item.item.duallyCommittedTournaments.length > 0) {
                commitmentControl = (
                  <div>
                    <i className="fas fa-check-square" style={{ color: '#9AA5B3' }}></i>{' '}
                    <i className={['fas fa-question-circle', styles.Tooltip].join(' ')} style={{ color: '#e8222b', margin: '0 0.25rem' }}>
                      <span className={styles.TooltipText}>{item.item.duallyCommittedTournaments[0].name}</span>
                    </i>{' '}
                    <i className="fas fa-info-circle" style={{ color: '#167F06' }}></i>
                  </div>
                );
              } else {
                commitmentControl = (
                  <div>
                    <i className="fas fa-check-square" style={{ color: '#9AA5B3' }}></i>{' '}
                    <i className="fas fa-info-circle" style={{ color: '#167F06' }}></i>
                  </div>
                );
              }
            } else {
              if (item.item.duallyCommittedTournaments.length > 0) {
                commitmentControl = (
                  <div>
                    <i className="fas fa-check-square" style={{ color: '#9AA5B3' }}></i>{' '}
                    <i className={['fas fa-question-circle', styles.Tooltip].join(' ')} style={{ color: '#e8222b', margin: '0 0.25rem' }}>
                      <span className={styles.TooltipText}>{item.item.duallyCommittedTournaments[0].name}</span>
                    </i>
                  </div>
                );
              } else {
                commitmentControl = (
                  <div>
                    <i className="fas fa-check-square" style={{ color: '#9AA5B3' }}></i>{' '}
                  </div>
                );
              }
            }
          } else if (
            item.item.officialTeamFormat &&
            (isCommitted(item.item.commitmentCode) ||
              (item.item.canCommit &&
                item.item.playersCanCommit &&
                (item.item.tournamentStatusCode === 'O' || item.item.tournamentStatusCode === 'P')))
          ) {
            const path = { commit: 'create/commit', view: 'view', 'add partner': 'create/select' };
            const { playerTeamConfirmed } = getDataFromField(item.item.tournamentScheduleId, item.id);
            commitmentControl =
              item.item.teamEventLink == null ? null : (
                <>
                  <Link
                    className={styles.ButtonLink}
                    to={`/worksheet/team/${path[item.item.teamEventLink.toLowerCase()]}/${item.item.tournamentScheduleId}?tour=${
                      item.item.tourCode
                    }&player=${encodePlayer(item.id)}`}
                  >
                    {item.item.teamEventLink}
                  </Link>
                  <span className={teamStyles.ConfirmedIcon}>{playerTeamConfirmed ? <i className="fas fa-check-circle" /> : <></>}</span>
                </>
              );
          } else {
            commitmentControl = <div></div>;
          }

          const positionInFieldString = getPositionInFieldString(item.item);
          const showProAm = ['R', 'H'].includes(item.item.tourCode) && item.item.fieldApproved && item.item.sponsorValue;
          const showCommitmentNumber = isCommitted(item.item.commitmentCode);
          const showFieldLink = !item.item.fieldCalculating && item.item.fieldApproved && item.item.eligibleForCommitments;

          return (
            <tr key={i}>
              <td>{commitmentControl}</td>
              <td>
                <div tabIndex="0" id={item.id} className={styles.PlayerNameLink} onClick={this.redirectToPlayerView}>
                  {item.name}
                </div>
              </td>
              <td>{showCommitmentNumber ? item.item.commitmentNumber : ''}</td>
              <td>
                {showFieldLink ? (
                  <Link
                    to={`/field/${item.item.tournamentScheduleId}/${item.item.officialTeamFormat ? 'team-report' : 'report'}?code=${
                      this.state.tourCode
                    }`}
                  >
                    {positionInFieldString}
                  </Link>
                ) : (
                  positionInFieldString
                )}
              </td>
              {['R', 'H'].includes(item.item.tourCode) ? (
                <td>
                  {showProAm ? (
                    <Link to={`/field/${item.item.tournamentScheduleId}/${item.item.officialTeamFormat ? 'team-proam' : 'proam'}`}>View</Link>
                  ) : (
                    ''
                  )}
                </td>
              ) : null}
            </tr>
          );
        });
      }

      const teamEventNamesList = this.state.commitmentTournamentList.filter((t) => t.officialTeamFormat).map((t) => t.tournamentScheduleName);

      const withdrawModalTitle = (
        <>
          Withdraw <span className={styles.Lighter}>{this.state.playerName}</span> from {''}
          {commitToTournaments.length} tournament{commitToTournaments.length > 1 && 's'}?
        </>
      );
      const withdrawModalPrompt = <p>Are you sure you want to withdraw from the following tournament{commitToTournaments.length > 1 && 's'}?</p>;
      const canWithdrawFromThisTour = canWithdrawFromTour(tourCode);

      return (
        <div className={styles.MainContainer}>
          <ErrorModal open={isError} onClose={this.errorClickHandler} isWithdraw={isWithdrawPage} />
          <Modal
            open={this.state.showWithdrawConfirmModal}
            submitText="Withdraw"
            onCancel={() => this.setState({ showWithdrawConfirmModal: false })}
            onSubmit={() => this.postCommitment(true)}
            title={withdrawModalTitle}
          >
            {withdrawModalPrompt}
            <ul>
              {this.state.commitmentTournamentList
                .filter((t) => this.state.commitToTournaments.includes(t.tournamentScheduleId.toString()))
                .map((t) => (
                  <li key={t.tournamentScheduleId}>{t.tournamentScheduleName}</li>
                ))}
            </ul>
          </Modal>
          <WelcomeHeader
            globalStyles={this.props.globalStyles}
            title="My Player's Commitments"
            tagline="your resource for tournament and event commitments."
            bannerImage="commitment"
          />
          <div className={styles.CommitmentsWorksheet}>
            {this.state.view === 'player' ? (
              <div className={styles.TabRow}>
                <div className={[styles.TabHeader, styles.TabHeader_Active].join(' ')}>Player View</div>
                <div
                  className={styles.TabHeader}
                  onClick={() => {
                    this.changeDataSource('event');
                    this.setState({ view: 'event' });
                  }}
                >
                  Event View
                </div>
              </div>
            ) : (
              <div className={styles.TabRow}>
                <div
                  className={styles.TabHeader}
                  onClick={() => {
                    this.changeDataSource('player');
                    this.setState({ view: 'player' });
                  }}
                >
                  Player View
                </div>
                <div className={[styles.TabHeader, styles.TabHeader_Active].join(' ')}>Event View</div>
              </div>
            )}

            <div
              className={styles.SectionHeader}
              style={{
                padding: this.props.globalStyles.isMobileSize ? '24px 0 24px 24px' : '48px 0 24px 24px',
              }}
            >
              {this.state.view === 'player' ? `${this.state.playerName} ${isWithdrawPage ? 'Withdrawals' : 'Commitments'}` : 'Event Commitments'}
              <h6>Commitments Worksheet as of {lastUpdatedDate}. To commit to an event(s), check the corresponding box and click Commit.</h6>
            </div>
            {this.state.view === 'player' && this.state.tourCode === 'R' ? (
              <div>
                {justCommittedSuccess && <FieldAlert type="commit-success" />}
                {this.state.strengthOfField === true ? <FieldAlert mobile={this.props.globalStyles.isMobileSize} type="positive" /> : <FieldAlert />}
              </div>
            ) : (
              ''
            )}
            <div className={styles.Commitments}>
              {this.state.view === 'player' ? (
                <div
                  className={styles.Filters}
                  style={{
                    flexFlow: this.props.globalStyles.isMobileSize ? 'column nowrap' : 'row nowrap',
                    alignItems: this.props.globalStyles.isMobileSize ? 'flex-start' : 'center',
                  }}
                >
                  <div className={this.props.globalStyles.isMobileSize ? styles.MobileFilter : styles.Filter}>
                    <label>Player</label>
                    <select name="player" value={this.state.playerId} onChange={this.handlePlayerChange}>
                      {playerOptions}
                    </select>
                  </div>
                  <div className={this.props.globalStyles.isMobileSize ? styles.MobileFilter : styles.Filter}>
                    <label>Tour</label>
                    <select name="tour" value={this.state.tourCode} onChange={this.handleTourChange}>
                      {tourOptions}
                    </select>
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    className={styles.Filters}
                    style={{
                      flexFlow: this.props.globalStyles.isMobileSize ? 'column nowrap' : 'row nowrap',
                      alignItems: this.props.globalStyles.isMobileSize ? 'flex-start' : 'center',
                    }}
                  >
                    <div className={this.props.globalStyles.isMobileSize ? styles.MobileFilter : styles.Filter}>
                      <label>Events</label>
                      <select name="tournament" value={this.state.tournamentId} onChange={this.handleTournamentChange}>
                        {tournamentOptions}
                      </select>
                    </div>
                    <div className={this.props.globalStyles.isMobileSize ? styles.MobileFilter : styles.Filter}>
                      <label>Tour</label>
                      <select name="tour" value={this.state.tourCode} onChange={this.handleTourChange}>
                        <option value="R">{PGA_TOUR}</option>
                        <option value="S">{CHAMPIONS_TOUR}</option>
                        <option value="H">{KORN_FERRY_TOUR}</option>
                        <option value="Y">{PGA_TOUR_AMERICAS}</option>
                      </select>
                    </div>
                  </div>
                </div>
              )}

              {this.state.commitmentTournamentList.length > 0 ? (
                <div>
                  <div className={styles.SymbolKey}>
                    <div className={styles.SymbolHeader}>Symbol Key</div>
                    <div
                      className={styles.Symbols}
                      style={{
                        flexFlow: this.props.globalStyles.isMobileSize ? 'column nowrap' : 'row nowrap',
                      }}
                    >
                      <div className={this.props.globalStyles.isMobileSize ? styles.MobileSymbolItem : styles.SymbolItem}>
                        <i className="fas fa-check-square" style={{ color: '#9AA5B3' }}></i>Player is committed.
                      </div>
                      {this.state.tourCode === 'R' ? (
                        <div className={this.props.globalStyles.isMobileSize ? styles.MobileSymbolItem : styles.SymbolItem}>
                          <i className="fas fa-info-circle" style={{ color: '#167F06' }}></i> Satisfies the Strength of Field requirement for the
                          player.
                        </div>
                      ) : null}
                      <div className={this.props.globalStyles.isMobileSize ? styles.MobileSymbolItem : styles.SymbolItem}>
                        <i className="fas fa-question-circle"></i> Player is committed to two tournaments during the same dates.
                      </div>
                      {teamEventNamesList.length > 0 && (
                        <div className={this.props.globalStyles.isMobileSize ? styles.MobileSymbolItem : styles.SymbolItem}>
                          <i className="fas fa-check-circle " style={{ color: '#167f06' }} />
                          Player's team has been confirmed for the {teamEventNamesList.join(' and ')}.
                        </div>
                      )}
                    </div>
                  </div>
                  {this.state.view === 'player' ? (
                    <div className={this.props.globalStyles.isMobileSize ? styles.MobileCommitmentsTable : styles.CommitmentsTable}>
                      {canWithdrawFromThisTour && (
                        <div className={styles.WithdrawInfo}>
                          {isWithdrawPage ? (
                            <p>
                              To return to the Commitment Worksheet,{' '}
                              <Link to={`/commitments/${encodePlayer(this.state.playerId)}`} onClick={this.handleWithdrawToggle}>
                                click here
                              </Link>
                              .
                            </p>
                          ) : (
                            <p>
                              To withdraw from a tournament,{' '}
                              <Link to={`/commitments/${encodePlayer(this.state.playerId)}?withdraw=true`} onClick={this.handleWithdrawToggle}>
                                click here
                              </Link>
                              .
                            </p>
                          )}
                          <p>
                            To withdraw after the deadline, please contact the Competitions Department at{' '}
                            <a href="tel:+18007422244">1-800-742-2244</a>.
                          </p>
                        </div>
                      )}
                      <table>
                        <thead>
                          <tr>
                            <th style={isWithdrawPage ? { color: 'red' } : {}}>{isWithdrawPage ? 'Withdraw' : 'Commit'}</th>
                            <th>Dates</th>
                            <th>Tournament</th>
                            <th>#</th>
                            <th>Position / Field</th>
                            {this.state.tourCode === 'R' && <th>Pro-Am?</th>}
                          </tr>
                        </thead>
                        <tbody>{commitmentData}</tbody>
                      </table>
                    </div>
                  ) : (
                    <div className={styles.CommitmentsTable}>
                      <FieldAlert
                        type="title"
                        mobile={this.props.globalStyles.isMobileSize}
                        title={selectedTournamentName}
                        dates={[selectedTournamentStartDate, selectedTournamentEndDate]}
                      />
                      <table>
                        <thead>
                          {this.state.tourCode === 'R' ? (
                            <tr>
                              <th>Commitment Status</th>
                              <th>Player Name</th>
                              <th>#</th>
                              <th>Position/Field</th>
                              <th>Pro-Am?</th>
                            </tr>
                          ) : (
                            <tr>
                              <th>Commitment Status</th>
                              <th>Player Name</th>
                              <th>#</th>
                              <th>Position/Field</th>
                            </tr>
                          )}
                        </thead>
                        <tbody>{commitmentData}</tbody>
                      </table>
                    </div>
                  )}
                </div>
              ) : (
                <p>0 Results</p>
              )}
            </div>
            {this.state.view === 'player' ? (
              <button className={styles.CommitButton} onClick={this.handleCommitWithdrawClick}>
                {isWithdrawPage ? 'Withdraw' : 'Commit'}
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
      );
    }
  }

  handleCommitWithdrawClick(event) {
    const isWithdraw = event.target.innerHTML.toLowerCase() === 'withdraw';
    if (isWithdraw) {
      this.setState({ showWithdrawConfirmModal: true });
    } else {
      this.postCommitment(false);
    }
  }

  postCommitment(isWithdrawPage) {
    const data = {
      playerId: this.state.playerId,
      [isWithdrawPage ? 'withdrawTournamentScheduleIds' : 'tournamentScheduleIds']: this.state.commitToTournaments,
      modifiedBy: `${this.props.profile.id} - ${this.props.profile.fullName}`,
      modifiedFrom: 'websiteapp',
    };
    this.setState({ isLoading: true, showWithdrawConfirmModal: false });
    CommitmentApi.postPlayerCommitments(data, this.props.token.id_token)
      .then((resp) => {
        if (!resp?.data?.successCommitCount && !resp.data.successWithdrawCount) {
          this.setState({ isError: true });
        }
      })
      .catch(() => this.setState({ isError: true }))
      .finally(() => {
        this.setState({ commitToTournaments: [] });
        this.props.navigate(`/commitments/${encodePlayer(this.state.playerId)}`);
        window.scrollTo(0, 0);
        this.fetchPlayerData(this.state.playerId, true);
      });
  }

  handlePlayerChange = (event) => {
    this.props.navigate(`/commitments/${encodePlayer(event.target.value)}`);
  };

  handleTournamentChange = (event) => {
    this.setStateEventView(event.target.value);
  };

  handleTourChange = (event) => {
    if (this.state.view === 'player') {
      this.setStatePlayerView(event.target.value);
    } else {
      this.setStateEventView(undefined, this.state.playerId, event.target.value);
    }
  };

  handleCommitmentChange = (event) => {
    const { commitToTournaments } = this.state;
    let eventName = event.target.name;
    if (event.target.checked) {
      if (this._isMounted) {
        this.setState((prevState) => ({
          commitToTournaments: [...prevState.commitToTournaments, eventName],
        }));
      }
    } else {
      var newCommitTo = commitToTournaments.filter((value, index, arr) => {
        return value !== eventName;
      });

      if (this._isMounted) {
        this.setState({
          commitToTournaments: newCommitTo,
        });
      }
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }
}

const mapStateToProps = (state) => {
  const profile = state.profile;
  const token = state.token;
  const commitmentInfo = state.commitmentInfo;
  const teamField = state.teamField;
  const commitmentEvents = state.commitmentEvents;
  return { profile, commitmentInfo, teamField, commitmentEvents, token };
};

const mapDispatchToProps = (dispatch) => ({
  receiveCommitmentInfo: (resp) => dispatch(receiveCommitmentInfo(resp.data)),
  receiveCommitmentEvents: () => dispatch(receiveCommitmentEvents()),
  receiveTeamField: (tsId, payload) => dispatch(receiveTeamField(tsId, payload)),
});

export default withParamsLocationNavigation(connect(mapStateToProps, mapDispatchToProps)(CommitmentWorkSheet));
