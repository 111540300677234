import React from 'react';
import styles from './CardHeader.module.css';

export default function CardHeader({ icon, title, globalStyles }) {
  const style = { gridTemplateColumns: globalStyles.isMobileSize ? '10% auto 5%' : 'repeat(3, fit-content(300px))' };
  return (
    <div className={styles.CardHeaderRow} style={style}>
      <div className={styles.CardIcon}>
        <i className={'fa fa-' + icon} />
      </div>
      <div className={styles.CardText}>{title}</div>
      <div className={styles.ChevronIcon} style={{ float: globalStyles.isMobileSize ? 'right' : '' }} />
    </div>
  );
}
