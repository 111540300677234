import React from 'react';
import styles from './CommitmentWorkSheet.module.css';
import teamStyles from './TeamCommitment.module.css';

export default function TeamFieldReport({ globalStyles, fieldListObj }) {
  return (
    <div className={styles.MainContainer}>
      <div className={styles.FieldReportContainer}>
        <div className={styles.TournamentName}>
          {fieldListObj.tournamentName} - {fieldListObj.tourName}
        </div>
        {fieldListObj.lastUpdated && (
          <p>
            Last updated:{' '}
            {new Intl.DateTimeFormat('en-US', {
              month: 'short',
              day: '2-digit',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            }).format(new Date(fieldListObj.lastUpdated))}
          </p>
        )}
        {fieldListObj.fieldHeaderMessage && <div>{fieldListObj.fieldHeaderMessage}</div>}
        <table className={teamStyles.FieldTable}>
          <thead>
            <tr>
              <th>Top Available Player</th>
              <th>Teammate</th>
            </tr>
          </thead>
          <tbody>
            {fieldListObj.teamFieldList?.map((pair) => (
              <tr key={pair.id}>
                <td>{pair.name}</td>
                <td>{pair.confirmed || pair.undecided ? pair.teammate.name : 'Unconfirmed'}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <table className={teamStyles.FieldTable}>
          <thead>
            <tr>
              <th>Alternates</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {fieldListObj.alternateList?.map((pair) => (
              <tr key={pair.id}>
                <td>{pair.name}</td>
                <td>{pair.confirmed || pair.undecided ? pair.teammate.name : 'Unconfirmed'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
