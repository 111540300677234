import React from 'react';
import CardHeader from '../../../Common/Headers/CardHeader';
import styles from './DashboardComponents.module.css';
import Circle from '../../../img/circle.png';
import { useNavigate } from 'react-router-dom';

function Report(props) {
  const navigate = useNavigate();

  function navigateTo(path) {
    navigate(path, { state: { id: props.playerId } });
  }

  return (
    <div className={styles.SectionContainer}>
      <CardHeader title="Additional information" icon="newspaper" globalStyles={props.globalStyles} />
      <div className={styles.ReportAdditionalInfo}>
        {!props.showOnlyEarnings && (
          <div className={styles.ReportItem} onClick={() => navigateTo('/endorsements')}>
            <h2>
              Endorsements <img src={Circle} alt="Right arrow icon" />
            </h2>
            <p>View your endorsements.</p>
          </div>
        )}
        <div className={styles.ReportItem} onClick={() => navigateTo('/earnings-statements')}>
          <h2>
            Earnings Statements <img src={Circle} alt="Right arrow icon" />
          </h2>
        </div>
        {!props.showOnlyEarnings && (
          <div className={styles.ReportItem} onClick={() => navigateTo('/participation')}>
            <h2>
              Participation Report <img src={Circle} alt="Right arrow icon" />
            </h2>
            <p>View your current and past participation report.</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Report;
