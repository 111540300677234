import React from 'react';
import styles from './CommitmentWorkSheet.module.css';
import CommitmentApi from '../../../api/commitmentApi';
import Parser from 'html-react-parser';
import { connect } from 'react-redux';
import TeamFieldReport from './TeamFieldReport';
import TeamFieldReportProam from './TeamFieldReportProam';
import { receiveCommitmentInfo, receiveTeamField } from '../../../actions/commitmentActions';
import Loader from '../../Common/Loader/Loader';
import { intlDateFormat } from '../../../util/date';
import { getPlayerId } from '../../../util/generalUtils';
import { playerIdTourCodeLookup } from '../../../reducers/commitmentInfoReducer';
import { withParamsAndLocation } from '../../../util/routerHooks';
import { Navigate } from 'react-router-dom';

class FieldReport extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      fieldData: null,
      sortedFieldData: null,
      fieldDataKeys: null,
      isBlank: false,
      teamFieldListObj: null,
      teamFieldProamObj: null,
      doDisplay: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    let { type, tournamentId } = this.props.params;

    if (type === 'report' && tournamentId === '152614') {
      type = 'team-report';
    } else if (type === 'proam' && tournamentId === '152614') {
      type = 'team-proam';
    }

    const tourCode = new URLSearchParams(this.props.location.search).get('code') || 'R';
    const playerId = getPlayerId(tourCode, this.props);
    const key = playerIdTourCodeLookup(playerId, tourCode);

    const approved = this.props.commitmentInfo[key] && this.props.commitmentInfo[key].fieldApproved;
    let worksheetPromise;
    if (approved || type.includes('proam')) {
      this.setState({ doDisplay: true });
    } else {
      worksheetPromise = CommitmentApi.getPlayerCommitmentWorksheet(playerId, tourCode, this.props.idToken).then((response) => {
        this.props.receiveCommitmentInfo(response);
        const doDisplay = response?.data?.playerCommitmentTournamentList?.find(
          (t) => t.tournamentScheduleId.toString() === tournamentId,
        )?.fieldApproved;
        this.setState({ doDisplay });
      });
    }

    let reportPromise;
    switch (type) {
      case 'report':
        reportPromise = CommitmentApi.getFieldReport(tournamentId, this.props.idToken).then((respo) => {
          if (respo.ERROR) {
            this.setState({ isBlank: true });
          } else {
            const sortedFieldMap = JSON.stringify(respo.data.sortedFieldByCategoryMap);
            if (sortedFieldMap.length > 0) {
              const fieldData = JSON.parse(sortedFieldMap);
              const fieldDataKeys = Object.keys(fieldData);
              this.setState({
                fieldData: respo.data,
                sortedFieldData: fieldData,
                fieldDataKeys: fieldDataKeys,
                isBlank: false,
              });
            } else {
              this.setState({
                isBlank: true,
              });
            }
          }
        });
        break;
      case 'proam':
        reportPromise = CommitmentApi.getProAmFieldReport(tournamentId, this.props.idToken).then((resp) => {
          if (resp.data !== undefined) {
            if (!resp.data.nineAndNineFormat) {
              this.setState({ fieldData: resp.data, isBlank: false });
            } else {
              const sortedProAmMap = JSON.stringify(resp.data.proAmNineAndNinePositionMap);
              const sortedProAmJson = JSON.parse(sortedProAmMap);
              const fieldDataKeys = Object.keys(sortedProAmJson);

              this.setState({
                fieldData: resp.data,
                proAmNineAndNine: sortedProAmJson,
                fieldDataKeys: fieldDataKeys,
              });
            }
          } else {
            this.setState({ isBlank: true });
          }
        });
        break;
      case 'team-report':
        if (this.props.teamField[tournamentId]) {
          this.setState({ isBlank: false, teamFieldListObj: this.props.teamField[tournamentId] });
        } else {
          let playerId;
          if (this.props.profile.type === 'player') {
            playerId = this.props.profile.id;
          } else {
            playerId = this.props.profile.players.find((player) => player.tourCodes.includes('R'))?.playerId;
          }
          reportPromise = CommitmentApi.getPlayerCommitmentWorksheet(playerId, 'R', this.props.idToken).then((response) => {
            this.props.receiveCommitmentInfo(response);
            CommitmentApi.getTeamField(tournamentId, this.props.idToken).then((resp) => {
              this.props.receiveTeamField(tournamentId, resp.data);
              this.setState({ isBlank: !!resp.ERROR, teamFieldListObj: resp.data });
            });
          });
        }
        break;
      case 'team-proam':
        reportPromise = CommitmentApi.getTeamProAmField(tournamentId, this.props.idToken).then((resp) => {
          if (resp.ERROR) {
            this.setState({ isBlank: true });
          } else {
            this.setState({ isBlank: false, teamFieldProamObj: resp.data });
          }
        });
        break;
      default:
        throw new Error('Route type not supported');
    }
    Promise.all([worksheetPromise, reportPromise]).then(() => this.setState({ isLoading: false }));
  }

  render() {
    let { type, tournamentId } = this.props.params;

    if (type === 'report' && tournamentId === '152614') {
      return <Navigate to={`/field/152614/team-report`} />;
    } else if (type === 'proam' && tournamentId === '152614') {
      return <Navigate to={`/field/152614/team-proam`} />;
    }

    if (this.state.isLoading) {
      return <Loader withWrapper />;
    } else if (!this.state.doDisplay || this.state.isBlank) {
      const statement = !this.state.doDisplay
        ? 'The selected field is not available at this time.'
        : 'There is no data available for the selected field.';
      return (
        <div className={styles.MainContainer}>
          <div
            className={styles.FieldReportContainer}
            style={{
              minHeight: this.props.globalStyles.isMobileSize
                ? this.props.globalStyles.windowHeight + 140
                : this.props.globalStyles.windowHeight + 120,
            }}
          >
            <p>{statement} Please try again later.</p>
          </div>
        </div>
      );
    } else if (type === 'report') {
      const mappedFields = this.state.fieldDataKeys.map((item, i) => {
        let categoryHasPlayers = true;
        for (let l = 0; l < this.state.fieldData.categoriesWithNoPlayers.length; l++) {
          if (item === this.state.fieldData.categoriesWithNoPlayers[l]) {
            categoryHasPlayers = false;
          }
        }
        if (categoryHasPlayers) {
          let fieldValues = `<div class=${styles.FieldValues}>`;

          for (let j = 0; j < this.state.sortedFieldData[item].length; j++) {
            if (this.state.sortedFieldData[item][j].fieldStatusCode !== 'A') {
              const fullName = this.state.sortedFieldData[item][j].fullName;
              if (fullName !== undefined && fullName !== null) {
                if (this.state.fieldData.tourCode !== 'S') {
                  fieldValues += `<div>${fullName}</div>`;
                } else {
                  var placementString;
                  if (
                    this.state.sortedFieldData[item][j].positionInField === null ||
                    this.state.sortedFieldData[item][j].positionInCategory === null
                  ) {
                    placementString = '<span></span>';
                  } else {
                    placementString = `<span>${this.state.sortedFieldData[item][j].positionInField} / ${this.state.sortedFieldData[item][j].positionInCategory}</span>`;
                  }
                  fieldValues += `<div>${placementString} <div class=${styles.FieldValueName}>${fullName}</div></div>`;
                }
              } else {
                if (this.state.fieldData.tourCode !== 'S') {
                  fieldValues += `<div>${this.state.sortedFieldData[item][j].exemptionShortDescription}</div>`;
                } else {
                  if (
                    this.state.sortedFieldData[item][j].positionInField !== null &&
                    this.state.sortedFieldData[item][j].positionInCategory !== null
                  ) {
                    fieldValues += `<div>${this.state.sortedFieldData[item][j].positionInField} / ${this.state.sortedFieldData[item][j].positionInCategory} ${this.state.sortedFieldData[item][j].exemptionShortDescription}</div>`;
                  } else {
                    fieldValues += `<div>-- ${this.state.sortedFieldData[item][j].exemptionShortDescription}</div>`;
                  }
                }
              }
            }
          }
          fieldValues += `</div>`;

          return (
            <div key={i}>
              <h2>{item}</h2>
              {Parser(fieldValues)}
            </div>
          );
        } else {
          return null;
        }
      });

      const alternates = this.state.fieldData.alternatesList.map((item, i) => {
        if (this.state.fieldData.tourCode !== 'S') {
          return <div key={i}>{item.fullName !== null ? item.fullName : item.exemptionShortDescription}</div>;
        } else {
          return (
            <div key={i}>
              {item.positionInField} / {item.positionInCategory} &nbsp;{item.fullName !== null ? item.fullName : item.exemptionShortDescription}
            </div>
          );
        }
      });

      return (
        <div className={styles.MainContainer}>
          <div className={styles.FieldReportContainer}>
            <div className={styles.TournamentName}>
              {this.state.fieldData.tournamentName} - {this.state.fieldData.tourName}
            </div>
            {this.state.fieldData.lastUpdated !== null ? (
              <p>
                Last updated:{' '}
                {new Intl.DateTimeFormat('en-US', {
                  month: 'short',
                  day: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                }).format(new Date(this.state.fieldData.lastUpdated))}
              </p>
            ) : (
              ''
            )}
            {this.state.fieldData.fieldHeaderMessage !== null ? <div>{this.state.fieldData.fieldHeaderMessage}</div> : ''}
            {mappedFields}
            <h2>Alternates</h2>
            {alternates}
          </div>
        </div>
      );
    } else if (type === 'proam') {
      if (this.state.fieldData.nineAndNineFormat) {
        const categoryCountMap = JSON.stringify(this.state.fieldData.categoryCountMap);
        const categoryCountJson = JSON.parse(categoryCountMap);
        const categoryKeys = Object.keys(categoryCountJson);
        let theCategories = [];
        for (let m = 0; m < categoryKeys.length; m++) {
          if (categoryCountJson[categoryKeys[m]] > 0) {
            const playingStr =
              categoryKeys[m] === 'Pro-Am' && categoryCountJson[categoryKeys[m]] > this.state.fieldData.uniquePlayerCount
                ? `- ${this.state.fieldData.uniquePlayerCount} Playing`
                : '';
            const { breakPoint } = this.state.fieldData;

            if (categoryKeys[m] === 'Pro-Am') {
              let tableRows = [];
              for (let a = 0; a < breakPoint; a++) {
                const showAsterisk = !!this.state.fieldData.playersPlaying18Holes?.find(
                  (player) => this.state.proAmNineAndNine[this.state.fieldDataKeys[a]].id === player,
                );
                const asterisk = showAsterisk ? '*' : '';
                const b = a + breakPoint;
                const objA = this.state.proAmNineAndNine[this.state.fieldDataKeys[a]];
                const objB = this.state.proAmNineAndNine[this.state.fieldDataKeys[b]];
                tableRows.push(
                  <tr>
                    <td style={{ minWidth: '150px' }}>
                      {objA.fullName}
                      {asterisk}
                    </td>
                    <td style={{ textAlign: 'center' }}>{objA.order}</td>
                    <td style={{ textAlign: 'center' }}>{objA.fecRank}</td>
                    <td style={{ minWidth: '150px' }}>
                      {objB?.fullName || ''}
                      {asterisk}
                    </td>
                    <td style={{ textAlign: 'center' }}>{asterisk || !objB?.order ? '' : objB.order}</td>
                    <td style={{ textAlign: 'center' }}>{asterisk || !objB?.fecRank ? '' : objB.fecRank}</td>
                  </tr>,
                );
              }
              theCategories.push(
                <div className={styles.Category}>
                  <div className={styles.CategoryHeader}>
                    {categoryKeys[m]} ({categoryCountJson[categoryKeys[m]]}) {playingStr}
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th />
                        <th>Order</th>
                        <th>
                          {this.state.fieldData.categoryYearLabelMap['Pro-Am']} {getRankLabel(this.state.fieldData.tourCode)} RANK
                        </th>
                        <th />
                        <th>Order</th>
                        <th>
                          {this.state.fieldData.categoryYearLabelMap['Pro-Am']} {getRankLabel(this.state.fieldData.tourCode)} RANK
                        </th>
                      </tr>
                    </thead>
                    <tbody>{tableRows}</tbody>
                  </table>
                </div>,
              );
            } else {
              const proAmList = this.state.fieldData.proAmReportDataList;
              const rows = proAmList
                .filter((item) => item.category === categoryKeys[m])
                .map((item, i) => (
                  <tr key={i}>
                    <td>{item.fullName}</td>
                    <td>{item.order}</td>
                    <td>{item.fecRank}</td>
                  </tr>
                ));
              theCategories.push(
                <div className={styles.Category}>
                  <div className={styles.CategoryHeader}>
                    {categoryKeys[m]} ({categoryCountJson[categoryKeys[m]]}) {playingStr}
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th />
                        <th>Order</th>
                        <th>
                          {this.state.fieldData.categoryYearLabelMap[categoryKeys[m]]} {getRankLabel(this.state.tourCode)} RANK
                        </th>
                      </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                  </table>
                </div>,
              );
            }
          }
        }

        return (
          <div className={styles.MainContainer}>
            <div className={styles.FieldReportContainer}>
              <h1 className={styles.TournamentName}>{`Pro-Am Field - ${this.state.fieldData.tourName}`}</h1>
              <h2>
                {this.state.fieldData.tournamentName} (Ends {intlDateFormat(this.state.fieldData.endDate)})
              </h2>
              {theCategories}
            </div>
          </div>
        );
      } else {
        const categoryCountMapStandard = JSON.stringify(this.state.fieldData.categoryCountMap);
        const categoryCountJson = JSON.parse(categoryCountMapStandard);
        const categoryKeys = Object.keys(categoryCountJson);

        let categoriesStandard = '';
        for (let mapCount = 0; mapCount < categoryKeys.length; mapCount++) {
          if (categoryCountJson[categoryKeys[mapCount]] > 0) {
            categoriesStandard += `<div class=${styles.Category}><div class=${styles.CategoryHeader}>${categoryKeys[mapCount]} (${
              categoryCountJson[categoryKeys[mapCount]]
            })</div>`;
          }

          categoriesStandard += `<table><thead><tr><td width="75%"></td><td></td></tr></thead><tbody>`;
          this.state.fieldData.proAmReportDataList.map((item, i) => {
            if (item.category === categoryKeys[mapCount]) {
              if (item.fullName !== null && item.fullName !== ' ') {
                categoriesStandard += `<tr><td>${item.fullName}</td><td>${item.formattedRank}</td></tr>`;
              } else {
                categoriesStandard += `<tr><td>${this.state.fieldData.sponsorValueCodeForPlaceholders}</td><td>${item.formattedRank}</td></tr>`;
              }
            }
            return null;
          });
          categoriesStandard += `</tbody></table>`;
        }
        categoriesStandard += `</div>`;

        return (
          <div className={styles.MainContainer}>
            <div className={styles.FieldReportContainer}>
              <h1 className={styles.TournamentName}>Pro-Am Field - PGA TOUR</h1>
              <h2>
                {this.state.fieldData.tournamentName} (Ends {intlDateFormat(this.state.fieldData.endDate)})
              </h2>
              {Parser(categoriesStandard)}
            </div>
          </div>
        );
      }
    } else if (type === 'team-report' && this.state.teamFieldListObj) {
      return <TeamFieldReport globalStyles={this.props.globalStyles} fieldListObj={this.state.teamFieldListObj} />;
    } else if (type === 'team-proam') {
      return <TeamFieldReportProam globalStyles={this.props.globalStyles} teamFieldProamObj={this.state.teamFieldProamObj} />;
    } else {
      return null;
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
}

const mapStateToProps = (state) => {
  const profile = state.profile;
  const idToken = state.token.id_token;
  const teamField = state.teamField;
  const commitmentInfo = state.commitmentInfo;
  return { profile, idToken, teamField, commitmentInfo };
};

const mapDispatchToProps = (dispatch) => ({
  receiveCommitmentInfo: (resp) => dispatch(receiveCommitmentInfo(resp.data)),
  receiveTeamField: (tsId, payload) => dispatch(receiveTeamField(tsId, payload)),
});

function getRankLabel(tourCode) {
  if (tourCode === 'R') {
    return 'FEC';
  } else if (tourCode === 'H') {
    return 'KFT';
  } else {
    return '';
  }
}

export default withParamsAndLocation(connect(mapStateToProps, mapDispatchToProps)(FieldReport));
