import { RECEIVE_PROFILE_DATA, ADD_PROFILE_DATA } from '../util/actionTypes';

export const receiveProfileData = (profile) => ({
  type: RECEIVE_PROFILE_DATA,
  payload: { profile },
});

export const updateProfileData = (profile) => ({
  type: ADD_PROFILE_DATA,
  payload: { profile },
});
