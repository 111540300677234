import React, { useEffect, useState } from 'react';

function VideoByUrl() {
  const [videoUrl, setVideoUrl] = useState('');
  const [height, setHeight] = useState(1);
  const [width, setWidth] = useState(1);
  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
    const suppliedUrl = params['video-url'] || '';

    if (suppliedUrl.indexOf('https://videos.sproutvideo.com/') === 0) {
      setVideoUrl(suppliedUrl);
    }
  }, []);
  return (
    <iframe
      title="video-sprout"
      id="sproutvideo-player"
      src={videoUrl}
      width={width}
      height={height}
      frameBorder="0"
      allowFullScreen
      referrerPolicy="no-referrer-when-downgrade"
    />
  );
}

export default VideoByUrl;
