import React from 'react';
import styles from './Reports.module.css';
import TourApi from '../../api/tourApi';
import Loader from '../Common/Loader/Loader';
import { withParams } from '../../util/routerHooks';

class TeeTimes extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    const params = this.props.params;

    this.state = {
      isLoading: true,
      isLoadingTeeTimes: true,
      routeParams: params,
      activeRound: 1,
      isBlank: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    TourApi.getTournamentDetails(this.props.params.eventId).then((res) => {
      const courseMap = {};
      res[0].courses.forEach((course) => {
        courseMap[course.id] = course;
      });
      if (this._isMounted) {
        this.setState({
          tournamentName: res[0].Tournament_Name,
          courseMap,
          showCourses: res[0].courses.length > 1,
          isLoading: false,
        });
        this.getData();
      }
    });
  }

  getData(newRound = undefined) {
    if (newRound === null || newRound === undefined) {
      newRound = this.state.activeRound;
    }

    TourApi.getTournamentTeeTimes(this.props.params.eventId, newRound).then((res) => {
      if (res !== undefined) {
        if (this._isMounted) {
          var teeTimesMap = [];
          res.forEach((resultItem) => {
            var inMap = false;
            teeTimesMap.forEach((teeTimeMapItem) => {
              if (teeTimeMapItem === resultItem.time) {
                inMap = true;
              }
            });

            if (!inMap) {
              teeTimesMap.push(resultItem.time);
            }
          });

          this.setState({
            isLoadingTeeTimes: false,
            isBlank: false,
            teeTimeReport: res,
            teeTimeMap: teeTimesMap,
          });
        }
      } else {
        if (this._isMounted) {
          this.setState({
            isLoadingTeeTimes: false,
            isBlank: true,
          });
        }
      }
    });
  }

  render() {
    if (this.state.isLoading) {
      return <Loader withWrapper />;
    } else {
      const { teeTimeReport, activeRound, teeTimeMap, tournamentName, isBlank, courseMap, showCourses } = this.state;
      var teeTimes;
      var teeTimesWarning;
      if (this.state.isLoadingTeeTimes === false) {
        if (isBlank) {
          teeTimes = null;
          teeTimesWarning = <div className={styles.Empty}>No data available. Please try again later.</div>;
        } else {
          teeTimes = teeTimeMap.map((item, i) => {
            const teeTimeItems = teeTimeReport.map((itemA, a) => {
              if (itemA.time === item) {
                return (
                  <tr key={a}>
                    {itemA.PositionWithinGroup === 1 ? <td>Tee #{itemA.start_hole}</td> : <td></td>}
                    {showCourses && itemA.PositionWithinGroup === 1 ? <td>{courseMap[itemA.course_id].name}</td> : <td></td>}
                    <td>
                      {itemA.first_name} {itemA.last_name}
                    </td>
                  </tr>
                );
              } else {
                return null;
              }
            });

            var dateString = item + 'Z';
            var date = new Date(dateString);

            return (
              <tbody key={i}>
                <tr>
                  <td colSpan="3" className={styles.TimeRow}>
                    {new Intl.DateTimeFormat('en-US', {
                      hour: 'numeric',
                      minute: 'numeric',
                      timeZone: 'UTC',
                    }).format(new Date(date))}
                  </td>
                </tr>
                {teeTimeItems}
              </tbody>
            );
          });
        }
      }

      return (
        <div className={styles.MainContainer}>
          <div
            className={styles.ReportContainer}
            style={{
              minHeight: this.props.globalStyles.isMobileSize
                ? this.props.globalStyles.windowHeight + 140
                : this.props.globalStyles.windowHeight + 120,
            }}
          >
            <h1>Tee Times</h1>
            <p className={styles.Info}>
              As of{' '}
              {new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZone: 'UTC',
              }).format(new Date())}
            </p>
            <h2 className={styles.TeeTimeH2}>{tournamentName}</h2>
            <p className={styles.Info}>All tee times are local</p>
            <div className={styles.Filters}>
              <div className={styles.FilterLabel}>Round</div>
              {activeRound === 1 ? (
                <div className={[styles.FilterItem, styles.FilterItem_Active].join(' ')} onClick={() => this.changeRound(1)}>
                  1
                </div>
              ) : (
                <div className={styles.FilterItem} onClick={() => this.changeRound(1)}>
                  1
                </div>
              )}
              {activeRound === 2 ? (
                <div className={[styles.FilterItem, styles.FilterItem_Active].join(' ')} onClick={() => this.changeRound(2)}>
                  2
                </div>
              ) : (
                <div className={styles.FilterItem} onClick={() => this.changeRound(2)}>
                  2
                </div>
              )}
              {activeRound === 3 ? (
                <div className={[styles.FilterItem, styles.FilterItem_Active].join(' ')} onClick={() => this.changeRound(3)}>
                  3
                </div>
              ) : (
                <div className={styles.FilterItem} onClick={() => this.changeRound(3)}>
                  3
                </div>
              )}
              {activeRound === 4 ? (
                <div className={[styles.FilterItem, styles.FilterItem_Active].join(' ')} onClick={() => this.changeRound(4)}>
                  4
                </div>
              ) : (
                <div className={styles.FilterItem} onClick={() => this.changeRound(4)}>
                  4
                </div>
              )}
            </div>
            {this.state.isLoadingTeeTimes ? (
              <Loader withWrapper wrapperStyle={{ textAlign: 'center', padding: '5% 0', backgroundColor: '#fff' }} />
            ) : (
              <div>{isBlank ? teeTimesWarning : <table>{teeTimes}</table>}</div>
            )}
          </div>
        </div>
      );
    }
  }

  changeRound = (item) => {
    if (this._isMounted) {
      this.setState({
        isLoadingTeeTimes: true,
        activeRound: item,
      });
      this.getData(item);
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }
}

export default withParams(TeeTimes);
