import {
  CHAMP_TOUR_ANNOUNCEMENTS,
  CHAMP_TOUR_ANNOUNCEMENTS_AGT,
  CHAMPIONS_TOUR_CONTACTS,
  KF_TOUR_ANNOUNCEMENTS,
  KF_TOUR_ANNOUNCEMENTS_AGT,
  KF_TOUR_CONTACTS,
  PGA_TOUR_ANNOUNCEMENTS,
  PGA_TOUR_ANNOUNCEMENTS_AGT,
  PGA_TOUR_CONTACTS,
  SHAREPOINT_AUTHENTICATION_URL,
  SHAREPOINT_BASE_URL,
  PGA_TOUR_AMERICAS_ANNOUNCEMENTS,
  PGA_TOUR_AMERICAS_ANNOUNCEMENTS_AGT,
  PGA_TOUR_AMERICAS_CONTACTS,
} from '../url/service-url';
import NavigationRoles from '../components/Common/Navigation/NavigationRoles.json';
import React, { useEffect, useRef, useState } from 'react';
import Loader from '../components/Common/Loader/Loader';

const HOST = ' https://pgatourinc.sharepoint.com';
const INITIAL_MAPPINGS = [
  {
    name: 'PGA TOUR Announcements',
    url: PGA_TOUR_ANNOUNCEMENTS,
  },
  {
    name: 'PGA TOUR Announcements - Agent',
    url: PGA_TOUR_ANNOUNCEMENTS_AGT,
  },
  {
    name: 'Korn Ferry Tour Announcements',
    url: KF_TOUR_ANNOUNCEMENTS,
  },
  {
    name: 'Korn Ferry Tour Announcements - Agent',
    url: KF_TOUR_ANNOUNCEMENTS_AGT,
  },
  {
    name: 'PGA Tour Americas Announcements',
    url: PGA_TOUR_AMERICAS_ANNOUNCEMENTS,
  },
  {
    name: 'PGA Tour Americas Announcements - Agent',
    url: PGA_TOUR_AMERICAS_ANNOUNCEMENTS_AGT,
  },
  {
    name: 'Champions Tour Announcements',
    url: CHAMP_TOUR_ANNOUNCEMENTS,
  },
  {
    name: 'Champions Announcements - Agent',
    url: CHAMP_TOUR_ANNOUNCEMENTS_AGT,
  },
  {
    name: 'PGA TOUR Contact Info',
    url: PGA_TOUR_CONTACTS,
  },
  {
    name: 'Champions Tour Contact Info',
    url: CHAMPIONS_TOUR_CONTACTS,
  },
  {
    name: 'Korn Ferry Tour Contact Info',
    url: KF_TOUR_CONTACTS,
  },
  {
    name: 'PGA Tour Americas Contact Info',
    url: PGA_TOUR_AMERICAS_CONTACTS,
  },
  {
    name: 'Landing Page',
    url: SHAREPOINT_BASE_URL + '/pages/eeb768d4-242b-49cf-a76d-b6e062c88724',
  },
  {
    name: 'Help',
    url: SHAREPOINT_BASE_URL + '/pages/165bf828-9f46-46f4-91cc-265f4480103b',
  },
  {
    name: 'Pro-Am Tee Times',
    navigable: (tourCode = '{tourCode}', id = '{id}') =>
      `${HOST}/sites/PGATOURLINKS/SiteAssets/Pro-Am%20Tee%20Times/ProAmTeeTimes-${tourCode}-${id}-ProAm1.pdf`,
  },
  {
    name: 'Pro-Am Tee Times 2',
    navigable: (tourCode = '{tourCode}', id = '{id}') =>
      `${HOST}/sites/PGATOURLINKS/SiteAssets/Pro-Am%20Tee%20Times/ProAmTeeTimes-${tourCode}-${id}-ProAm2.pdf`,
  },
  {
    name: 'Weather Report',
    navigable: (tourCode = '{tourCode}', id = '{id}') =>
      `${HOST}/sites/PGATOURLINKS/SiteAssets/Weather%20Forecast/WeatherForecast-${tourCode}${id}.pdf`,
  },
  {
    name: 'Hole Locations',
    navigable: (tourCode = '{tourCode}', tournamentNumber = '{tournamentNumber}', courseId = '{courseId}', round = '{round}') =>
      `${HOST}/sites/PGATOURLINKS/SiteAssets/Hole%20Locations/holelocations-${tourCode}-${tournamentNumber}-${courseId}-${round}.pdf`,
  },
  {
    name: 'Upcoming Events - Contact Info',
    navigable: (tourCode = '{tourCode}', id = '{eventId}') => `${HOST}/sites/PGATOURLINKS/SitePages/CI-${tourCode}${id}.aspx`,
  },
  {
    name: 'Upcoming Events - Tournament Info',
    navigable: (tourCode = '{tourCode}', id = '{eventId}') => `${HOST}/sites/PGATOURLINKS/SitePages/TI-${tourCode}${id}.aspx`,
  },
];

async function callApi(url) {
  const token = await fetch(SHAREPOINT_AUTHENTICATION_URL).then((res) => res.json());
  return fetch(url, {
    method: 'get',
    headers: new Headers({
      Authorization: token.TOKEN.access_token,
    }),
  }).then((res) => res.json());
}

/**
 * Sharepoint Links Component
 * Only used internally to create report all sharepoint links
 *
 **/
export default function SharepointLinks() {
  const [paths, setPaths] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const mappings = useRef(INITIAL_MAPPINGS);

  useEffect(() => {
    let navigationObjects = [];
    Object.keys(NavigationRoles).forEach((playerOrAgent) => {
      Object.keys(NavigationRoles[playerOrAgent].tours).forEach((tourName) => {
        const tourObj = NavigationRoles[playerOrAgent].tours[tourName];
        navigationObjects.push({
          name: `${tourName} - Access Summary - ${playerOrAgent}`,
          url: SHAREPOINT_BASE_URL + tourObj.accessSummary,
        });
        const { resources, tournaments, regulations, tourBusiness } = tourObj;
        tourBusiness
          .concat(resources)
          .concat(tournaments)
          .concat(regulations)
          .forEach((business) => {
            if (business?.props?.contentIds) {
              business.props.contentIds.forEach((prop, i) => {
                const name = `${business.props.title} ${i + 1} - ${tourName}`;
                if (prop.includes('/list') && prop.includes('driveItem/Child')) {
                  prop = prop.substring(0, prop.indexOf('/Child'));
                }
                const url = SHAREPOINT_BASE_URL + prop;
                const linkAlreadyFound = navigationObjects.find((o) => o.url === url);
                if (linkAlreadyFound) {
                  linkAlreadyFound.name += linkAlreadyFound.name.includes(tourName) ? '' : `, ${tourName}`;
                } else {
                  navigationObjects.push({
                    name,
                    url,
                  });
                }
              });
            }
          });
      });
    });

    mappings.current.push(...navigationObjects);

    const promises = mappings.current.map((mapping) => {
      if (mapping.url) {
        return callApi(mapping.url).then((resp) => {
          const navigable = 'https://pgatourinc.sharepoint.com/sites/PGATOURLINKS/' + resp.webUrl;
          setPaths((prevState) => [...prevState, { ...mapping, navigable }]);
        });
      } else {
        setPaths((prevState) => [...prevState, mapping]);
        return null;
      }
    });

    Promise.all(promises).then(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return (
      <div style={{ textAlign: 'center', padding: '25% 0' }}>
        Generating report. This could take up to a minute. Please be patient.
        <Loader />
      </div>
    );
  }

  return (
    <table>
      <thead>
        <tr>
          <th>Sharepoint URL</th>
          <th>Website Item</th>
        </tr>
      </thead>
      <tbody>
        {paths.map((m, i) => {
          if (m.url) {
            return (
              <tr key={m.name + i}>
                <td>
                  <a
                    href={m.navigable}
                    style={m.navigable.includes('undefined') ? { color: 'red', textDecoration: 'none' } : {}}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {m.navigable}
                  </a>
                </td>
                <td>{m.name}</td>
              </tr>
            );
          } else {
            const url = m.navigable();
            return (
              <tr key={m.name + i}>
                <td>
                  <a href={url} rel="noopener noreferrer" target="_blank">
                    {url}
                  </a>
                </td>
                <td>{m.name}</td>
              </tr>
            );
          }
        })}
      </tbody>
    </table>
  );
}
