import styles from './RealResponseTerms.module.css';

export default function RealResponseTerms() {
  return (
    <div className={styles.TermsAndConditions}>
      <h1>Terms of Service - Real Response Anonymous SMS</h1>
      <ol>
        <li>
          If you have information on an anti-doping or integrity violation, you can anonymously text a tip to PGA TOUR (powered by RealResponse) by
          texting <strong>22TOUR</strong>.
        </li>
        <li>
          The RealResponse system will notify PGA TOUR but will not provide PGA TOUR with the cell number of the texting party. Although PGA TOUR will
          not know your identity, it will be able to respond back to the text messages to assist in the investigation and gather information.
        </li>
        <li>
          PGA TOUR will use commercially reasonable efforts to ensure that anonymity is protected to the greatest extent possible. Any communications
          with PGA TOUR through the PGA TOUR Anonymous Texting program hotline shall be subject to the Privacy Policy found at{' '}
          <a rel="noreferrer" target="_blank" href="https://www.pgatour.com/company/privacy-policy.html">
            https://www.pgatour.com/company/privacy-policy.html
          </a>
          ; provided that data obtained through the PGA TOUR Anonymous Texting program will not be shared with any third-parties for their marketing
          reasons/purposes.
        </li>
        <li>
          When you text an anonymous tip to PGA TOUR, you provide consent to receive texts from PGA TOUR and its service providers. You may opt-out
          from further messages at any time.
        </li>
        <li>
          As with any text, message and data rates may apply. Depending on your text plan, you may be charged by your carrier. Message frequency
          varies.
        </li>
        <li>
          To stop receiving text messages, simply text STOP to the short code from which you received a text from the Anonymous Text program. After
          doing so, you will receive a text message confirming your opt-out and will no longer receive text messages to the phone number used to text
          STOP. Note: Texting STOP will only stop text messages to the phone number you used to text STOP.
        </li>
        <li>
          If you send PGA TOUR a STOP text in response to a text message, you may start receiving text messages again by texting YES to{' '}
          <strong>22TOUR</strong>.
        </li>
        <li>Carriers are not liable for delayed or undelivered messages.</li>
        <li>
          To request more information or request help, simply text HELP to the short code from which you received the PGA TOUR text message(s) or
          email <a href="mailto:Integrity@pgatourhq.com">Integrity@pgatourhq.com</a>.
        </li>
        <li>
          You agree that you will not use the service, or direct or permit others to use the service, to transmit or attempt to transmit content that:
          <ul>
            <li>infringes the intellectual property or other proprietary rights of any party;</li>
            <li>
              is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, pornographic, libelous, invasive of
              anther’s privacy, excessively violent, or hate speech;
            </li>
            <li>
              is, in the judgment of PGA TOUR, objectionable or which restricts or inhibits any other person from using or enjoying the services, or
              which actually or potentially exposes PGA TOUR, its business partners, or users to harm or liability of any type;
            </li>
            <li>
              creates a false report about a person, disseminates false information, makes defamatory statements about, or otherwise harasses or
              violates the rights of any person; or
            </li>
            <li>is sexually explicit, except as necessary to provide the tip.</li>
          </ul>
        </li>
        <li>
          You further agree that you will not use the service, or direct or permit others to use the service:
          <ul>
            <li>
              in violation of any applicable local, state, national, or international law, or any regulations having the force of law, or in
              furtherance of any criminal activity, enterprise, or conspiracy;
            </li>
            <li>to impersonate any person or entity, or otherwise misrepresent your affiliation with any person or entity;</li>
            <li>to solicit personal information from anyone under the age of 18;</li>
            <li>
              from a mobile phone, landline phone, computer, tablet, or other device to which you do not have rightful access, control, and/or
              ownership;
            </li>
            <li>to mislead others or engage in dishonest behavior; or</li>
            <li>
              to disrupt, damage, or access in any unauthorized manner any device, server, network, application programming interface, or any other
              service.
            </li>
          </ul>
          You are solely responsible for communications and content you choose to send and receive through the service. PGA TOUR reserves the right,
          at its sole discretion, to review and monitor usage of, investigate, report to authorities, or remove unlawful content received or uploaded
          by any user who PGA TOUR in good faith believes has violated or will violate the foregoing restrictions.
        </li>
        <li>
          You acknowledge and agree that the provision of this service does not obligate PGA TOUR to store any information, and that PGA TOUR has no
          responsibility or liability to you for the failure to store, or the deletion of, any information transmitted through the service.
        </li>
        <li>
          PGA TOUR reserves the right, at its sole discretion, to modify, suspend or discontinue the service, or any part thereof, with or without
          notice to you. You acknowledge and agree that PGA TOUR will not be liable to you or to any third party for any expenses or damages,
          including consequential or special damages, allegedly resulting from suspension, modification or discontinuance of the services, or any part
          thereof.
        </li>
        <li>
          You agree that the ability to effectively use the service will depend on technical and operational factors that are not within PGA TOUR’s
          control and for which PGA TOUR has no responsibility and bears no liability, including without limitation, the availability and quality of
          third-party network services and the quality and features of any equipment, such as mobile phones, which you employ to access and use the
          services.
        </li>
        <li>You acknowledge that PGA TOUR has no duty to take any action on your behalf or with respect to any tips. </li>
      </ol>
    </div>
  );
}
