import React from 'react';
import styles from './Buttons.module.css';

export default class StyledButton extends React.Component {
  render() {
    if (this.props.type === 'primary') {
      return (
        <button className={styles.PrimaryButton} onClick={this.props.handler}>
          {this.props.title}
        </button>
      );
    } else if (this.props.type === 'secondary') {
      return (
        <button className={styles.SecondaryButton} onClick={this.props.handler}>
          {this.props.title}
        </button>
      );
    } else if (this.props.type === 'disabled') {
      return (
        <button className={styles.PrimaryButton_disabled} disabled>
          {this.props.title}
        </button>
      );
    }
  }
}
