import React from 'react';
import CardHeader from '../../../Common/Headers/CardHeader';
import styles from './DashboardComponents.module.css';
import Circle from '../../../img/circle.png';
import { useNavigate } from 'react-router-dom';

function PaceOfPlay({ globalStyles }) {
  const navigate = useNavigate();

  return (
    <div className={styles.SectionContainer}>
      <CardHeader title="Pace of Play" icon="flag" globalStyles={globalStyles} />
      <div className={styles.Reports}>
        <div className={styles.ReportItem} onClick={() => navigate('/timings')}>
          <h2>
            Pace of Play Timings <img src={Circle} alt="Right arrow icon" />
          </h2>
        </div>
      </div>
    </div>
  );
}

export default PaceOfPlay;
