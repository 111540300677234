import { CLEAR_ALL_COMMITMENTS, RECEIVE_TEAM_FIELD } from '../util/actionTypes';

const teamFieldReducer = (state = null, { type, payload, tournamentScheduleId }) => {
  switch (type) {
    case RECEIVE_TEAM_FIELD:
      return {
        ...state,
        [tournamentScheduleId]: {
          ...payload,
        },
      };
    case CLEAR_ALL_COMMITMENTS:
      return {};
    default:
      return state;
  }
};

export default teamFieldReducer;
