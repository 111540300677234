import { nanoid } from 'nanoid';
import {
  RECEIVE_ACCESS_TOKEN,
  UPDATE_ACCESS_TOKENS,
  RECEIVE_REFRESH_TOKEN,
  UPDATE_ID_TOKEN,
  RECEIVE_ID_TOKEN,
  GENERATE_STATE_AND_NONCE,
  CLEAR_TOKENS,
} from '../util/actionTypes';

const defaultState = {
  accessToken: null,
  id_token: null,
  state: null,
  nonce: null,
};

const tokenReducer = (reduxState = defaultState, { type, payload }) => {
  switch (type) {
    case RECEIVE_ACCESS_TOKEN:
      return {
        ...reduxState,
        accessToken: payload.accessToken,
      };
    case RECEIVE_REFRESH_TOKEN:
      return {
        ...reduxState,
        refreshToken: payload.refreshToken,
      };
    case RECEIVE_ID_TOKEN:
      return {
        ...reduxState,
        id_token: payload.idToken,
      };
    case UPDATE_ACCESS_TOKENS:
      return {
        ...reduxState,
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
        id_token: payload.idToken,
      };
    case UPDATE_ID_TOKEN:
      return {
        ...reduxState,
        id_token: payload.idToken,
      };
    case GENERATE_STATE_AND_NONCE:
      return {
        ...reduxState,
        // no need to redefine if it exists in the state already
        state: reduxState.state || nanoid(32),
        nonce: reduxState.nonce || nanoid(32),
      };
    case CLEAR_TOKENS:
      return defaultState;
    default:
      return reduxState;
  }
};

export default tokenReducer;
