import React, { useState } from 'react';
import styles from './Dashboard.module.css';
import { useSelector } from 'react-redux';
import WelcomeHeader from '../../Common/Headers/WelcomeHeader';
import UpcomingEvents from './Components/UpcomingEvents';
import Announcements from './Components/Announcements';
import CommitmentStatus from './Components/CommitmentStatus';
import Report from './Components/Report';
import CardHeader from '../../Common/Headers/CardHeader';

function AgentLayout({ globalStyles }) {
  const profile = useSelector((state) => state.profile);
  const [selectedPlayer, setSelectedPlayer] = useState('');

  return (
    <div className={styles.MainContainer}>
      <WelcomeHeader
        globalStyles={globalStyles}
        title={`Welcome ${profile.firstName}`}
        tagline="all the tour information you need at a glance."
        bannerImage="dashboard"
      />
      <div style={{ height: '40px' }} />
      <UpcomingEvents globalStyles={globalStyles} />
      <Announcements globalStyles={globalStyles} />
      <div className={styles.PlayerOverview}>
        <CardHeader title="Player Overview" icon="user" globalStyles={globalStyles} />
        <div className={styles.PlayerSelection}>
          Quick View of&nbsp;
          <select name="agent_player" value={selectedPlayer} onChange={(e) => setSelectedPlayer(e.target.value)}>
            <option value="">Choose Player...</option>
            {profile.players.map((p) => (
              <option key={p.playerId} value={p.playerId}>
                {p.playerName}
              </option>
            ))}
          </select>
        </div>
        {selectedPlayer && (
          <div>
            <CommitmentStatus globalStyles={globalStyles} playerId={selectedPlayer} />
            <Report globalStyles={globalStyles} playerId={selectedPlayer} />
          </div>
        )}
      </div>
    </div>
  );
}

export default AgentLayout;
