import React from 'react';
import styles from './Cards.module.css';
import { Link } from 'react-router-dom';
import { TOUR_NAMES_BY_CODE } from '../../../util/titles';
import Loader from '../Loader/Loader';
import { getFixedLength } from '../../../util/generalUtils';
import { intlDateFormatNumeric } from '../../../util/date';
import { isCommitted } from '../../../util/commitmentUtils';

function CommitmentStatusCard({ isLoading, tournament, teeTime, currentRound, courseName, globalStyles }) {
  const fullSize = !(globalStyles.isMobileSize || globalStyles.isTabletSize);
  const tCode = tournament.tourCode;
  const tourCodeStyle = 'TourCode' + tournament.tourCode;
  const id = getFixedLength(tournament.tournamentNumber, 3);
  // const tournamentImageSource = `https://pga-tour-res.cloudinary.com/image/upload/c_pad,f_auto,h_90,q_auto,w_145/v1/pgatour/logos/tournament_logos/${tournament.tourCode}${id}/145x90.png`;
  const tournamentImageSource = `https://res.cloudinary.com/pgatour-prod/d_tournaments:logos:${tCode}000.png/tournaments/logos/${tCode}${id}.png`;
  const fieldLink = `/field/${tournament.tournamentScheduleId}/report?code=${tCode}`;
  const showProam = !!(tCode === 'R' && tournament.fieldApproved && tournament.sponsorValue);
  const proAmLink = `/field/${tournament.tournamentScheduleId}/proam`;
  const teeTimeDate = teeTime ? new Date(teeTime.time + 'Z') : null;

  if (isLoading) {
    return (
      <div
        className={styles.ShadowCard}
        style={{
          padding: fullSize ? '' : '0 1rem',
          width: fullSize ? '45%' : '100%',
          maxWidth: fullSize ? '48%' : '100%',
        }}
      >
        <Loader height={40} width={40} withWrapper wrapperStyle={{ textAlign: 'center', padding: '2rem 0' }} />
      </div>
    );
  } else {
    return (
      <div
        className={styles.CardContainer}
        style={{
          padding: fullSize ? '' : '0 1rem',
          width: fullSize ? '50%' : '100%',
          maxWidth: fullSize ? '50%' : '100%',
        }}
      >
        <div className={[styles.EventItem, tourCodeStyle].join(' ')} style={{ flexFlow: fullSize ? 'row nowrap' : 'column nowrap' }}>
          <img className={styles.TourLogo} src={`${tournamentImageSource}`} alt="Logo for tournament" />
          <div>
            <div className={styles['TourTag' + tCode]}>{TOUR_NAMES_BY_CODE[tCode]}</div>
            <p className={styles.EventName}>{tournament.tournamentScheduleName}</p>
            <div className={styles.CommitmentInfo}>
              {tournament.commitmentNumber !== null && isCommitted(tournament.commitmentCode) && (
                <div>
                  <strong>Commitment Number</strong>
                  {tournament.commitmentNumber}
                </div>
              )}
              <div>
                <strong>Tournament Date</strong> {intlDateFormatNumeric(tournament.startDate)}
              </div>
              {isCommitted(tournament.commitmentCode) && teeTime && (
                <div>
                  <strong>Tee Time for Round {currentRound}</strong>
                  {new Intl.DateTimeFormat('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    timeZone: 'UTC',
                  }).format(teeTimeDate)}{' '}
                  - Start at Hole #{teeTime.start_hole}
                  {courseName ? courseName : null}
                </div>
              )}
              {showProam && (
                <div>
                  <strong>Pro-Am</strong>
                  <Link to={proAmLink} tabIndex="0">
                    View
                  </Link>
                </div>
              )}
              {tournament.positionInField !== null ? (
                <div>
                  <strong>Position/Field</strong>
                  {tournament.fieldApproved ? (
                    <Link to={fieldLink} tabIndex="0">
                      {tournament.positionInField} / {tournament.fieldSizeScheduled}
                    </Link>
                  ) : (
                    <span>
                      {tournament.positionInField} / {tournament.fieldSizeScheduled}
                    </span>
                  )}
                </div>
              ) : (
                <div>
                  <strong>Position/Field</strong>/{tournament.fieldSizeScheduled}
                </div>
              )}
              {isCommitted(tournament.commitmentCode) && (
                <div>
                  <strong>Date Committed</strong> {intlDateFormatNumeric(tournament.dateCommitted)}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CommitmentStatusCard;
