export const RECEIVE_ACCESS_TOKEN = 'RECEIVE_ACCESS_TOKEN';
export const RECEIVE_ID_TOKEN = 'RECEIVE_ID_TOKEN';
export const RECEIVE_PROFILE_DATA = 'RECEIVE_PROFILE_DATA';
export const GENERATE_STATE_AND_NONCE = 'GENERATE_STATE_AND_NONCE';
export const ADD_PROFILE_DATA = 'ADD_PROFILE_DATA';
export const CLEAR_TOKENS = 'CLEAR_TOKENS';
export const RECEIVE_REFRESH_TOKEN = 'RECEIVE_REFRESH_TOKEN';
export const UPDATE_ACCESS_TOKENS = 'UPDATE_ACCESS_TOKENS';
export const UPDATE_ID_TOKEN = 'UPDATE_ID_TOKEN';
export const RECEIVE_COMMITMENT_INFO = 'RECEIVE_COMMITMENT_INFO';
export const RECEIVE_TEAM_FIELD = 'RECEIVE_TEAM_FIELD';
export const RECEIVE_COMMITMENT_EVENT = 'RECEIVE_COMMITMENT_EVENT';
export const RECEIVE_COMMITMENT_RESPONSE = 'RECEIVE_SUCCESS_MESSAGE';
export const CLEAR_ALL_COMMITMENTS = 'CLEAR_ALL_COMMITMENTS';
export const CLEAR_PLAYER_COMMITMENT = 'CLEAR_PLAYER_COMMITMENT';
export const RECEIVE_TOURNAMENT_INFO_PAGE = 'RECEIVE_TOURNAMENT_INFO';
export const CLEAR_ALL_TOURNAMENT_PAGES = 'CLEAR_ALL_TOURNAMENT_PAGES';
export const RECEIVE_REORDER_STANDINGS = 'RECEIVE_REORDER_STANDINGS';
