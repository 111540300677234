import SharePoint from '../api/sharepointApi';
import { SHAREPOINT_BASE_URL } from '../url/service-url';

export async function fetchSharePointData(path) {
  try {
    const data = await SharePoint.getContent(path);
    if (data.error) {
      return fetchFallbackData(path);
    } else {
      return data;
    }
  } catch (err) {}
}

async function fetchFallbackData(path) {
  const originalData = await SharePoint.getFile(path);
  const webUrl = originalData.webUrl;
  const parsed = webUrl.split('/');
  const fallbackUrl = 'Fallbacks/' + parsed[1];
  const pdfUrl = fallbackUrl.replace('aspx', 'pdf');
  const fallback = `lists/a60895a1-9af0-4530-ab11-dc4a5f0b77c4/drive/root:/${pdfUrl}?select=id,file,parentReference`;
  const data = await SharePoint.getFile(SHAREPOINT_BASE_URL + fallback);
  const parentReference = data.parentReference.driveId;
  const id = data.id;
  const fallbackData = await SharePoint.getFile(`${SHAREPOINT_BASE_URL}drives/${parentReference}/items/${id}`);
  return fallbackData;
}
