import React, { useEffect, useState } from 'react';
import styles from './Admin.module.css';
import Loader from '../Common/Loader/Loader';
import Modal from '../Common/Modals/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { receiveProfileData } from '../../actions/profileActions';
import { useNavigate } from 'react-router-dom';
import SalesforceApi from '../../api/salesforceApi';

export default function AdminModeForm({ type, clearCommitments, setIsLoadingPage }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [clearModalIsOpen, setClearModalIsOpen] = useState(false);
  const [playerOrAgentId, setPlayerOrAgentId] = useState('');
  const [playerOrAgentObj, setPlayerOrAgentObj] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { profile, token } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (profile.adminMode.active && profile.type === type) {
      setPlayerOrAgentId(profile.id);
      setPlayerOrAgentObj({
        id: profile.id,
        firstName: profile.firstName,
        lastName: profile.lastName,
      });
    }
  }, [profile.adminMode.active, profile.firstName, profile.id, profile.lastName, profile.type, type]);

  function getNewProfile(id, newType, isAdminMode) {
    clearCommitments();
    SalesforceApi.getProfileInfo(id, token.id_token, newType, isAdminMode).then((resp) => {
      dispatch(receiveProfileData(resp.data));
      navigate(`/dashboard/${newType}`, { clearCachedEvents: true });
    });
  }

  function handleChange(event) {
    const re = /^[0-9\b]+$/;
    const { value } = event.target;
    if (value.length <= 5 && (value === '' || re.test(value))) {
      setPlayerOrAgentId(value);
    }
  }

  function handleVerify(event) {
    event.preventDefault();
    setIsLoading(true);
    SalesforceApi.getProfileInfo(playerOrAgentId, token.id_token, type, true)
      .then((resp) => {
        const errorState = { id: playerOrAgentId, notFound: true };
        setPlayerOrAgentObj(resp.error ? errorState : resp.data);
      })
      .finally(() => setIsLoading(false));
  }

  function handleClear(event) {
    setPlayerOrAgentId('');
    setIsLoadingPage(true);
    getNewProfile(profile.adminMode.originalId, profile.adminMode.originalType, false);
  }

  function handleModalSubmit() {
    setIsLoadingPage(true);
    getNewProfile(playerOrAgentObj.id, type, true);
  }

  function showModal(event, modalType) {
    event.preventDefault();
    modalType === 'clear' ? setClearModalIsOpen(true) : setModalIsOpen(true);
  }

  let objStyle, objSelected;
  if (playerOrAgentObj?.notFound) {
    [objStyle, objSelected] = ['Red', 'Not found'];
  } else if (playerOrAgentObj?.id) {
    [objStyle, objSelected] = ['Green', `${playerOrAgentObj.firstName} ${playerOrAgentObj.lastName}`];
  } else {
    [objStyle, objSelected] = ['Grey', '--'];
  }

  const disabled = (type !== 'financial' && playerOrAgentId.length < 4) || isLoading;

  return (
    <>
      <Modal
        open={modalIsOpen}
        title={'Enter Override Mode?'}
        submitText={'Proceed'}
        onCancel={() => setModalIsOpen(false)}
        onSubmit={handleModalSubmit}
      >
        <p>
          You are about to enter Override Mode for{' '}
          <strong>
            <span className={styles[objStyle]}>
              {playerOrAgentObj.firstName} {playerOrAgentObj.lastName}
            </span>{' '}
            (ID {playerOrAgentObj.id})
          </strong>
          .
        </p>
        <p>You will be redirected to the main dashboard. Proceed with caution.</p>
      </Modal>
      <Modal
        open={clearModalIsOpen}
        title={'Exit Override Mode?'}
        submitText={'Proceed'}
        onCancel={() => setClearModalIsOpen(false)}
        onSubmit={handleClear}
      >
        <p>
          You are about to exit Override Mode. You will be reset to {profile?.adminMode?.originalType} mode with ID{' '}
          <strong>{profile.adminMode?.originalId}</strong>.
        </p>
        <p>You will be redirected to the main dashboard.</p>
      </Modal>
      <form className={styles.FormDiv}>
        <h2>Set {type === 'financial' ? 'Financial Advisor' : type} ID:</h2>
        <div className={styles.FormItemDiv}>
          <input id={`${type}-input`} type="text" placeholder={`${type} ID`} onChange={handleChange} value={playerOrAgentId} />
          <div className={styles.AdminBtnGroup}>
            <button className={styles.AdminBtn} onClick={handleVerify} id={`verify-${type}`} disabled={disabled}>
              Verify ID
            </button>
            <button className={styles.AdminBtn} onClick={(e) => showModal(e, 'confirm')} id={`confirm-${type}`} disabled={disabled}>
              Confirm ID
            </button>
            <button className={styles.AdminBtn} onClick={(e) => showModal(e, 'clear')} id={`clear-${type}`}>
              Clear ID
            </button>
          </div>
        </div>
        <p className={styles.SelectedPlayerOrAgent}>
          <span>Selected {type === 'financial' ? 'financial advisor' : type}: </span>
          <span className={styles[objStyle]}>{isLoading ? <Loader height={20} width={60} /> : objSelected}</span>
          <span>{playerOrAgentObj?.id ? ` (ID ${isLoading ? playerOrAgentId : playerOrAgentObj.id})` : ''}</span>
        </p>
      </form>
    </>
  );
}
