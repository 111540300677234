import React, { useCallback, useEffect, useState } from 'react';
import CardHeader from '../../../Common/Headers/CardHeader';
import styles from './DashboardComponents.module.css';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import CommitmentApi from '../../../../api/commitmentApi';
import CommitmentStatusCard from '../../../Common/Cards/CommitmentStatusCard';
import Loader from '../../../Common/Loader/Loader';
import TourApi from '../../../../api/tourApi';
import { isCommitted } from '../../../../util/commitmentUtils';
import { CODE_LOOKUP } from '../../../../util/tabMap';

function CommitmentStatus({ playerId, globalStyles }) {
  const [isLoadingInitial, setIsLoadingInitial] = useState(true);
  const [statusCards, setStatusCards] = useState([]);
  const { players } = useSelector((state) => state.profile);
  const idToken = useSelector((state) => state.token.id_token);

  const getCard = useCallback(
    async (tournament) => {
      const unfilledCard = (
        <CommitmentStatusCard key={tournament.tournamentID} tournament={tournament} globalStyles={globalStyles} isLoading={false} />
      );
      return TourApi.getTournamentDetails(tournament.tournamentID).then((details) => {
        if (!details) {
          return unfilledCard;
        }
        return TourApi.getTournamentStatus(tournament.tournamentID).then((status) => {
          const currentRound = status?.rounds && status.rounds[0].current_round;
          if (!currentRound) {
            return unfilledCard;
          }
          return TourApi.getTournamentTeeTimes(tournament.tournamentID, currentRound).then((teeTimes) => {
            if (!teeTimes) {
              return unfilledCard;
            }
            const teeTime = teeTimes.find((teeTime) => parseInt(teeTime.p_id) === parseInt(playerId));
            const courseName = details[0]?.courses?.find((course) => course.id === teeTime?.course_id)?.name || '';
            return (
              <CommitmentStatusCard
                key={tournament.tournamentID}
                courseName={courseName}
                tournament={tournament}
                teeTime={teeTime}
                isLoading={false}
                currentRound={currentRound}
                globalStyles={globalStyles}
              />
            );
          });
        });
      });
    },
    [globalStyles, playerId],
  );

  useEffect(() => {
    setIsLoadingInitial(true);
    const tourCodes = players.find((player) => player.playerId === playerId)?.tourCodes;
    const resultsList = [];
    const requestList = tourCodes.map((tourCode) =>
      CommitmentApi.getPlayerCommitmentWorksheet(playerId, tourCode, idToken).then((res) => {
        if (res && res.data) {
          resultsList.push.apply(resultsList, res.data.playerCommitmentTournamentList);
        }
      }),
    );
    Promise.all(requestList).then(() => {
      const nextTournaments = resultsList.filter((item) => {
        const endDate = new Date(item.endDate);
        const nextWeek = new Date(new Date().getTime() + 13 * 24 * 60 * 60 * 1000);
        return endDate < nextWeek;
      });
      const theCardsResponses = [];
      const theCardsRequests = nextTournaments.map((t) => getCard(t).then((card) => theCardsResponses.push(card)));
      setStatusCards(
        nextTournaments.map((t) => <CommitmentStatusCard key={t.tournamentID} globalStyles={globalStyles} isLoading={true} tournament={t} />),
      );
      setIsLoadingInitial(false);
      Promise.all(theCardsRequests).then(() => setStatusCards(theCardsResponses));
    });
  }, [playerId, idToken, players, getCard, globalStyles]);

  if (isLoadingInitial) {
    return (
      <div className={styles.SectionContainer}>
        <CardHeader title="Commitment Status" icon="user-check" globalStyles={globalStyles} />
        <Loader withWrapper />
      </div>
    );
  } else {
    const uncommitted = statusCards.filter((card) => !isCommitted(card.props.tournament.commitmentCode));
    const committed = statusCards.filter((card) => isCommitted(card.props.tournament.commitmentCode));
    const noneUpcoming = <p className="no-data">No upcoming tournaments.</p>;

    return (
      <div className={styles.SectionContainer}>
        <CardHeader title="Commitment Status" icon="user-check" globalStyles={globalStyles} />
        <Tabs>
          <TabList>
            <Tab>
              <div className="upcoming">All Tournaments</div>
            </Tab>
            <Tab>
              <div className="upcoming">Committed</div>
            </Tab>
            <Tab>
              <div className="upcoming">Uncommitted</div>
            </Tab>
          </TabList>
          <TabPanel>
            <div className={styles.EventContainer}>{statusCards.length ? statusCards : noneUpcoming}</div>
          </TabPanel>
          <TabPanel>
            <div className={styles.EventContainer}>{committed.length ? committed : noneUpcoming}</div>
          </TabPanel>
          <TabPanel>
            <div className={styles.EventContainer}>{uncommitted.length ? uncommitted : noneUpcoming}</div>
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

export default CommitmentStatus;
