import {
  AGENT_DASHBOARD_URL,
  FINANCIAL_ADVISOR_DASHBOARD_URL,
  COMMITMENT_WS_URL,
  CONTACT_URL,
  CONTENT_URL,
  CREATE_TEAM_COMMITMENT_URL,
  EARNINGS_STATEMENTS_URL,
  ENDORSEMENTS_URL,
  FIELD_REPORT_URL,
  HELP_URL,
  HOLE_LOCATIONS_URL,
  OWGR_POINTS_URL,
  PARTICIPATION_REPORT_URL,
  PLAYER_DASHBOARD_URL,
  PLAYER_INFO_URL,
  PLAYER_WORKSHEET_URL,
  REORDER_PAGE_URL,
  REORDER_REPORT_URL,
  SETTINGS_URL,
  SITEMAP_URL,
  TEE_TIMES_URL,
  TIMINGS_URL,
  TOURNAMENT_INFO_URL,
  VIDEO_PAGE_URL,
  VIEW_TEAM_COMMITMENT_URL,
  YEAR_END_STATEMENTS_URL,
} from '../url/url';
import LoginPage from '../components/Pages/Auth/LoginPage';
import PlayerLayout from '../components/Pages/Dashboard/PlayerLayout';
import AgentLayout from '../components/Pages/Dashboard/AgentLayout';
import Video from '../components/Pages/Video';
import CommitmentWorkSheet from '../components/Pages/Commitments/CommitmentWorkSheet';
import FieldReport from '../components/Pages/Commitments/FieldReport';
import TeeTimes from '../components/Pages/TeeTimes';
import ContentPage from '../components/Pages/Content/ContentPage';
import ReorderPage from '../components/Pages/Content/ReorderPage';
import SettingsPage from '../components/Pages/Settings';
import Endorsements from '../components/Pages/Endorsements';
import Timings from '../components/Pages/Timings';
import ParticipationReport from '../components/Pages/ParticipationReport';
import PlayerCommitments from '../components/Pages/Commitments/PlayerCommitments';
import PlayerInfoReport from '../components/Pages/PlayerInfo';
import HoleLocations from '../components/Pages/HoleLocations';
import TournamentInfo from '../components/Pages/Content/TournamentInfo';
import OWGRInfo from '../components/Pages/OWGRInfo';
import EarningsStatements from '../components/Pages/EarningsStatements';
import ContactPage from '../components/Pages/Contact';
import ViewTeamCommitment from '../components/Pages/Commitments/ViewTeamCommitment';
import CreateTeamCommitment from '../components/Pages/Commitments/CreateTeamCommitment';
import YearEndStatements from '../components/Pages/YearEndStatements';
import ReorderReport from '../components/Pages/ReorderReport';
import HelpPage from '../components/Pages/Help';
import AuthPage from '../components/Pages/Auth/AuthPage';
import Sitemap from '../components/Pages/Sitemap';
import SharepointLinks from '../local-reports/sharepoint-links';
import LandingPage from '../components/Pages/Landing';
import VideoByUrl from '../components/Pages/VideoByUrl';
import ErrorPage from '../components/Pages/ErrorPage';
import LogoutPage from '../components/Pages/Logout';
import AdminMode from '../components/Pages/AdminMode';
import RealResponseTerms from '../components/Pages/RealResponseTerms';
import FinancialAdvisorLayout from '../components/Pages/Dashboard/FincancialAdvisorLayout';

export const PROTECTED_ROUTES = [
  {
    path: '/login',
    component: LoginPage,
    openBeforeLogin: true,
  },
  {
    path: '/dashboard/admin',
    component: AdminMode,
    adminRequired: true,
  },
  {
    path: YEAR_END_STATEMENTS_URL,
    component: YearEndStatements,
    redirect: '/login?return=ye-statements',
  },
  {
    path: PLAYER_DASHBOARD_URL,
    component: PlayerLayout,
  },
  {
    path: AGENT_DASHBOARD_URL,
    component: AgentLayout,
  },
  {
    path: FINANCIAL_ADVISOR_DASHBOARD_URL,
    component: FinancialAdvisorLayout,
  },
  {
    path: VIDEO_PAGE_URL,
    component: Video,
    redirect: '/login?return=video-player',
  },
  {
    path: COMMITMENT_WS_URL,
    component: CommitmentWorkSheet,
  },
  {
    path: FIELD_REPORT_URL,
    component: FieldReport,
  },
  {
    path: TEE_TIMES_URL,
    component: TeeTimes,
  },
  {
    path: CONTENT_URL,
    component: ContentPage,
  },
  {
    path: REORDER_PAGE_URL,
    component: ReorderPage,
  },
  {
    path: SETTINGS_URL,
    component: SettingsPage,
  },
  {
    path: ENDORSEMENTS_URL,
    component: Endorsements,
  },
  {
    path: TIMINGS_URL,
    component: Timings,
  },
  {
    path: PLAYER_WORKSHEET_URL,
    component: PlayerCommitments,
  },
  {
    path: PARTICIPATION_REPORT_URL,
    component: ParticipationReport,
  },
  {
    path: HOLE_LOCATIONS_URL,
    component: HoleLocations,
  },
  {
    path: PLAYER_INFO_URL,
    component: PlayerInfoReport,
  },
  {
    path: TOURNAMENT_INFO_URL,
    component: TournamentInfo,
  },
  {
    path: OWGR_POINTS_URL,
    component: OWGRInfo,
  },
  {
    path: REORDER_REPORT_URL,
    component: ReorderReport,
  },
  {
    path: EARNINGS_STATEMENTS_URL,
    component: EarningsStatements,
  },
  {
    path: CONTACT_URL,
    component: ContactPage,
  },
  {
    path: VIEW_TEAM_COMMITMENT_URL,
    component: ViewTeamCommitment,
  },
  {
    path: CREATE_TEAM_COMMITMENT_URL,
    component: CreateTeamCommitment,
  },
  {
    path: SITEMAP_URL,
    component: Sitemap,
  },
];

export const OPEN_ROUTES = [
  {
    path: HELP_URL,
    component: HelpPage,
    hasHeader: true,
  },
  {
    path: '/dashboard',
    component: AuthPage,
  },
  {
    path: '/ye-statements',
    component: AuthPage,
  },
  {
    path: '/video-player',
    component: AuthPage,
  },
  {
    path: '/sprout/video',
    component: VideoByUrl,
  },
  {
    path: '/content-error/:reportType/*',
    component: ErrorPage,
    props: {
      type: 'content',
    },
  },
  {
    path: '/logout',
    component: LogoutPage,
  },
  {
    path: '/',
    component: LandingPage,
    exact: true,
  },
  {
    path: '/*',
    component: ErrorPage,
    props: {
      type: '404',
    },
  },
  {
    path: '/local-reports/sharepoint-links',
    component: SharepointLinks,
    developmentOnly: true,
  },
  {
    path: '/tos-real-response',
    component: RealResponseTerms,
    hasHeader: true,
  },
];
