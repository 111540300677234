import React, { useEffect, useState } from 'react';
import styles from './ContentPage.module.css';
import SharepointApi from '../../../api/sharepointApi';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import Loader from '../../Common/Loader/Loader';
import { useSelector } from 'react-redux';
import { fetchSharePointData } from '../../../util/sharepointUtils';
import { SHAREPOINT_BASE_URL } from '../../../url/service-url';

export default function TournamentInfo() {
  const [loadingState, setLoadingState] = useState(0);
  const [content, setContent] = useState([]);
  const [hardcodedPdf, setHardcodedPdf] = useState('');
  const { contentId } = useParams();
  const savedTournamentPages = useSelector((state) => state.tournamentPages);

  useEffect(() => {
    const pageName = contentId.includes('TI-') ? contentId : null;

    if (savedTournamentPages && pageName && savedTournamentPages[pageName]) {
      SharepointApi.getContent(`pages/${savedTournamentPages[pageName].id}`).then((result) => {
        if (!result.error && result.value) {
          setLoadingState(1);
          setContent(result.value);
        } else {
          fetchSharePointData(`${SHAREPOINT_BASE_URL}pages/${savedTournamentPages[pageName].id}`).then((response) => {
            if (response) {
              setHardcodedPdf(response.webUrl);
              setLoadingState(1);
            } else {
              setLoadingState(-1);
            }
          });
        }
      });
    } else if (pageName) {
      SharepointApi.findPageByName(pageName).then((page) => {
        SharepointApi.getContent(`pages/${page.id}`).then((result) => {
          if (!result.error && result.webParts) {
            setLoadingState(1);
            setContent(result.webParts);
          } else {
            fetchSharePointData(`pages/${savedTournamentPages[pageName].id}`).then((response) => {
              if (response) {
                setHardcodedPdf(response.webUrl);
                setLoadingState(1);
              } else {
                setLoadingState(-1);
              }
            });
          }
        });
      });
    } else if (!contentId) {
      setLoadingState(-1);
    } else {
      SharepointApi.getContent(`pages/${contentId}`).then((result) => {
        if (!result.error && result.webParts) {
          setLoadingState(1);
          setContent(result.webParts);
        } else {
          setLoadingState(-1);
        }
      });
    }
  }, [contentId, savedTournamentPages]);

  if (loadingState === 0) {
    return <Loader withWrapper />;
  } else if (loadingState === -1) {
    return (
      <div className={styles.ContentContainer}>
        <p>There was an error getting the tournament information at this time. Please try again later.</p>
      </div>
    );
  } else if (hardcodedPdf != null && hardcodedPdf.length > 0) {
    return (
      <div style={{ margin: '2rem' }}>
        <p>
          <a href={hardcodedPdf}>Click here to view in PDF</a>
        </p>
      </div>
    );
  } else {
    const newContent = content.map((item, i) => {
      if (item.innerHtml) {
        return (
          <div key={i} className={styles.PageContent}>
            {parse(item.innerHtml, { replace: (node) => replaceTournamentInfoPageLinks(node) })}
          </div>
        );
      } else {
        return <div key={i} className={styles.Divider} />;
      }
    });

    return <div className={styles.ContentContainer}>{newContent}</div>;
  }
}

function replaceTournamentInfoPageLinks(domNode) {
  if (domNode.name === 'a' && domNode.attribs.href.includes('sites/PGATOURLINKS')) {
    const split = domNode.attribs.href.substring(domNode.attribs.href.indexOf('sites/PGATOURLINKS/SiteAssets' + 1));
    const text = findText(domNode);
    return (
      <a href={`/${split}`} target="_blank" rel="noreferrer">
        {text}
      </a>
    );
  }
}

function findText(startNode) {
  let currentNode = startNode;
  while (currentNode) {
    if (currentNode.type === 'text') {
      return currentNode.data;
    }
    currentNode = currentNode.children[0];
  }
  return '';
}
