import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import styles from './Modals.module.css';

export default function Modal({ children, title, onCancel, onSubmit, submitText, open, opts }) {
  const theChildren = Array.isArray(children) ? children : [children];
  const content = theChildren.map((child, i) => {
    const special = child?.props?.className?.includes('autocomplete');
    const theChild = child.props?.children || child;
    return special ? (
      <div key={i} className={child?.props?.className}>
        {theChild}
      </div>
    ) : (
      <DialogContentText key={i}>{theChild}</DialogContentText>
    );
  });
  return (
    <Dialog open={open} onClose={onCancel ? onCancel : onSubmit} {...opts}>
      <DialogTitle className={styles.ModalTitle}>{title}</DialogTitle>
      <DialogContent className={styles.ModalContent}>{content}</DialogContent>
      <DialogActions>
        {onCancel && (
          <Button onClick={onCancel} color="inherit">
            Cancel
          </Button>
        )}
        <Button onClick={onSubmit} color="primary">
          {submitText || 'Submit'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
