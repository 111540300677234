import React, { useEffect, useState } from 'react';
import styles from './Endorsements.module.css';
import { useSelector } from 'react-redux';
import SalesforceApi from '../../api/salesforceApi';
import Loader from '../Common/Loader/Loader';
import { intlDateFormat } from '../../util/date';
import { useLocation } from 'react-router-dom';
import Modal from '../Common/Modals/Modal';
import SearchCreate from '../Common/Forms/SearchCreate';

function EndorsementsPage({ globalStyles }) {
  const [isLoading, setIsLoading] = useState(false);
  const [endorsementsData, setEndorsementsData] = useState(null);
  const [expandedMap, setExpandedMap] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [editPayload, setEditPayload] = useState({});
  const [sponsorList, setSponsorList] = useState([]);
  const [lastUpdatedId, setLastUpdatedId] = useState('');
  const location = useLocation();
  const { playerId, playerName, userName } = useSelector(({ profile }) => {
    if (profile.type === 'agent') {
      const playerId = location.state.id;
      return {
        userName: profile.fullName,
        playerId,
        playerName: profile.players.find((p) => p.playerId.toString() === playerId)?.playerName,
      };
    } else {
      return { userName: profile.fullName, playerId: profile.id, playerName: profile.fullName };
    }
  });

  useEffect(() => {
    setIsLoading(true);
    SalesforceApi.getPlayerEndorsements(playerId)
      .then((result) => {
        const areas = result?.data?.endorsementsMap ? Object.keys(result?.data?.endorsementsMap) : [];
        let expandedAreas = {};
        areas.forEach((area) => (expandedAreas[area] = true));
        setExpandedMap(expandedAreas);
        setEndorsementsData(result?.data);
      })
      .finally(() => setIsLoading(false));
  }, [playerId, lastUpdatedId]);

  useEffect(() => {
    SalesforceApi.getEndorsementsBrands().then((result) => {
      const custom = [
        { id: -2, name: '(Open)', color: '#269633' },
        { id: -1, name: '(Not available)', color: '#b03427' },
      ];
      const sponsorList = custom.concat(result?.data || []);
      setSponsorList(sponsorList);
    });
  }, [lastUpdatedId]);

  function handleValueChange(brand) {
    setEditPayload((prevState) => ({
      ...prevState,
      brand: {
        ...prevState.brand,
        ...brand,
      },
    }));
  }

  function toggleAreaExpansion(area) {
    setExpandedMap((prevState) => ({ ...prevState, [area]: !prevState[area] }));
  }

  function handleShowEditModal({ area, location, recordId, displayLocation }) {
    const { salesforcePlayerId } = endorsementsData;
    setEditPayload({ record: { area, location, recordId, displayLocation }, brand: { name: '' }, userName, salesforcePlayerId });
    setShowEditModal(true);
  }

  function handleVerifyEndorsements() {
    setIsLoading(true);
    SalesforceApi.updateContactLastVerified(playerId)
      .then((resp) => {
        setLastUpdatedId(resp?.data?.uniqueId);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handleUpdateSponsor() {
    setIsLoading(true);
    SalesforceApi.updateSponsorshipDeal(editPayload)
      .then((resp) => {
        setLastUpdatedId(resp?.data?.uniqueId);
      })
      .finally(() => {
        setShowEditModal(false);
        setIsLoading(false);
      });
  }

  function generateSponsorText(endorsement) {
    if (endorsement?.brand) {
      return <span>{endorsement.brand}</span>;
    } else if (endorsement?.isAvailable) {
      return <span style={{ color: '#83a181', fontSize: '11px' }}>[OPEN]</span>;
    } else {
      return <span style={{ color: 'lightgrey', fontSize: '11px' }}>(NOT AVAILABLE)</span>;
    }
  }

  if (isLoading) {
    return <Loader withWrapper />;
  } else if (!endorsementsData) {
    return (
      <div className={styles.MainContainer}>
        <div className={styles.EndorsementsContainer}>
          <div style={{ minHeight: globalStyles.windowHeight }}>
            <h1>Endorsements - {playerName}</h1>
            <div style={{ textAlign: 'center' }}>There is currently no information available.</div>
          </div>
        </div>
      </div>
    );
  } else {
    const { endorsementsMap, lastUpdated, contact, verifiedDate } = endorsementsData;
    return (
      <div className={styles.MainContainer}>
        <div className={styles.EndorsementsContainer}>
          <div
            style={{ minHeight: globalStyles.windowHeight + (globalStyles.isTabletSize ? 130 : 90), minWidth: globalStyles.isMobileSize ? 300 : 680 }}
          >
            <h1>Endorsements - {playerName}</h1>
            <div className={styles.InstructionText}>
              <p>
                Below is the information that we have on file for your endorsements.
                {lastUpdated && (
                  <>
                    {' '}
                    Last updated on <strong>{intlDateFormat(lastUpdated)}</strong>.
                  </>
                )}
              </p>
              <p>
                For any questions, please contact <a href={`mailto:${contact}`}>{contact}</a>.
              </p>
              {verifiedDate ? (
                <h3 className={styles.VerifiedText}>
                  <i className="fa fa-check" /> All endorsements verified on {intlDateFormat(verifiedDate)}.
                </h3>
              ) : (
                <h3 className={styles.VerifyText}>Scroll to the bottom to verify.</h3>
              )}
            </div>
            <div className={styles.EndorsementItems}>
              {Object.entries(endorsementsMap).map(([area, endorsements]) => (
                <React.Fragment key={area}>
                  <div className={styles.AreaCategoryLabel} onClick={() => toggleAreaExpansion(area)}>
                    <strong>{area}</strong>
                    <i className={'fas fa-chevron-' + (expandedMap[area] ? 'up' : 'down')} />
                  </div>
                  {expandedMap[area] &&
                    endorsements.map((endorsement) => (
                      <div key={endorsement.order}>
                        <strong>{endorsement.displayLocation}</strong>
                        <span onClick={() => handleShowEditModal(endorsement)}>
                          {generateSponsorText(endorsement)}
                          &nbsp;&nbsp;
                          <i className="fa fa-pencil" />
                        </span>
                      </div>
                    ))}
                </React.Fragment>
              ))}
            </div>
            <div className={styles.VerifyButtonWrapper}>
              <button className={styles.VerifyButton} onClick={handleVerifyEndorsements}>
                Verify Endorsements
              </button>
            </div>
          </div>
        </div>
        <Modal
          open={showEditModal}
          onCancel={() => setShowEditModal(false)}
          title="Update Sponsor"
          submitText="Update"
          onSubmit={handleUpdateSponsor}
        >
          <div className="endorsements-modal-label-group autocomplete-group">
            <label>Location</label>
            <div>{editPayload.record?.displayLocation}</div>
          </div>
          <div className="endorsements-modal-label-group autocomplete-group">
            <label>Current Sponsor</label>
            <div>
              {generateSponsorText(endorsementsMap[editPayload?.record?.area]?.find((o) => o.displayLocation === editPayload.record.displayLocation))}{' '}
            </div>
          </div>
          <div className="endorsements-modal-label-group autocomplete-input">
            <label>New Sponsor</label>
            {sponsorList.length === 0 ? (
              <Loader height={10} width={300} withWrapper wrapperStyle={{}} />
            ) : (
              <SearchCreate value={editPayload?.brand} label="New Sponsor" onValueChange={handleValueChange} options={sponsorList} />
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

export default EndorsementsPage;
