import React from 'react';
import styles from './TeamCommitment.module.css';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { intlDateFormat } from '../../../util/date';
import CommitmentContainer from './CommitmentContainer';
import { playerIdTourCodeLookup } from '../../../reducers/commitmentInfoReducer';
import { decodePlayer, encodePlayer } from '../../../util/titles';
import { getDataFromField } from '../../../util/generalUtils';
import Loader from '../../Common/Loader/Loader';

export default function ViewTeamCommitment({ globalStyles }) {
  const { tournamentScheduleId } = useParams();
  const navigate = useNavigate();
  const { commitmentInfo, profile } = useSelector((state) => state);
  const encodedPlayerId = new URLSearchParams(useLocation().search).get('player');
  const playerId = encodedPlayerId ? decodePlayer(encodedPlayerId) : profile.id;
  const tourCode = new URLSearchParams(useLocation().search).get('tour') || profile.tourCodes[0];
  const commitmentInfoForPlayerTour = commitmentInfo[playerIdTourCodeLookup(playerId, tourCode)];
  const commitmentTournamentInfo = commitmentInfoForPlayerTour.playerCommitmentTournamentList.find(
    (item) => item.tournamentScheduleId.toString() === tournamentScheduleId,
  );

  const { playerTeammateName } = getDataFromField(tournamentScheduleId, playerId);

  return (
    <CommitmentContainer globalStyles={globalStyles} isError={false}>
      <div className={styles.ViewContainer}>
        <div className={styles.SectionContainer}>
          <div className={styles.HeaderText}>Commitment Information</div>
          <table className={styles.Table}>
            <tbody>
              <tr>
                <td>Player</td>
                <td>{commitmentInfoForPlayerTour.playerName}</td>
              </tr>
              <tr>
                <td>Tour</td>
                <td>{commitmentInfoForPlayerTour.tourLongName}</td>
              </tr>
              <tr>
                <td>Tournament</td>
                <td>{commitmentTournamentInfo.tournamentScheduleName}</td>
              </tr>
              <tr>
                <td>Number</td>
                <td>{commitmentTournamentInfo.commitmentNumber}</td>
              </tr>
              <tr>
                <td>Date Committed</td>
                <td>{intlDateFormat(commitmentTournamentInfo.dateCommitted)}</td>
              </tr>
              <tr>
                <td>Teammate</td>
                <td>{playerTeammateName === undefined ? <Loader height={16} width={40} /> : playerTeammateName}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <button
          className={styles.CreateButton}
          onClick={() => navigate(profile.type === 'player' ? '/worksheet' : `/commitments/${encodePlayer(playerId)}`)}
        >
          OK
        </button>
      </div>
    </CommitmentContainer>
  );
}
