import React from 'react';
import styles from './Footer.module.css';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function Footer({ globalStyles: { isMobileSize } }) {
  const location = useLocation();
  const profile = useSelector((state) => state.profile);
  if (['/dashboard', '/ye-statements'].includes(location.pathname) || !profile) {
    return null;
  }

  return (
    <footer>
      <div className={styles.VerticalFooterContainer}>
        <div className={isMobileSize ? '' : styles.HorizontalFooterContainer}>
          <div className={styles.FooterItem}>
            <a href="tel:+18887429558" tabIndex="0">
              1.888.742.9558
            </a>
          </div>
          <div className={styles.FooterItem}>
            <a href="mailto:playertechnology@pgatourhq.com" tabIndex="0">
              playertechnology@pgatourhq.com
            </a>
          </div>
          <div className={styles.FooterItem}>
            <Link to="/sitemap" tabIndex="0">
              SITEMAP
            </Link>
          </div>
          <div className={styles.FooterItem}>
            <Link to="/help" tabIndex="0">
              Help
            </Link>
          </div>
          <div className={styles.FooterItem}>
            <a tabIndex="0" href="https://www.facebook.com/PGATour/" target="_">
              <i className="fab fa-facebook-square" />
            </a>
            <a tabIndex="0" href="https://twitter.com/PGATOUR" target="_">
              <i className="fab fa-twitter-square" />
            </a>
            <a tabIndex="0" href="https://www.youtube.com/pgatour" target="_">
              <i className="fab fa-youtube-square" />
            </a>
            <a tabIndex="0" href="https://www.instagram.com/pgatour" target="_">
              <i className="fab fa-instagram-square" />
            </a>
          </div>
        </div>
        <div className={styles.Copyright}>
          <div style={{ color: '#fff' }}>&copy; 2020 PGA TOUR, INC.</div>
          <div style={{ fontSize: '18px', color: '#DEDEDE' }}>|</div>
          <div style={{ color: '#fff' }}>all rights reserved.</div>
        </div>
      </div>
    </footer>
  );
}
