import styles from './CommitmentWorkSheet.module.css';
import teamStyles from './TeamCommitment.module.css';
import React from 'react';
import { intlDateFormat } from '../../../util/date';

export default function TeamFieldReportProam({ teamFieldProamObj, globalStyles }) {
  const categoryKeys = Object.keys(teamFieldProamObj.categoryNumberOfPlayersMap);
  categoryKeys.sort((a, b) => {
    const minOrderA = teamFieldProamObj.proamPlayerList.filter((obj) => obj.category === a)[0]?.order;
    const minOrderB = teamFieldProamObj.proamPlayerList.filter((obj) => obj.category === b)[0]?.order;
    return minOrderA < minOrderB ? -1 : 1;
  });

  function renderTable(categoryName) {
    const firstPosition = teamFieldProamObj.proamPlayerList.filter((obj) => obj.category === categoryName && obj.displayColumn === 1);
    const secondPosition = teamFieldProamObj.proamPlayerList.filter((obj) => obj.category === categoryName && obj.displayColumn === 2);
    const categoryNumberOfPlayers = teamFieldProamObj.categoryNumberOfPlayersMap[categoryName];
    return (
      <div className={styles.Category} key={categoryName}>
        <table className={teamStyles.TeamProamTable}>
          <thead>
            <tr>
              <th colSpan={3} className={styles.CategoryHeader}>
                {categoryName} - ({categoryNumberOfPlayers})
              </th>
              <th colSpan={3} className={styles.CategoryHeader}>
                {secondPosition.length ? `${categoryName} - (${categoryNumberOfPlayers})` : ' '}
              </th>
            </tr>
            <tr>
              <th />
              <th>Order</th>
              <th>{teamFieldProamObj.categoryYearLabelMap[categoryName]} FEC RANK</th>
              <th />
              <th>{secondPosition.length ? 'Order' : ' '}</th>
              <th>{secondPosition.length ? `${teamFieldProamObj.categoryYearLabelMap[categoryName]} FEC RANK` : ' '}</th>
            </tr>
          </thead>
          <tbody>
            {firstPosition.map((firstPlayer) => {
              const secondPlayer = secondPosition.find((obj) => obj.order === firstPlayer.order);
              return (
                <tr key={firstPlayer.id}>
                  <td>{firstPlayer.name}</td>
                  <td>{firstPlayer.order}</td>
                  <td>{firstPlayer.fecRank}</td>
                  <td>{secondPlayer?.name}</td>
                  <td>{secondPlayer?.order}</td>
                  <td>{secondPlayer?.fecRank}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className={styles.MainContainer}>
      <div className={styles.FieldReportContainer}>
        <h1 className={styles.TournamentName}>Pro-Am Field - PGA TOUR</h1>
        <h2>
          {' '}
          {teamFieldProamObj.tournamentName} (Ends {intlDateFormat(teamFieldProamObj.endDate)})
        </h2>
        {categoryKeys.map((categoryName) => renderTable(categoryName))}
      </div>
    </div>
  );
}
