import { RECEIVE_COMMITMENT_RESPONSE } from '../util/actionTypes';

const commitmentResponseReducer = (state = null, { type, payload }) => {
  switch (type) {
    case RECEIVE_COMMITMENT_RESPONSE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default commitmentResponseReducer;
