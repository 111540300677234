import React, { useEffect, useState } from 'react';
import styles from './ContentHeader.module.css';

export default function ContentHeader({ globalStyles, tour, title, isLogoOnly, logoFile }) {
  const [bannerImage, setBannerImage] = useState();
  const [logoImage, setLogoImage] = useState();

  useEffect(() => {
    const theTour = tour.toLowerCase();
    const theSponsorSpecificTour = theTour;
    setBannerImage(require(`../../img/banner_${theTour}.png`));
    if (logoFile) {
      setLogoImage(require(`../../img/${logoFile}`));
    } else {
      setLogoImage(require(`../../img/bannerLogo_${theSponsorSpecificTour}.png`));
    }
  }, [logoFile, title, tour]);

  if (globalStyles.isMobileSize) {
    return (
      <div className={styles.Container} style={{ height: isLogoOnly ? '150px' : '225px' }}>
        <div className={styles.MobileContentHeaderContainer}>
          <img className={styles.MobileLogo} src={logoImage} alt="logo icon for tour" />
          {!isLogoOnly && <div className={styles.HorizontalSpacer} />}
          <h1 className={styles.MobileHeading}>{isLogoOnly ? '' : title}</h1>
        </div>
        <div className={styles.BackgroundImage} style={{ backgroundImage: `url(${bannerImage})` }} />
        <div className={styles.Shade} />
      </div>
    );
  } else {
    return (
      <div className={styles.Container}>
        <div className={styles.ContentHeaderContainer}>
          <img className={styles.Logo} src={logoImage} alt="logo icon for tour" />
          {!isLogoOnly && <div className={styles.VerticalSpacer} />}
          <h1 className={styles.Heading} style={{ fontSize: globalStyles.isTabletSize ? '38px' : '43px' }}>
            {isLogoOnly ? '' : title}
          </h1>
        </div>
        <div className={styles.BackgroundImage} style={{ backgroundImage: `url(${bannerImage})` }} />
        <div className={styles.Shade} />
      </div>
    );
  }
}
