import { RECEIVE_PROFILE_DATA, ADD_PROFILE_DATA, CLEAR_TOKENS } from '../util/actionTypes';

const profileReducer = (state = null, { type, payload }) => {
  switch (type) {
    case RECEIVE_PROFILE_DATA:
      return {
        fullName: payload.profile.fullName,
        firstName: payload.profile.firstName,
        lastName: payload.profile.lastName,
        id: payload.profile.id,
        email: payload.profile.email,
        players: payload.profile.players,
        tourCodes: payload.profile.tourCodes,
        type: payload.profile.type,
        adminMode: payload.profile.adminMode,
      };
    case ADD_PROFILE_DATA:
      return {
        ...state,
        fullName: payload.profile.fullName,
        firstName: payload.profile.firstName,
        lastName: payload.profile.lastName,
        id: payload.profile.id,
        email: payload.profile.email,
        players: payload.profile.players,
        tourCodes: payload.profile.tourCodes,
        type: payload.profile.type,
        adminMode: payload.profile.adminMode,
      };
    case CLEAR_TOKENS:
      return null;
    default:
      return state;
  }
};

export default profileReducer;
