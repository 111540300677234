const appURL = `${process.env.REACT_APP_APP_URL}`;

export const SHAREPOINT_BASE_URL =
  'https://graph.microsoft.com/v1.0/sites/pgatourinc.sharepoint.com,dabf0690-6432-422e-970c-dc6075b0d0fc,b391ca26-3d85-4409-ab23-3dd67478a3f0/';

// SharePoint Content URLS
const sharepointBasePagesURL = `${SHAREPOINT_BASE_URL}pages/`;

export const PGA_TOUR_ANNOUNCEMENTS = sharepointBasePagesURL + '451a6983-d91a-41e9-a204-512513fad43a';
export const PGA_TOUR_ANNOUNCEMENTS_AGT = sharepointBasePagesURL + 'f4575cc7-c8a8-434b-86f3-f2f755076f81';
export const PGA_TOUR_CONTACTS = sharepointBasePagesURL + 'a1630929-0144-4d03-b239-5b0c648d4a38';

export const CHAMP_TOUR_ANNOUNCEMENTS = sharepointBasePagesURL + 'f2440c2c-572e-4381-b750-300ab20ed2b4';
export const CHAMP_TOUR_ANNOUNCEMENTS_AGT = sharepointBasePagesURL + '81591001-3919-4a93-9184-34b1555aceaf';
export const CHAMPIONS_TOUR_CONTACTS = sharepointBasePagesURL + 'b6000a86-955c-4ccd-8745-3762a40458f7';

export const KF_TOUR_ANNOUNCEMENTS = sharepointBasePagesURL + '69525f7b-606a-4d03-a17c-9ccc57e7cf06';
export const KF_TOUR_ANNOUNCEMENTS_AGT = sharepointBasePagesURL + 'a49d0fb4-fbaf-4f4d-bc15-210bbf5b4451';
export const KF_TOUR_CONTACTS = sharepointBasePagesURL + 'f57026fc-5445-4385-bc2a-5385a823f0c4';

export const PGA_TOUR_AMERICAS_ANNOUNCEMENTS = sharepointBasePagesURL + 'b4ee5c4e-6b66-4eac-b64a-4362600b4661';
export const PGA_TOUR_AMERICAS_ANNOUNCEMENTS_AGT = sharepointBasePagesURL + '7f04aa9c-8515-4145-b9d8-4818270fa5d7';
export const PGA_TOUR_AMERICAS_CONTACTS = sharepointBasePagesURL + 'f0f07466-9e56-4f5b-801c-df75dd02e1ae';

export const EMAIL_URL = appURL + 'send-email';

export const EARNING_STATEMENT_URL = appURL + 'earnings-statements';
export const YEAR_END_STATEMENT_SERVICE_URL = appURL + 'ye-statements';
export const ENCODED_ID = appURL + 'id-string';

// Authorization URLs
export const SHAREPOINT_AUTHENTICATION_URL = appURL + 'sharepoint-auth';
export const TOUR_AUTH_URL = appURL + 'tour-auth';

// Salesforce URLs
export const PROFILE_INFO_URL = appURL + 'profile-info';
export const ENDORSEMENTS_INFO_URL = appURL + 'endorsements';

// TourHub/Commitment API URLs
export const COMMITMENT_URL = appURL;

// // OneLogin URLs
export const ONE_LOGIN_AUTH2 = appURL + 'ol-auth2';
export const ONE_LOGIN_AUTH_REFRESH = appURL + 'ol-refresh';
