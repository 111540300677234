import React from 'react';
import styles from './DropdownMenu.module.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { encodePlayer } from '../../../util/titles';

function DropdownMenu({ type, clickHandler, globalStyles, logout }) {
  const profile = useSelector((state) => state.profile);
  const hasAdminRole = profile?.adminMode?.hasRole;

  switch (type) {
    case 'player-account':
      return (
        <div className={styles.AccountDropdownMenuContainer}>
          <ul>
            <li className={styles.NavHeader}>My Account</li>
            <li className={styles.PlayerName}>
              <Link to="/endorsements" onClick={clickHandler}>
                Endorsements
                <i className="fas fa-arrow-right" />
              </Link>
            </li>
            <li className={styles.PlayerName}>
              <Link to="/playerinfo" onClick={clickHandler}>
                Player Information
                <i className="fas fa-arrow-right" />
              </Link>
            </li>
            <li className={styles.PlayerName}>
              <Link to="/settings" onClick={clickHandler}>
                Preferences
                <i className="fas fa-arrow-right" />
              </Link>
            </li>
            {hasAdminRole && (
              <li className={styles.PlayerName}>
                <Link to="/dashboard/admin" onClick={clickHandler}>
                  Admin Mode
                  <i className="fas fa-arrow-right" />
                </Link>
              </li>
            )}
            <li className={styles.PlayerName} onClick={logout}>
              <span className={styles.LogoutItem}>
                Logout <i className="fas fa-sign-out-alt" />
              </span>
            </li>
          </ul>
        </div>
      );
    case 'agent-account':
      return (
        <div className={styles.AccountDropdownMenuContainer}>
          <ul>
            {hasAdminRole && (
              <li className={styles.PlayerName}>
                <Link to="/dashboard/admin" onClick={clickHandler}>
                  Admin Mode
                  <i className="fas fa-arrow-right" />
                </Link>
              </li>
            )}
            <li className={styles.PlayerName} onClick={logout}>
              <span className={styles.LogoutItem}>
                Logout <i className="fas fa-sign-out-alt" />
              </span>
            </li>
          </ul>
        </div>
      );
    case 'financial-account':
      return (
        <div className={styles.AccountDropdownMenuContainer}>
          <ul>
            {hasAdminRole && (
              <li className={styles.PlayerName}>
                <Link to="/dashboard/admin" onClick={clickHandler}>
                  Admin Mode
                  <i className="fas fa-arrow-right" />
                </Link>
              </li>
            )}
            <li className={styles.PlayerName} onClick={logout}>
              <span className={styles.LogoutItem}>
                Logout <i className="fas fa-sign-out-alt" />
              </span>
            </li>
          </ul>
        </div>
      );
    case 'player':
      const playerNames = profile.players.map((player) => (
        <li className={styles.PlayerName} key={player.playerId}>
          <Link tabIndex="0" to={'/commitments/' + encodePlayer(player.playerId)} onClick={clickHandler}>
            {player.playerName} <i className="fas fa-arrow-right" />
          </Link>
        </li>
      ));

      return (
        <div className={styles.PlayerDropdownMenuContainer} style={{ top: globalStyles.isTabletSize ? '45px' : '40px' }}>
          <ul>{playerNames}</ul>
        </div>
      );
    default:
      return null;
  }
}

export default DropdownMenu;
