import { stringify } from 'query-string';
import { ONE_LOGIN_AUTH2, ONE_LOGIN_AUTH_REFRESH } from '../url/service-url';

// Static oidc params for a single provider
const authority = 'https://pgatour.onelogin.com/oidc/2';
const client_id = '5a9f2030-916c-0138-46e1-0a1614e7d5f4136057';
const response_type = 'code';
const scope = 'openid profile groups params';

export const beginAuth = ({ state, nonce, redirectTo }) => {
  // Generate authentication URL
  const params = stringify({
    client_id,
    redirect_uri: process.env.REACT_APP_WEB_APP_BASE + redirectTo,
    response_type,
    scope,
    state,
    nonce,
  });
  const authUrl = `${authority}/auth?${params}`;

  // Attempt login by navigating to authUrl
  window.location.assign(authUrl);
};

export const logout = ({ accessToken }) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
  myHeaders.append(
    'Authorization',
    `Basic NWE5ZjIwMzAtOTE2Yy0wMTM4LTQ2ZTEtMGExNjE0ZTdkNWY0MTM2MDU3OmJmNDMxYjhlNGRhZDA0YjJiOTEwZDBhNWQ1YTdhNTdiZTJhNjRmNjI2MzM2ZGQ2MzUyNDcwZGU5NmYwYWMxYzc`,
  );
  myHeaders.append('Cookie', 'ol_oidc_canary_46=false; ol_login_rules_canary_0=false');

  const urlencoded = new URLSearchParams();
  urlencoded.append('token', accessToken);
  urlencoded.append('token_type_hint', 'access_token');

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow',
  };

  fetch('https://pgatour.onelogin.com/oidc/2/token/revocation', requestOptions)
    .then((response) => response.text())
    .then((result) => {
      console.log('token revoked');
      window.location = '/';
    })
    .catch((error) => console.log('error', error));
};

export const getAccessToken = (code, redirect) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('code', code);
  urlencoded.append('redirect', redirect);

  const postData = {
    method: 'POST',
    body: urlencoded,
  };

  return fetch(ONE_LOGIN_AUTH2, postData)
    .then((res) => res.json())
    .catch((error) => console.log(error));
};

export const refreshAccessToken = (refreshToken) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('token', refreshToken);

  const postData = {
    method: 'POST',
    body: urlencoded,
  };

  return fetch(ONE_LOGIN_AUTH_REFRESH, postData)
    .then((res) => res.json())
    .catch((error) => {
      console.log(error);
    });
};
