import React from 'react';
import styles from './Alerts.module.css';
import { intlDateFormatNumeric } from '../../../util/date';
import { useSelector } from 'react-redux';

export default function FieldAlert({ type, title, dates }) {
  const commitmentResponse = useSelector((state) => state.postCommitmentResponse);

  if (type === 'positive') {
    return (
      <div className={[styles.AlertWrapper, styles.AlertWrapper_Positive].join(' ')}>
        <div className={styles.AlertIcon_Positive}>
          <i className="fas fa-check-circle" />
        </div>
        <div className={styles.FieldText}>The player has met the strength of field requirements for the current season.</div>
      </div>
    );
  } else if (type === 'title') {
    return (
      <div className={[styles.AlertWrapper_Small, styles.AlertWrapper_Title].join(' ')}>
        <div className={styles.AlertIcon_Title}>{title}</div>
        <div className={styles.FieldTextInfo}>
          <strong>Dates:</strong>&nbsp;
          {intlDateFormatNumeric(dates[0])} -{intlDateFormatNumeric(dates[1])}
        </div>
      </div>
    );
  } else if (type === 'commit-success') {
    return (
      <div className={[styles.AlertWrapper, styles.AlertWrapper_SuccessCommit].join(' ')}>
        <div className={styles.AlertIcon_SuccessCommit}>
          <i className="fas fa-check-circle" />
        </div>
        <div className={styles.FieldText}>
          {commitmentResponse?.playerCommitment[0].tournamentName}: {commitmentResponse?.instructions}
        </div>
      </div>
    );
  } else {
    return (
      <div className={[styles.AlertWrapper, styles.AlertWrapper_Negative].join(' ')}>
        <div className={styles.AlertIcon_Negative}>
          <i className="fas fa-exclamation-circle" />
        </div>
        <div className={styles.FieldText}>This Player has not met the Strength of Field requirement for the current season.</div>
      </div>
    );
  }
}
