import React from 'react';
import { Navigate } from 'react-router-dom';

/**
 * ProtectedRoute locks a route unless the open prop is true
 *
 * @param {Bool} open - if true, renders `component` prop. If false, redirects to `redirect` location
 * @param {Component} component - Component to render when accessible
 * @param {String} redirect - path to redirect to when inaccessible
 */
function ProtectedRoute({ open, component: Component, redirect, ...rest }) {
  if (!redirect) {
    redirect = '/';
  }
  return open ? <Component {...rest} /> : <Navigate to={redirect} />;
}

export default ProtectedRoute;
