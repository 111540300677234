import styles from './HeaderPreAuth.module.css';
import logo from '../../img/logo_pgacolor.png';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { OPEN_ROUTES } from '../../../util/routes';
export default function HeaderPreAuth() {
  const validHeaderPaths = OPEN_ROUTES.filter((route) => route.hasHeader).map((route) => route.path);
  const location = useLocation();
  if (!validHeaderPaths.includes(location.pathname)) {
    return null;
  }
  return (
    <div className={styles.HeaderPreAuth}>
      <Link to="/">
        <img src={logo} alt="Logo of PGA Tour" />
        <span>PGA TOUR LINKS</span>
      </Link>
    </div>
  );
}
