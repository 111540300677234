/**
 * intlDateFormat formats a date based on a string passed in
 * @param date original JS date object
 * @param optString optional string to specify how to format the date, e.g. "ms-dn-yn" will use {month: 'short', day: 'numeric', year: 'numeric', timeZone: 'UTC'}
 * @return string formatted date
 */
export function intlDateFormat(date, optString = 'ms-d2-yn') {
  if (!date) {
    return '';
  }
  let options = { timeZone: 'UTC' };
  const lookup = { y: 'year', m: 'month', d: 'day', 2: '2-digit', n: 'numeric', s: 'short', h: 'hour', t: 'minute', c: 'second' };
  const optArray = optString.split('-');
  for (let [shortProperty, shortValue] of optArray) {
    options[lookup[shortProperty]] = lookup[shortValue];
  }
  return new Intl.DateTimeFormat('en-US', options).format(new Date(date));
}

export function intlDateFormatNumeric(date) {
  if (!date) {
    return '';
  }
  return new Intl.DateTimeFormat('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC',
  }).format(new Date(date));
}

export function formatTournamentDateRange(tournamentInfo, withYear = false) {
  if (!tournamentInfo) {
    return '';
  }
  const { startDate, endDate } = tournamentInfo;
  const secondPartFormat = tournamentInfo.sameMonth ? 'd2' : 'ms-d2';
  const year = withYear ? `, ${intlDateFormat(endDate, 'yn')}` : '';
  return `${intlDateFormat(startDate, 'ms-d2')}-${intlDateFormat(endDate, secondPartFormat)}${year}`;
}
