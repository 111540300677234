import store from '../reducers/_store';

export function getDataFromField(tournamentScheduleId, playerId) {
  const { teamField } = store.getState();
  const field = teamField && teamField[tournamentScheduleId] ? teamField[tournamentScheduleId].teamFieldList : null;
  const objectIfTopPlayer = field?.find((player) => player.id.toString() === playerId.toString());
  const objectIfTeammate = field?.find((player) => player.teammate.id?.toString() === playerId.toString());

  let playerTeammateName, playerTeamConfirmed;
  if (objectIfTopPlayer) {
    playerTeammateName = objectIfTopPlayer.teammate.name;
    playerTeamConfirmed = objectIfTopPlayer.confirmed;
  } else if (objectIfTeammate) {
    playerTeammateName = objectIfTeammate.name;
    playerTeamConfirmed = objectIfTeammate.confirmed;
  }

  return { playerTeammateName, playerTeamConfirmed };
}

export function randomBase64String() {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < 28; i++) {
    const randChar = characters.charAt(Math.floor(Math.random() * characters.length));
    result += randChar;
  }
  return btoa(result);
}

export function getFixedLength(value, len) {
  let str = value.toString();
  for (let i = str.length; i < len; i++) {
    str = '0' + str;
  }
  return str;
}

export function getTime() {
  return new Date().getTime();
}

export function setLastClosed() {
  localStorage.setItem('lastClosed', getTime());
}

export function getLastClosed() {
  const time = localStorage.getItem('lastClosed');
  return time ? Number(time) : null;
}

export function setActiveSession() {
  sessionStorage.setItem('activeSession', 'true');
  localStorage.setItem('activeSession', 'true');
}

export function isNewTab() {
  const otherTabIsOpen = localStorage.getItem('activeSession') === 'true';
  const thisTabIsNew = sessionStorage.getItem('activeSession') !== 'true';
  return otherTabIsOpen && thisTabIsNew;
}

export function clearSession() {
  localStorage.setItem('activeSession', 'false');
}

export function getPlayerId(tourCode, props) {
  if (props.profile.type === 'player') {
    return props.profile.id;
  } else {
    return props.profile.players.find((player) => player.tourCodes.includes(tourCode))?.playerId;
  }
}
