import { RECEIVE_COMMITMENT_EVENT, RECEIVE_COMMITMENT_INFO, RECEIVE_COMMITMENT_RESPONSE, RECEIVE_TEAM_FIELD } from '../util/actionTypes';
import store from '../reducers/_store';

export const receiveCommitmentInfo = (commitmentInfo) => ({
  type: RECEIVE_COMMITMENT_INFO,
  payload: commitmentInfo,
});

export const receiveTeamField = (tournamentScheduleId, teamField) => ({
  type: RECEIVE_TEAM_FIELD,
  payload: teamField,
  tournamentScheduleId,
});

export const receiveCommitmentEvents = () => {
  const { commitmentInfo } = store.getState();
  return {
    type: RECEIVE_COMMITMENT_EVENT,
    commitmentInfoState: commitmentInfo,
  };
};

export const receivePostCommitmentResponse = (commitmentResponse) => ({
  type: RECEIVE_COMMITMENT_RESPONSE,
  payload: commitmentResponse,
});
