import React, { useEffect, useState } from 'react';
import styles from './ContentPage.module.css';
import ContentHeader from '../../Common/Headers/ContentHeader';
import { Link, useParams } from 'react-router-dom';
import Parser from 'html-react-parser';
import SharepointApi from '../../../api/sharepointApi';
import NavigationRoles from '../../Common/Navigation/NavigationRoles.json';
import Loader from '../../Common/Loader/Loader';
import { TOUR_CODE_BY_SHORTNAME } from '../../../util/titles';
import TourApi from '../../../api/tourApi';
import { useDispatch } from 'react-redux';
import { receiveReorderStandings } from '../../../actions/reorderStandingsActions';

const TOUR_TO_EXEMPTIONS = {
  pga: [884, 883, 882, 181, 205, 203],
  kornFerry: [881, 305],
  americas: [882, 260],
};

function ReorderPage({ globalStyles }) {
  const { tourName } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [sharepointContent, setSharepointContent] = useState(null);
  const [reorderContent, setReorderContent] = useState([]);
  const dispatch = useDispatch();
  const exemptionsInHalf = Math.ceil(TOUR_TO_EXEMPTIONS[tourName].length / 2);
  const draftNumbers = TOUR_TO_EXEMPTIONS[tourName].slice(0, exemptionsInHalf);
  const officialNumbers = TOUR_TO_EXEMPTIONS[tourName].slice(-exemptionsInHalf);
  const draftContent = reorderContent.slice(0, exemptionsInHalf).filter((c) => !!c);
  const officialContent = reorderContent.slice(-exemptionsInHalf).filter((c) => !!c);

  useEffect(() => {
    setIsLoading(true);
    const contentId = NavigationRoles.player.tours[tourName].accessSummary;
    SharepointApi.getContent(contentId).then((res) => {
      setSharepointContent(res.error ? null : res.value[0].innerHtml);
    });
    const tourCode = TOUR_CODE_BY_SHORTNAME[tourName];
    const promises = TOUR_TO_EXEMPTIONS[tourName].map((exemptionNumber) => {
      return TourApi.getReorderStandings(tourCode, exemptionNumber);
    });
    Promise.all(promises)
      .then((responses) => {
        const results = responses.map((result) => (result && result[0] ? result[0] : null));
        dispatch(receiveReorderStandings(tourName, results));
        setReorderContent(results);
      })
      .finally(() => setIsLoading(false));
  }, [dispatch, tourName]);

  if (isLoading || sharepointContent === null) {
    return <Loader withWrapper />;
  } else {
    return (
      <div>
        <ContentHeader title="Reorder and Access Summary" tour={tourName} globalStyles={globalStyles} />
        <div className={styles.AnchorLinks}>
          <div className={styles.AnchorLinksHeader}>Scroll to:</div>
          <div className="anchor-link">
            <a tabIndex="0" href="#anchor0">
              Reorder Standings <i className="fas fa-arrow-circle-down" />
            </a>
          </div>
          <div className="anchor-link">
            <a tabIndex="0" href="#anchor1">
              Access Summary <i className="fas fa-arrow-circle-down" />
            </a>
          </div>
        </div>
        <div className={styles.ContentContainer} style={{ minHeight: globalStyles.windowHeight }}>
          <div className={styles.PageContent}>
            <h1 className="page-title" id="anchor0">
              Reorder Standings
            </h1>
            {tourName === 'pga' ? (
              <div className="content-section">
                <h2>Draft Reorder</h2>
                {draftContent.length > 0
                  ? draftNumbers.map((eNum, i) => (
                      <p key={eNum}>
                        <Link to={`/reorder-report/${tourName}/${eNum}`} tabIndex="0">
                          {reorderContent[i]?.exemption_name}
                        </Link>
                      </p>
                    ))
                  : 'No data available at this time'}
                <h2>Official Reorder</h2>
                {officialContent.length > 0
                  ? officialNumbers.map((eNum, i) => (
                      <p key={eNum}>
                        <Link to={`/reorder-report/${tourName}/${eNum}`} tabIndex="0">
                          {reorderContent[i + exemptionsInHalf]?.exemption_name}
                        </Link>
                      </p>
                    ))
                  : 'No data available at this time'}
              </div>
            ) : (
              <div className="content-section">
                {TOUR_TO_EXEMPTIONS[tourName].map((eNum, i) => (
                  <p key={eNum}>
                    <Link to={`/reorder-report/${tourName}/${eNum}`} tabIndex="0">
                      {i === 0 ? 'Draft' : 'Official'} Reorder
                    </Link>
                  </p>
                ))}
              </div>
            )}
          </div>
          <div className={styles.PageContent}>
            <div className="page-title" id="anchor1">
              Access Summary
            </div>
            <div className="content-section">{Parser(sharepointContent, { replace: (node) => replaceTournamentInfoPageLinks(node) })}</div>
          </div>
        </div>
      </div>
    );
  }
}

function replaceTournamentInfoPageLinks(domNode) {
  if (domNode.name === 'a' && domNode.attribs.href.includes('sites/PGATOURLINKS')) {
    const split = domNode.attribs.href.substring(domNode.attribs.href.indexOf('sites/PGATOURLINKS/SiteAssets' + 1));
    const text = findText(domNode);
    return (
      <a href={`/${split}`} target="_blank" rel="noreferrer">
        {text}
      </a>
    );
  }
}

function findText(startNode) {
  let currentNode = startNode;
  while (currentNode) {
    if (currentNode.type === 'text') {
      return currentNode.data;
    }
    currentNode = currentNode.children[0];
  }
  return '';
}

export default ReorderPage;
