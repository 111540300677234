import { combineReducers } from 'redux';
import profileReducer from './profileReducer';
import tokenReducer from './tokenReducer';
import commitmentInfoReducer from './commitmentInfoReducer';
import teamFieldReducer from './teamFieldReducer';
import commitmentEventsReducer from './commitmentEventReducer';
import commitmentResponseReducer from './commitmentResponseReducer';
import tournamentInfoPageReducer from './tournamentInfoPageReducer';
import reorderStandingsReducer from './reorderStandingsReducer';

export default combineReducers({
  profile: profileReducer,
  token: tokenReducer,
  commitmentInfo: commitmentInfoReducer,
  teamField: teamFieldReducer,
  commitmentEvents: commitmentEventsReducer,
  postCommitmentResponse: commitmentResponseReducer,
  tournamentPages: tournamentInfoPageReducer,
  reorderStandings: reorderStandingsReducer,
});
