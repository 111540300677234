import { RECEIVE_REORDER_STANDINGS } from '../util/actionTypes';

const reorderStandingsReducer = (state = null, { type, payload, tourName }) => {
  switch (type) {
    case RECEIVE_REORDER_STANDINGS:
      return {
        ...state,
        [tourName]: payload,
      };
    default:
      return state;
  }
};

export default reorderStandingsReducer;
