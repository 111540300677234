import { GENERATE_STATE_AND_NONCE, RECEIVE_ACCESS_TOKEN, RECEIVE_ID_TOKEN, RECEIVE_REFRESH_TOKEN, UPDATE_ACCESS_TOKENS } from '../util/actionTypes';

export const receiveAccessToken = (accessToken) => ({
  type: RECEIVE_ACCESS_TOKEN,
  payload: { accessToken },
});

export const receiveIdToken = (idToken) => ({
  type: RECEIVE_ID_TOKEN,
  payload: { idToken },
});

export const receiveRefreshToken = (refreshToken) => ({
  type: RECEIVE_REFRESH_TOKEN,
  payload: { refreshToken },
});

export const generateStateAndNonce = () => ({
  type: GENERATE_STATE_AND_NONCE,
});

export const updateAccessTokens = (accessToken, refreshToken, idToken) => ({
  type: UPDATE_ACCESS_TOKENS,
  payload: { accessToken, refreshToken, idToken },
});
