import { PGA_TOUR, CHAMPIONS_TOUR, KORN_FERRY_TOUR, PGA_TOUR_AMERICAS } from './titles';

export const CODE_LOOKUP = {
  R: {
    tournament: PGA_TOUR,
    tournamentName: 'pgaTour',
    className: 'pga',
    order: 1,
  },
  S: {
    tournament: CHAMPIONS_TOUR,
    tournamentName: 'championsTour',
    className: 'champion',
    order: 2,
  },
  H: {
    tournament: KORN_FERRY_TOUR,
    tournamentName: 'kornFerryTour',
    className: 'kornferry',
    order: 3,
  },
  Y: {
    tournament: PGA_TOUR_AMERICAS,
    tournamentName: 'pgaTourAmericas',
    className: 'americas',
    order: 4,
  },
};

export const getTabMap = (tourCodes) => {
  return tourCodes
    .filter((code) => !!CODE_LOOKUP[code])
    .map((tourCode) => {
      const { tournament, tournamentName, className } = CODE_LOOKUP[tourCode];
      return tournament && tournamentName && className ? { tourCode, tournament, tournamentName, className } : null;
    })
    .sort((a, b) => (CODE_LOOKUP[a.tourCode].order > CODE_LOOKUP[b.tourCode].order ? 1 : -1));
};
