import React from 'react'; // { useState, useEffect } from "react";
import styles from './Dashboard.module.css';
import WelcomeHeader from '../../Common/Headers/WelcomeHeader';
import { useSelector } from 'react-redux';
import Announcements from './Components/Announcements';
import Report from './Components/Report';
import UpcomingEvents from './Components/UpcomingEvents';
import PaceOfPlay from './Components/PaceOfPlay';
import EarningsStatements from '../EarningsStatements';
import { useLocation } from 'react-router-dom';

function PlayerLayout({ globalStyles, directToYearEndStatement }) {
  const profile = useSelector((state) => state.profile);
  const location = useLocation();

  if (directToYearEndStatement) {
    return (
      <div className={styles.MainContainer}>
        <EarningsStatements globalStyles={globalStyles} reportType="ye-statements" />
      </div>
    );
  } else {
    return (
      <div className={styles.MainContainer}>
        <WelcomeHeader
          globalStyles={globalStyles}
          title={`Welcome ${profile.firstName}`}
          tagline="all the tour information you need at a glance."
          bannerImage="dashboard"
        />
        <div style={{ height: '40px' }} />
        <UpcomingEvents globalStyles={globalStyles} clearCache={location?.state?.clearCachedEvents} />
        <Announcements globalStyles={globalStyles} />
        <Report globalStyles={globalStyles} />
        <PaceOfPlay globalStyles={globalStyles} />
      </div>
    );
  }
}

export default PlayerLayout;
