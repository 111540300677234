import React from 'react';
import CommitmentApi from '../../api/commitmentApi';
import styles from './Contact.module.css';
import { connect } from 'react-redux';
import StyledButton from '../Common/Buttons/StyledButton';
import Loader from '../Common/Loader/Loader';

class SettingsPage extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      phoneDeleteIds: [],
      phoneListErrors: [],
      emailListErrors: [],
      emailDeleteIds: [],
    };

    this.handlePhonePrimaryChange = this.handlePhonePrimaryChange.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchMessagingData();
  }

  fetchMessagingData() {
    CommitmentApi.getPlayerMobilePreferences(this.props.profile.id, this.props.idToken).then((result) => {
      CommitmentApi.getMobileCarriers(this.props.idToken).then((res) => {
        if (this._isMounted) {
          this.setState({
            isLoading: false,
            emailList: result.data.emailMessagingList,
            phoneList: result.data.phoneMessagingList,
            carriers: res.data.carrierList,
          });
        }
      });
    });
  }

  render() {
    const { phoneList, emailList, carriers } = this.state;
    if (this.state.isLoading) {
      return <Loader withWrapper />;
    } else {
      const carriersOptions = carriers.map((item, i) => {
        return (
          <option value={item.id} key={i}>
            {item.description}
          </option>
        );
      });

      let phoneError = false;
      const phoneItems = phoneList.map((item, i) => {
        if (this.state.phoneListErrors[i] !== null && this.state.phoneListErrors[i] !== undefined) {
          phoneError = true;
        }
        var descriptionValue;
        if (!item.description) {
          descriptionValue = '';
        } else {
          descriptionValue = item.description;
        }
        return (
          <tr className={styles.FormRow} key={i}>
            <td>
              <input data-i={i} value={descriptionValue} onChange={this.handlePhoneDescriptionChange} placeholder="No Description..." />
            </td>
            <td>
              <select data-i={i} name="carrierId" value={item.carrierId} onChange={this.handleCarrierChange}>
                {carriersOptions}
              </select>
            </td>
            <td>
              {this.state.phoneListErrors[i] === null || this.state.phoneListErrors[i] === undefined ? (
                <input data-i={i} value={item.phoneNumber} onChange={this.handlePhoneNumberChange} />
              ) : (
                <input data-i={i} className={styles.Invalid} value={item.phoneNumber} onChange={this.handlePhoneNumberChange} />
              )}
            </td>
            <td>
              <input data-i={i} type="radio" checked={item.primary} onChange={this.handlePhonePrimaryChange} />
            </td>
            <td>
              <input data-i={i} type="checkbox" checked={item.earningsFinishPosition} onChange={this.handlePhoneEarningsChange} />
            </td>
            <td>
              <input data-i={i} type="checkbox" checked={item.groupingStartingTimes} onChange={this.handlePhoneGroupingsChange} />
            </td>
            <td>
              <input data-i={i} type="checkbox" checked={item.suspendResumePlay} onChange={this.handlePhoneSuspendChange} />
            </td>
            <td>
              <button disabled={item.primary} className={styles.DeleteRowButton} onClick={this.deletePhoneNumber}>
                <span className={styles.TooltipText}>Cannot delete primary number</span>
                <i data-i={i} className="fas fa-times-circle" />
              </button>
            </td>
          </tr>
        );
      });
      var emailError = false;
      const emailItems = emailList.map((item, i) => {
        if (this.state.emailListErrors[i] !== null && this.state.emailListErrors[i] !== undefined) {
          emailError = true;
        }
        var descriptionValue;
        if (!item.description) {
          descriptionValue = '';
        } else {
          descriptionValue = item.description;
        }
        return (
          <tr className={styles.FormRow} key={i} id={item.id}>
            <td>
              <input value={descriptionValue} data-i={i} onChange={this.handleEmailDescriptionChange} placeholder="No Description..." />
            </td>
            <td>
              {this.state.emailListErrors[i] === null || this.state.emailListErrors[i] === undefined ? (
                <input value={item.emailAddress} data-i={i} onChange={this.handleEmailAddressChange} placeholder="Email Address" />
              ) : (
                <input
                  value={item.emailAddress}
                  className={styles.Invalid}
                  data-i={i}
                  onChange={this.handleEmailAddressChange}
                  placeholder="Email Address"
                />
              )}
            </td>
            <td>
              <input type="checkbox" data-i={i} checked={item.earningsFinishPosition} onChange={this.handleEmailEarningsChange} />
            </td>
            <td>
              <input type="checkbox" data-i={i} checked={item.groupingStartingTimes} onChange={this.handleEmailGroupingsChange} />
            </td>
            <td>
              <input type="checkbox" data-i={i} checked={item.suspendResumePlay} onChange={this.handleEmailSuspendChange} />
            </td>
            <td>
              <i data-i={i} className="fas fa-times-circle" style={{ color: 'red', fontSize: '18px' }} onClick={this.deleteEmailAddress}></i>
            </td>
          </tr>
        );
      });

      var formIsValid;
      if (phoneError || emailError) {
        formIsValid = false;
      } else {
        formIsValid = true;
      }
      return (
        <div className={styles.PageContainer}>
          <div
            style={{
              padding: '1rem',
              minHeight: this.props.globalStyles.isTabletSize
                ? this.props.globalStyles.windowHeight + 140
                : this.props.globalStyles.windowHeight + 100,
            }}
          >
            <h1 className={styles.FormHeader}>Messaging Preferences</h1>

            <h2>Email Addresses</h2>
            {emailError ? <p style={{ color: 'red' }}>Please enter a valid email address.</p> : ''}
            <table
              style={{
                textAlign: 'center',
              }}
            >
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Email Address</th>
                  <th>Earnings Finish Position</th>
                  <th>Grouping Starting Times</th>
                  <th>Suspend/Resume Play</th>
                  <th>Delete?</th>
                </tr>
              </thead>
              <tbody>
                {emailItems}
                <tr>
                  <td colSpan="6">
                    <button onClick={this.addEmail}>
                      Add Email Address{'  '}
                      <i
                        role="button"
                        className="fas fa-plus-square"
                        style={{
                          color: '#6fbf4a',
                          fontSize: '18px',
                        }}
                      ></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <h2>Phone Numbers</h2>
            {phoneError ? <p style={{ color: 'red' }}>Please enter a valid phone number.</p> : ''}
            <table
              style={{
                textAlign: 'center',
              }}
            >
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Carrier</th>
                  <th>Phone Number</th>
                  <th>Primary?</th>
                  <th>Earnings Finish Position</th>
                  <th>Grouping Starting Times</th>
                  <th> Suspend/Resume Play</th>
                  <th>Delete?</th>
                </tr>
              </thead>
              <tbody>
                {phoneItems}
                <tr>
                  <td colSpan="7">
                    <button onClick={this.addPhoneNumber}>
                      Add Phone Number{'  '}
                      <i
                        className="fas fa-plus-square"
                        style={{
                          color: '#6fbf4a',
                          fontSize: '18px',
                        }}
                      ></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className={styles.ButtonContainer}>
              {formIsValid ? (
                <StyledButton type="secondary" handler={this.handleSubmit} title="Save Changes" />
              ) : (
                <StyledButton type="disabled" title="Save Changes" />
              )}
            </div>
          </div>
        </div>
      );
    }
  }

  handlePhoneNumberChange = (event) => {
    const {
      target: {
        dataset: { i },
      },
    } = event;
    const { phoneList } = this.state;
    const newPhoneNumber = event.target.value;

    if (!newPhoneNumber.match(/^[0-9]{10,14}$/)) {
      if (this._isMounted) {
        this.setState({
          phoneListErrors: {
            ...this.state.phoneListErrors,
            [i]: 'Phone number is invalid',
          },
        });
      }
    } else {
      if (this._isMounted) {
        this.setState({
          phoneListErrors: {
            ...this.state.phoneListErrors,
            [i]: null,
          },
        });
      }
    }

    const newPhoneList = Object.assign(phoneList, {
      ...phoneList,
      [i]: { ...phoneList[i], phoneNumber: event.target.value },
    });
    this.setState({
      phoneList: newPhoneList,
    });
  };

  handleCarrierChange = (event) => {
    const {
      target: {
        dataset: { i },
      },
    } = event;
    const { phoneList } = this.state;
    const newPhoneList = Object.assign(phoneList, {
      ...phoneList,
      [i]: { ...phoneList[i], carrierId: parseInt(event.target.value) },
    });
    this.setState({
      phoneList: newPhoneList,
    });
  };

  handlePhoneDescriptionChange = (event) => {
    const {
      target: {
        dataset: { i },
      },
    } = event;
    const { phoneList } = this.state;
    const newPhoneList = Object.assign(phoneList, {
      ...phoneList,
      [i]: { ...phoneList[i], description: event.target.value },
    });
    this.setState({
      phoneList: newPhoneList,
    });
  };

  handlePhoneEarningsChange = (event) => {
    const {
      target: {
        dataset: { i },
      },
    } = event;
    const { phoneList } = this.state;
    const newPhoneList = Object.assign(phoneList, {
      ...phoneList,
      [i]: { ...phoneList[i], earningsFinishPosition: event.target.checked ? true : false },
    });

    this.setState({
      phoneList: newPhoneList,
    });
  };

  handlePhoneGroupingsChange = (event) => {
    const {
      target: {
        dataset: { i },
      },
    } = event;
    const { phoneList } = this.state;
    const newPhoneList = Object.assign(phoneList, {
      ...phoneList,
      [i]: { ...phoneList[i], groupingStartingTimes: event.target.checked ? true : false },
    });

    this.setState({
      phoneList: newPhoneList,
    });
  };

  handlePhoneSuspendChange = (event) => {
    const {
      target: {
        dataset: { i },
      },
    } = event;
    const { phoneList } = this.state;
    const newPhoneList = Object.assign(phoneList, {
      ...phoneList,
      [i]: { ...phoneList[i], suspendResumePlay: event.target.checked ? true : false },
    });

    this.setState({
      phoneList: newPhoneList,
    });
  };

  handleEmailDescriptionChange = (event) => {
    const {
      target: {
        dataset: { i },
      },
    } = event;
    const { emailList } = this.state;
    const newEmailList = Object.assign(emailList, {
      ...emailList,
      [i]: { ...emailList[i], description: event.target.value },
    });
    this.setState({
      emailList: newEmailList,
    });
  };

  handleEmailAddressChange = (event) => {
    const {
      target: {
        dataset: { i },
      },
    } = event;
    const { emailList } = this.state;
    const newEmailAddress = event.target.value;

    var emailPattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
    );

    if (!newEmailAddress.match(emailPattern)) {
      if (this._isMounted) {
        this.setState({
          emailListErrors: {
            ...this.state.emailListErrors,
            [i]: 'Email Address is invalid',
          },
        });
      }
    } else {
      if (this._isMounted) {
        this.setState({
          emailListErrors: {
            ...this.state.emailListErrors,
            [i]: null,
          },
        });
      }
    }

    const newEmailList = Object.assign(emailList, {
      ...emailList,
      [i]: { ...emailList[i], emailAddress: event.target.value },
    });
    this.setState({
      emailList: newEmailList,
    });
  };

  handleEmailEarningsChange = (event) => {
    const {
      target: {
        dataset: { i },
      },
    } = event;
    const { emailList } = this.state;
    const newEmailList = Object.assign(emailList, {
      ...emailList,
      [i]: { ...emailList[i], earningsFinishPosition: event.target.checked ? true : false },
    });

    this.setState({
      emailList: newEmailList,
    });
  };

  handleEmailGroupingsChange = (event) => {
    const {
      target: {
        dataset: { i },
      },
    } = event;
    const { emailList } = this.state;
    const newEmailList = Object.assign(emailList, {
      ...emailList,
      [i]: { ...emailList[i], groupingStartingTimes: event.target.checked ? true : false },
    });

    this.setState({
      emailList: newEmailList,
    });
  };

  handleEmailSuspendChange = (event) => {
    const {
      target: {
        dataset: { i },
      },
    } = event;
    const { emailList } = this.state;
    const newEmailList = Object.assign(emailList, {
      ...emailList,
      [i]: { ...emailList[i], suspendResumePlay: !!event.target.checked },
    });

    this.setState({
      emailList: newEmailList,
    });
  };

  handlePhonePrimaryChange(event) {
    const i = parseInt(event.target.dataset.i);
    const newPhoneList = this.state.phoneList.slice();
    for (let a = 0; a < newPhoneList.length; a++) {
      newPhoneList[a].primary = a === i;
    }
    this.setState({
      phoneList: newPhoneList,
    });
  }

  handleSubmit = (event) => {
    if (this._isMounted) {
      this.setState({
        isLoading: true,
      });
    }
    event.preventDefault();
    var { phoneList, phoneDeleteIds, emailList, emailDeleteIds } = this.state;

    var promiseArray = [];
    if (phoneDeleteIds.length > 0) {
      var phoneDeletes = phoneDeleteIds.map((item) => {
        if (item !== undefined) {
          return CommitmentApi.deletePlayerPhoneNumber(item, this.props.idToken);
        }
        return null;
      });
      promiseArray.push.apply(promiseArray, phoneDeletes);
    }

    if (emailDeleteIds.length > 0) {
      var emailDeletes = emailDeleteIds.map((item) => {
        if (item !== undefined) {
          return CommitmentApi.deletePlayerEmailAddress(item, this.props.idToken);
        }
        return null;
      });
      promiseArray.push.apply(promiseArray, emailDeletes);
    }

    if (phoneList.length > 0) {
      var phoneAddList = phoneList.map((item, i) => {
        if (item.id !== undefined) {
          var updatePhonePostData = {
            id: item.id,
            playerId: this.props.profile.id,
            description: item.description,
            phoneNumber: item.phoneNumber,
            carrierId: item.carrierId,
            earningsFinishPosition: item.earningsFinishPosition,
            groupingStartingTimes: item.groupingStartingTimes,
            suspendResumePlay: item.suspendResumePlay,
            primary: item.primary,
          };
          return CommitmentApi.updatePlayerPhoneNumber(updatePhonePostData, this.props.idToken);
        } else {
          const addPhonePostData = {
            playerId: this.props.profile.id,
            description: item.description,
            phoneNumber: item.phoneNumber,
            carrierId: item.carrierId,
            earningsFinishPosition: item.earningsFinishPosition,
            groupingStartingTimes: item.groupingStartingTimes,
            suspendResumePlay: item.suspendResumePlay,
            primary: item.primary,
          };
          return CommitmentApi.addPlayerPhoneNumber(addPhonePostData, this.props.idToken);
        }
      });
      promiseArray.push.apply(promiseArray, phoneAddList);
    }

    if (emailList.length > 0) {
      var emailAddList = emailList.map((item, i) => {
        if (item.id !== undefined) {
          const updateEmailPostData = {
            id: item.id,
            playerId: this.props.profile.id,
            description: item.description,
            emailAddress: item.emailAddress,
            earningsFinishPosition: item.earningsFinishPosition,
            groupingStartingTimes: item.groupingStartingTimes,
            suspendResumePlay: item.suspendResumePlay,
          };
          return CommitmentApi.updatePlayerEmailAddress(updateEmailPostData, this.props.idToken);
        } else {
          const addEmailPostData = {
            playerId: this.props.profile.id,
            description: item.description,
            emailAddress: item.emailAddress,
            earningsFinishPosition: item.earningsFinishPosition,
            groupingStartingTimes: item.groupingStartingTimes,
            suspendResumePlay: item.suspendResumePlay,
          };
          return CommitmentApi.addPlayerEmailAddress(addEmailPostData, this.props.idToken);
        }
      });
      promiseArray.push.apply(promiseArray, emailAddList);
    }

    Promise.all(promiseArray).then(() => {
      this.setState({
        phoneDeleteIds: [],
        phoneListErrors: [],
        emailListErrors: [],
        emailDeleteIds: [],
      });
      this.fetchMessagingData();
    });
  };

  addEmail = (event) => {
    event.preventDefault();
    if (this._isMounted) {
      const { emailList } = this.state;
      const newEmailList = Object.assign(emailList, {
        ...emailList,
        [emailList.length]: {
          description: '',
          emailAddress: '',
          earningsFinishPosition: false,
          groupingStartingTimes: false,
          suspendResumePlay: false,
        },
      });

      this.setState({
        emailList: newEmailList,
      });
    }
  };

  addPhoneNumber = (event) => {
    event.preventDefault();
    if (this._isMounted) {
      const { phoneList } = this.state;
      const newPhoneList = Object.assign(phoneList, {
        ...phoneList,
        [phoneList.length]: {
          description: '',
          carrierId: 1,
          phoneNumber: '',
          primary: false,
          earningsFinishPosition: false,
          groupingStartingTimes: false,
          suspendResumePlay: false,
        },
      });

      this.setState({
        phoneList: newPhoneList,
      });
    }
  };

  deletePhoneNumber = (event) => {
    const {
      target: {
        dataset: { i },
      },
    } = event;

    const { phoneList } = this.state;
    const { phoneDeleteIds } = this.state;

    const newDeleteIds = Object.assign(phoneDeleteIds, {
      ...phoneDeleteIds,
      [phoneDeleteIds.length]: phoneList[i].id,
    });

    const newPhoneList = phoneList.filter((value) => {
      return value !== phoneList[i];
    });

    this.setState({
      phoneDeleteIds: newDeleteIds,
      phoneList: newPhoneList,
    });
    this.render();
  };

  deleteEmailAddress = (event) => {
    const {
      target: {
        dataset: { i },
      },
    } = event;

    const { emailList } = this.state;
    const { emailDeleteIds } = this.state;

    const newDeleteIds = Object.assign(emailDeleteIds, {
      ...emailDeleteIds,
      [emailDeleteIds.length]: emailList[i].id,
    });

    const newEmailList = emailList.filter((value) => {
      return value !== emailList[i];
    });

    this.setState({
      emailDeleteIds: newDeleteIds,
      emailList: newEmailList,
    });
    this.render();
  };

  componentWillUnmount() {
    this._isMounted = false;
  }
}

const mapStateToProps = (state) => {
  const profile = state.profile;
  const idToken = state.token.id_token;
  return { profile, idToken };
};

export default connect(mapStateToProps)(SettingsPage);
