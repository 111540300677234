import React from 'react';
import styles from './DashboardComponents.module.css';
import CardHeader from '../../../Common/Headers/CardHeader';
import TourApi from '../../../../api/tourApi';
import SharepointApi from '../../../../api/sharepointApi';
import CommitmentApi from '../../../../api/commitmentApi';
import { connect } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import UpcomingEventCard from '../../../Common/Cards/UpcomingEventCard';
import { CODE_LOOKUP, getTabMap } from '../../../../util/tabMap';
import Loader from '../../../Common/Loader/Loader';
import { receiveAllPages } from '../../../../actions/tournamentActions';
import { receiveCommitmentInfo } from '../../../../actions/commitmentActions';
import { getPlayerId } from '../../../../util/generalUtils';

const CACHE = {
  upcomingEvents: {},
};

class UpcomingEvents extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      sharepointLoading: true,
      upcomingEvents: null,
      tournamentIds: null,
      isTabLoading: false,
      commitmentStatus: {
        pgaTour: null,
        championsTour: null,
        kornFerryTour: null,
        pgaTourAmericas: null,
      },
      tournamentDetails: {
        pgaTour: [],
        championsTour: [],
        kornFerryTour: [],
        pgaTourAmericas: [],
      },
      showTournaments: {
        pgaTour: false,
        championsTour: false,
        kornFerryTour: false,
        pgaTourAmericas: false,
      },
      tabMap: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.clearCache) {
      CACHE.upcomingEvents = {};
    }
    if (CACHE.upcomingEvents.mount !== undefined) {
      this.setState({
        tabMap: CACHE.upcomingEvents.mount.tabMap,
      });
      this.fetchTournaments();
    } else {
      const tabMap = getTabMap(this.props.profile.tourCodes);
      CACHE.upcomingEvents['mount'] = {
        tabMap: tabMap,
      };
      if (this._isMounted) {
        this.setState({
          tabMap: tabMap,
        });
      }
      CACHE.upcomingEvents['mount'] = {
        ...CACHE.upcomingEvents['mount'],
      };

      this.fetchTournaments();
    }
  }

  fetchTournaments() {
    if (CACHE.upcomingEvents.tournaments) {
      if (this._isMounted) {
        this.setState({
          tournamentDetails: CACHE.upcomingEvents.tournaments,
          isTabLoading: false,
          isLoading: false,
        });
      }
    } else {
      var upcomingTournaments = [];
      const firstTourCode = this.props.profile.tourCodes.filter((code) => !!CODE_LOOKUP[code]).find((code) => code && !['E', 'O'].includes(code));

      const tournamentRequestArray = [
        TourApi.getTournamentSchedule(firstTourCode).then((respo) => {
          if (respo !== undefined && respo[0].schedule !== null && respo[0].schedule !== undefined) {
            var tournamentIds = respo[0].schedule.map((item) => {
              return {
                tournamentId: item.t_id,
                scheduleId: item.schedule_id,
                startDate: new Date(item.start_date),
                endDate: new Date(item.end_date),
                hasBeenPlayed: item.has_been_played,
                code: item.code !== undefined ? item.code : null,
                proAm1: item.proam1,
                proAm2: item.proam2,
              };
            });
            upcomingTournaments.push.apply(upcomingTournaments, tournamentIds);
          } else {
            if (this._isMounted) {
              this.setState({
                isLoading: false,
                isTabLoading: false,
              });
            }
          }
        }),
        ...this.fetchAllCommitmentsData(),
      ];

      Promise.all(tournamentRequestArray).then((response) => {
        const nextTournaments = upcomingTournaments.filter((item) => {
          const endDate = new Date(item.endDate);
          const todaysDate = new Date();
          const nextWeek = new Date(todaysDate.getTime() + 13 * 24 * 60 * 60 * 1000);
          if (endDate < nextWeek && item.hasBeenPlayed === '0') {
            return item;
          }
          return null;
        });
        const sortedTournaments = nextTournaments.sort((a, b) => a.endDate - b.endDate);
        var sortedTournamentIds = [];
        sortedTournaments.forEach((tournamentItem, i) => {
          sortedTournaments[i] = { tournament: sortedTournaments[i] };
          sortedTournamentIds[i] = tournamentItem.tournamentId;
        });
        this.fetchTournamentDetails(sortedTournamentIds, sortedTournaments, firstTourCode);
      });
    }

    if (CACHE.upcomingEvents.sharepoint !== undefined) {
      if (this._isMounted) {
        this.setState({
          sharepointPages: CACHE.upcomingEvents.sharepoint,
          sharepointLoading: false,
        });
      }
    } else {
      const sharepointPages = [];
      SharepointApi.getAllPages()
        .then((response) => {
          // console.log(response.map((item) => ({ name: item.name, id: item.id })));
          this.props.receiveTournamentPages(response);
          sharepointPages.push.apply(sharepointPages, response);
          CACHE.upcomingEvents.sharepoint = sharepointPages;
          if (this._isMounted) {
            this.setState({
              sharepointPages: sharepointPages,
              sharepointLoading: false,
            });
          } else {
            CACHE.upcomingEvents.sharepoint = sharepointPages;
          }
        })
        .catch((err) => {
          if (this._isMounted) {
            this.setState({
              sharepointPages: null,
              sharepointLoading: false,
            });
          }
        });
    }
  }

  fetchTournamentDetails(tournamentIds, tournaments = undefined, tourCode = undefined) {
    let tournamentResults = [];
    const resolvedPromisesArray = tournamentIds.map((id, i) => {
      return TourApi.getTournamentDetails(id).then((result) => {
        if (result !== undefined) {
          tournamentResults[i] = {
            ...result[0],
            scheduleId: tournaments[i].tournament.scheduleId,
            code: tournaments[i].tournament.code,
            proAm1: tournaments[i].tournament.proAm1,
            proAm2: tournaments[i].tournament.proAm2,
          };
        } else {
          tournamentResults.splice(i);
        }
      });
    });

    Promise.all(resolvedPromisesArray).then((response) => {
      if (this._isMounted) {
        if (tourCode !== undefined) {
          const { tournamentDetails } = this.state;
          var newTournamentDetails;
          switch (tourCode) {
            case 'R':
              newTournamentDetails = {
                ...tournamentDetails,
                pgaTour: tournamentResults,
              };
              break;
            case 'S':
              newTournamentDetails = {
                ...tournamentDetails,
                championsTour: tournamentResults,
              };
              break;
            case 'H':
              newTournamentDetails = {
                ...tournamentDetails,
                kornFerryTour: tournamentResults,
              };
              break;
            case 'Y':
              newTournamentDetails = {
                ...tournamentDetails,
                pgaTourAmericas: tournamentResults,
              };
              break;
            default:
              newTournamentDetails = {
                ...tournamentDetails,
              };
              break;
          }
        }
        CACHE.upcomingEvents.tournaments = newTournamentDetails;
        this.setState({
          tournamentDetails: newTournamentDetails,
          isTabLoading: false,
          isLoading: false,
        });
      }
    });
  }

  render() {
    const { tabMap, tournamentDetails, isLoading, sharepointPages, showTournaments, isTabLoading } = this.state;

    if (isLoading || sharepointPages === undefined) {
      return (
        <div className={styles.SectionContainer}>
          <CardHeader title="Upcoming Events" icon="golf-ball" globalStyles={this.props.globalStyles} />
          <Loader withWrapper wrapperStyle={{ textAlign: 'center' }} />
        </div>
      );
    } else {
      if (this.props.globalStyles.isMobileSize || this.props.globalStyles.isTabletSize) {
        const tabs = tabMap.map((item, i) => {
          var tournaments;
          if (tournamentDetails[item.tournamentName].length <= 0) {
            if (isTabLoading) {
              tournaments = (
                <div style={{ textAlign: 'center' }}>
                  <Loader height={40} width={40} />
                </div>
              );
            } else {
              tournaments = <div className={styles.NoData}>No upcoming tournaments.</div>;
            }
          } else {
            tournaments = tournamentDetails[item.tournamentName].map((event, i) => {
              let contactInformationId;
              var eventItem = event;
              var id = eventItem.id + '';
              while (id.length < 3) {
                id = '0' + id;
              }
              const tourCodeLetter = eventItem.tournament_id.charAt(0);
              const tournamentInfoPageName = 'TI-' + tourCodeLetter + id + '.aspx';
              const contactPageName = 'CI-' + tourCodeLetter + id + '.aspx';
              if (sharepointPages !== undefined) {
                sharepointPages.forEach((page) => {
                  if (page.name === contactPageName) {
                    contactInformationId = 'pages/' + page.id;
                  }
                });
              }

              if (this.props.profile.type === 'player') {
                return (
                  <UpcomingEventCard
                    key={i}
                    type="player"
                    tournament={eventItem}
                    contactInfo={contactInformationId}
                    tournamentInfo={tournamentInfoPageName}
                    globalStyles={this.props.globalStyles}
                  />
                );
              } else {
                return (
                  <UpcomingEventCard
                    key={i}
                    type="agent"
                    tournament={eventItem}
                    contactInfo={contactInformationId}
                    tournamentInfo={tournamentInfoPageName}
                    globalStyles={this.props.globalStyles}
                  />
                );
              }
            });
          }

          return (
            <div key={i} style={{ width: '100%' }}>
              <div
                onClick={() => this.expandOptions(item.tournamentName)}
                className={
                  showTournaments[item.tournamentName]
                    ? [styles.AnnouncementMenuParent, styles.AnnouncementMenuParent_Active].join(' ')
                    : styles.AnnouncementMenuParent
                }
              >
                {item.tournament}
                {showTournaments[item.tournamentName] ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down"></i>}
              </div>
              {showTournaments[item.tournamentName] ? tournaments : ''}
            </div>
          );
        });

        return (
          <div className={styles.SectionContainer}>
            <CardHeader title="Upcoming Events" icon="golf-ball" globalStyles={this.props.globalStyles} />
            <div className={styles.MobileSectionMenu}>{tabs}</div>
          </div>
        );
      } else {
        const tabs = tabMap.map((item, i) => {
          return (
            <Tab key={i}>
              <div className={item.className}>{item.tournament}</div>
            </Tab>
          );
        });

        const tabPanels = tabMap.map((item, i) => {
          var tournaments;
          if (tournamentDetails[item.tournamentName].length <= 0) {
            if (isTabLoading) {
              tournaments = (
                <div style={{ textAlign: 'center' }}>
                  <Loader height={40} width={40} />
                </div>
              );
            } else {
              tournaments = <div className={styles.NoData}>No upcoming tournaments.</div>;
            }
          } else {
            tournaments = tournamentDetails[item.tournamentName].map((event, i) => {
              const eventItem = event;
              let id = eventItem.id + '';
              while (id.length < 3) {
                id = '0' + id;
              }
              const tourCodeLetter = eventItem.tournament_id.charAt(0);
              const tournamentInfoPageName = 'TI-' + tourCodeLetter + id + '.aspx';
              const contactPageName = 'CI-' + tourCodeLetter + id + '.aspx';
              if (sharepointPages !== undefined) {
                var contactInformationId;
                const { sharepointPages } = this.state;
                sharepointPages.forEach((page) => {
                  if (page.name === contactPageName) {
                    contactInformationId = 'pages/' + page.id;
                  }
                });
              }

              if (this.props.profile.type === 'player') {
                return (
                  <UpcomingEventCard
                    key={i}
                    type="player"
                    tournament={eventItem}
                    contactInfo={contactInformationId}
                    tournamentInfo={tournamentInfoPageName}
                    globalStyles={this.props.globalStyles}
                  />
                );
              } else {
                return (
                  <UpcomingEventCard
                    key={i}
                    type="agent"
                    tournament={eventItem}
                    contactInfo={contactInformationId}
                    tournamentInfo={tournamentInfoPageName}
                    globalStyles={this.props.globalStyles}
                  />
                );
              }
            });
          }
          return (
            <TabPanel key={i}>
              <div className={styles.EventContainer}>{tournaments}</div>
            </TabPanel>
          );
        });

        return (
          <div className={styles.SectionContainer}>
            <CardHeader title="Upcoming Events" icon="golf-ball" globalStyles={this.props.globalStyles} />
            <Tabs onSelect={this.handleTabChange}>
              <TabList>{tabs}</TabList>
              {tabPanels}
            </Tabs>
          </div>
        );
      }
    }
  }

  expandOptions(type) {
    const { showTournaments, tabMap } = this.state;

    const falseShowTournaments = {
      allTours: false,
      pgaTour: false,
      championsTour: false,
      kornFerryTour: false,
      americasTour: false,
    };

    if (this._isMounted) {
      var newShowTournaments;

      switch (type) {
        case 'allTours':
          newShowTournaments = {
            ...falseShowTournaments,
            allTours: !showTournaments.allTours,
          };
          break;
        case 'pgaTour':
          newShowTournaments = {
            ...falseShowTournaments,
            pgaTour: !showTournaments.pgaTour,
          };
          break;
        case 'championsTour':
          newShowTournaments = {
            ...falseShowTournaments,
            championsTour: !showTournaments.championsTour,
          };
          break;
        case 'kornFerryTour':
          newShowTournaments = {
            ...falseShowTournaments,
            kornFerryTour: !showTournaments.kornFerryTour,
          };
          break;
        case 'pgaTourAmericas':
          newShowTournaments = {
            ...falseShowTournaments,
            pgaTourAmericas: !showTournaments.pgaTourAmericas,
          };
          break;
        default:
          newShowTournaments = {
            allTours: false,
            pgaTour: false,
            championsTour: false,
            kornFerryTour: false,
            pgaTourAmericas: false,
          };
          break;
      }

      tabMap.forEach((tab, i) => {
        if (tab.tournamentName === type) {
          this.handleTabChange(i);
        }
      });

      this.setState({
        isTabLoading: true,
        showTournaments: newShowTournaments,
      });
    }
  }

  fetchAllCommitmentsData() {
    return this.props.profile.tourCodes
      .filter((code) => !!getPlayerId(code, this.props))
      .filter((code) => !!CODE_LOOKUP[code])
      .map((code) => {
        return CommitmentApi.getPlayerCommitmentWorksheet(getPlayerId(code, this.props), code, this.props.idToken).then((result) => {
          this.props.receiveCommitmentInfo(result);
          const { commitmentStatus } = this.state;
          commitmentStatus[CODE_LOOKUP[code].tournamentName] = result.data.playerCommitmentTournamentList;
          this.setState({ commitmentStatus });
        });
      });
  }

  handleTabChange = (index, lastIndex = undefined) => {
    if (index !== lastIndex) {
      const upcomingTournaments = [];
      const { tabMap, tournamentDetails } = this.state;
      const tourCode = tabMap[index].tourCode;
      let tour, details;
      switch (tourCode) {
        case 'R':
          tour = 'pga';
          details = 'pgaTour';
          break;
        case 'S':
          tour = 'champions';
          details = 'championsTour';
          break;
        case 'H':
          tour = 'korn ferry';
          details = 'kornFerryTour';
          break;
        case 'Y':
          tour = 'pgaTourAmericas';
          details = 'pgaTourAmericas';
          break;
        default:
          tour = '';
          break;
      }

      if (tournamentDetails[details].length <= 0) {
        if (this._isMounted) {
          this.setState({
            isTabLoading: true,
          });
        }
        TourApi.getFullTournamentSchedule(tourCode).then((respo) => {
          if (respo !== undefined && respo[0].schedule !== null && respo[0].schedule !== undefined) {
            var tournamentIds = respo[0].schedule.map((item) => {
              return {
                isLoading: false,
                tournamentId: item.t_id,
                scheduleId: item.schedule_id,
                startDate: new Date(item.start_date),
                endDate: new Date(item.end_date),
                hasBeenPlayed: item.has_been_played,
                tour: tour,
                code: item.code !== undefined ? item.code : null,
                proAm1: item.proam1,
                proAm2: item.proam2,
              };
            });

            upcomingTournaments.push.apply(upcomingTournaments, tournamentIds);

            const nextTournaments = upcomingTournaments.filter((item) => {
              const endDate = new Date(item.endDate);
              const todaysDate = new Date();
              const nextWeek = new Date(todaysDate.getTime() + 13 * 24 * 60 * 60 * 1000);
              if (endDate < nextWeek && item.hasBeenPlayed === '0') {
                return item;
              }
              return null;
            });
            const sortedTournaments = nextTournaments.sort((a, b) => a.endDate - b.endDate);
            const sortedTournamentIds = [];
            sortedTournaments.forEach((tournamentItem, i) => {
              sortedTournaments[i] = { tournament: sortedTournaments[i] };
              sortedTournamentIds[i] = tournamentItem.tournamentId;
            });
            this.fetchTournamentDetails(sortedTournamentIds, sortedTournaments, tourCode);
          } else {
            if (this._isMounted) {
              this.setState({
                isLoading: false,
                isTabLoading: false,
              });
            }
          }
        });
      }
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }
}

const mapStateToProps = (state) => {
  const profile = state.profile;
  const idToken = state.token.id_token;
  const commitmentInfo = state.commitmentInfo;
  return { profile, idToken, commitmentInfo };
};

const mapDispatchToProps = (dispatch) => ({
  receiveTournamentPages: (resp) => dispatch(receiveAllPages(resp)),
  receiveCommitmentInfo: (resp) => dispatch(receiveCommitmentInfo(resp.data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingEvents);
