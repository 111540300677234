import { getDataFromField } from './generalUtils';
import styles from '../components/Pages/Commitments/CommitmentWorkSheet.module.css';
import { Link } from 'react-router-dom';
import teamStyles from '../components/Pages/Commitments/TeamCommitment.module.css';
import React from 'react';
import { formatTournamentDateRange } from './date';
import { encodePlayer } from './titles';

function isCommitted(commitmentCode) {
  return commitmentCode && !['%', 'W', 'X', 'D'].includes(commitmentCode);
}

function getPositionInFieldString(item) {
  if (!item.fieldApproved || item.fieldCalculating || !item.commitmentCode) {
    return '';
  } else if (['W', 'X'].includes(item.commitmentCode)) {
    return 'W/D';
  } else {
    return `${item.positionInField || ''}/${item.fieldSizeScheduled}`;
  }
}

function getCommitmentControl(item, isWithdrawPage, onChange, profile, playerId) {
  const isAgent = profile.type === 'agent';
  const playersCanCommit = item.playersCanCommit && ['O', 'P'].includes(item.tournamentStatusCode);
  const showCheckCommit = playersCanCommit && item.canCommit && !isWithdrawPage && !item.officialTeamFormat;
  const showTeamCommit = item.officialTeamFormat && (isCommitted(item.commitmentCode) || (item.canCommit && playersCanCommit));
  const showCheckWithdraw = isWithdrawPage && isCommitted(item.commitmentCode) && playersCanCommit && item.withdrawEnabled;

  if (!isWithdrawPage && isCommitted(item.commitmentCode) && !item.officialTeamFormat) {
    return (
      <div>
        <i className="fas fa-check-square" style={{ color: '#9AA5B3' }} />
        {item.duallyCommittedTournaments.length > 0 && (
          <i className={['fas fa-question-circle', styles.Tooltip].join(' ')} style={{ color: '#e8222b', margin: '0 0.25rem' }}>
            <span className={styles.TooltipText}>{item.duallyCommittedTournaments[0].name}</span>
          </i>
        )}
        {item.satisfiesStrengthOfFieldRequirement && <i className="fas fa-info-circle" style={{ color: '#167F06' }} />}
      </div>
    );
  } else if (showCheckCommit || showCheckWithdraw) {
    return (
      <div>
        <input type="checkbox" name={item.tournamentScheduleId} key={item.tournamentScheduleId} onChange={onChange} />{' '}
        {item.satisfiesStrengthOfFieldRequirement && <i className="fas fa-info-circle" style={{ color: '#167F06' }} />}
      </div>
    );
  } else if (showTeamCommit && item.teamEventLink) {
    const path = { commit: 'create/commit', view: 'view', 'add partner': 'create/select' };
    const { playerTeamConfirmed } = getDataFromField(item.tournamentScheduleId, profile.id);
    const teamPath = `/worksheet/team/${path[item.teamEventLink.toLowerCase()]}/${item.tournamentScheduleId}`;
    const queryParams = `?tour=${item.tourCode}${isAgent ? `&player=${encodePlayer(playerId)}` : ''}`;

    return (
      <>
        <Link className={styles.ButtonLink} to={`${teamPath}${queryParams}`}>
          {item.teamEventLink}
        </Link>
        <span className={teamStyles.ConfirmedIcon}>{playerTeamConfirmed && <i className="fas fa-check-circle" />}</span>
      </>
    );
  } else {
    return null;
  }
}

function getCommitmentData(commitmentTournamentList, isWithdrawPage, onCommitmentChange, profile, tourCode, playerId) {
  return commitmentTournamentList.map((item, i) => {
    const commitmentControl = getCommitmentControl(item, isWithdrawPage, onCommitmentChange, profile, playerId);

    const showCommitmentNumber = isCommitted(item.commitmentCode);
    const showFieldLink = !item.fieldCalculating && item.fieldApproved && item.eligibleForCommitments;
    const showProAm = doShowProAmFieldLink(item);

    const positionInFieldString = getPositionInFieldString(item);
    const fieldPath = getTournamentFieldPath(item.tournamentScheduleId, item.officialTeamFormat, tourCode);

    return (
      <tr key={i}>
        <td>{commitmentControl}</td>
        <td>{formatTournamentDateRange(item)}</td>
        <td>
          {showFieldLink ? (
            <Link to={fieldPath}>
              {item.year} - {item.tournamentScheduleName}
            </Link>
          ) : (
            `${item.year} - ${item.tournamentScheduleName}`
          )}
        </td>
        <td>{showCommitmentNumber ? item.commitmentNumber : ''}</td>
        <td>{positionInFieldString}</td>
        {item.tourCode === 'R' || item.tourCode === 'H' ? (
          <td>{showProAm ? <Link to={`/field/${item.tournamentScheduleId}/${item.officialTeamFormat ? 'team-proam' : 'proam'}`}>View</Link> : ''}</td>
        ) : null}
      </tr>
    );
  });
}

function canWithdrawFromTour(tourCode) {
  return tourCode && tourCode !== 'S';
}

function doShowProAmFieldLink(tournamentItem) {
  return (
    (tournamentItem.tourCode === 'R' || tournamentItem.tourCode === 'H') &&
    tournamentItem.fieldApproved &&
    tournamentItem.sponsorValue &&
    tournamentItem.daysFromTournamentStart > 3 &&
    tournamentItem.daysFromTournamentStart < tournamentItem.sponsorValueDeadline
  );
}

const getTournamentFieldPath = (tsId, isTeamEvent, tourCode) => `/field/${tsId}/${isTeamEvent ? 'team-report' : 'report'}?code=${tourCode}`;
const isTeamEvent = (tsId) => tsId.toString() === '172884';

export { getCommitmentData, isCommitted, getPositionInFieldString, canWithdrawFromTour, doShowProAmFieldLink, getTournamentFieldPath, isTeamEvent };
