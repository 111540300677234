export const LOGIN_URL = '/';
export const AGENT_DASHBOARD_URL = '/dashboard/agent';
export const PLAYER_DASHBOARD_URL = '/dashboard/player';
export const ADMIN_DASHBOARD_URL = '/dashboard/admin';
export const FINANCIAL_ADVISOR_DASHBOARD_URL = '/dashboard/financial';
export const COMMITMENT_WS_URL = '/commitments/:playerId';
export const PLAYER_WORKSHEET_URL = '/worksheet';

export const CONTENT_URL = '/content/:tourId/:sectionName/:contentId';
export const REORDER_PAGE_URL = '/reorder/:tourName';
export const REORDER_REPORT_URL = '/reorder-report/:tourName/:exemptionNumber';
export const EARNINGS_STATEMENTS_URL = '/earnings-statements/';

export const HELP_URL = '/help';
export const CONTACT_URL = '/contact';
export const SETTINGS_URL = '/settings';
export const ENDORSEMENTS_URL = '/endorsements';
export const SITEMAP_URL = '/sitemap';

export const TOURNAMENT_INFO_URL = '/tournament/:contentId';

export const FIELD_REPORT_URL = '/field/:tournamentId/:type';
export const TIMINGS_URL = '/timings';
export const TEE_TIMES_URL = '/teetimes/:eventId';
export const PARTICIPATION_REPORT_URL = '/participation';
export const HOLE_LOCATIONS_URL = '/holelocations/:eventId';
export const PLAYER_INFO_URL = '/playerinfo';
export const OWGR_POINTS_URL = '/owgr/:tournamentId';

export const VIEW_TEAM_COMMITMENT_URL = '/worksheet/team/view/:tournamentScheduleId';
export const CREATE_TEAM_COMMITMENT_URL = '/worksheet/team/create/:mode/:tournamentScheduleId';
export const YEAR_END_STATEMENTS_URL = '/ye-statements/statement';

export const VIDEO_PAGE_URL = '/video-player/video';
