import React, { useEffect, useState } from 'react';
import styles from './Contact.module.css';
import { Form, Select } from 'antd';
import { TOUR_NAMES_BY_CODE } from '../../util/titles';
import { useSelector } from 'react-redux';
import SharepointApi from '../../api/sharepointApi';
import Parser from 'html-react-parser';
import Loader from '../Common/Loader/Loader';
import { CODE_LOOKUP } from '../../util/tabMap';

export default function ContactPage({ globalStyles }) {
  const { tourCodes } = useSelector((state) => state.profile);
  const [loading, setLoading] = useState(true);
  const [selectedTour, setSelectedTour] = useState(() => tourCodes.filter((code) => code !== 'Y')[0]);
  const [contactListByTourCode, setContactListByTourCode] = useState({});

  useEffect(() => {
    setLoading(true);
    async function getContacts() {
      const contacts = await Promise.all([
        ...tourCodes.filter((code) => !!CODE_LOOKUP[code]).map((code) => SharepointApi.getContactInfoForTour(code)),
      ]);
      const contactMap = {};
      contacts
        .filter((contact) => contact?.error == null)
        .forEach((contact, index) => {
          contactMap[tourCodes[index]] = contact?.value[0]?.innerHtml;
        });
      return contactMap;
    }
    getContacts().then((contactMap) => {
      setContactListByTourCode(contactMap);
      setLoading(false);
    });
  }, [tourCodes]);

  if (loading) {
    return <Loader withWrapper />;
  }

  return (
    <div className={styles.PageContainer}>
      <div
        className={styles.FormContainer}
        style={{
          minHeight: globalStyles.isTabletSize ? globalStyles.windowHeight + 140 : globalStyles.windowHeight + 100,
        }}
      >
        <h1 className={styles.FormHeader}>Contact Us</h1>
        <Form name="contact_form" onFinish={() => {}} autoComplete="off">
          <Form.Item value="selectedTour" rules={[{ required: true }]}>
            <h2 className={styles.ContactLabel}>Selected Tour</h2>
            <Select defaultValue={selectedTour} placeholder="Select Tour..." onChange={setSelectedTour}>
              {tourCodes
                .filter((code) => !!CODE_LOOKUP[code])
                .map((code) => (
                  <Select.Option key={code} value={code}>
                    {TOUR_NAMES_BY_CODE[code]}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          {Parser(contactListByTourCode[selectedTour], { replace: (node) => replaceTournamentInfoPageLinks(node) })}
        </Form>
      </div>
    </div>
  );
}

function replaceTournamentInfoPageLinks(domNode) {
  if (domNode.name === 'a' && domNode.attribs.href.includes('sites/PGATOURLINKS')) {
    const split = domNode.attribs.href.substring(domNode.attribs.href.indexOf('sites/PGATOURLINKS/SiteAssets' + 1));
    const text = findText(domNode);
    return (
      <a href={`/${split}`} target="_blank" rel="noreferrer">
        {text}
      </a>
    );
  }
}

function findText(startNode) {
  let currentNode = startNode;
  while (currentNode) {
    if (currentNode.type === 'text') {
      return currentNode.data;
    }
    currentNode = currentNode.children[0];
  }
  return '';
}
