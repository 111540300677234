import React from 'react';
import styles from './Reports.module.css';
import { connect } from 'react-redux';
import NavigationRoles from '../Common/Navigation/NavigationRoles.json';
import { Link } from 'react-router-dom';
import { PGA_TOUR_AMERICAS, CHAMPIONS_TOUR, encodePlayer, KORN_FERRY_TOUR, PGA_TOUR } from '../../util/titles';
import { CODE_LOOKUP } from '../../util/tabMap';

const Sitemap = (props) => {
  const type = props.profile.type;

  const topNavItems = NavigationRoles[type].topNav.map((item, i) => {
    if (type === 'agent') {
      if (item.route === '/commitments') {
        const playerCommitments = props.profile.players.map((player, p) => {
          const encodedString = encodePlayer(player.playerId);

          return (
            <Link tabIndex="0" to={'/commitments/' + encodedString} key={p}>
              {player.playerName}
            </Link>
          );
        });

        return (
          <div key={i}>
            <h2>My Player's Commitments</h2>
            {playerCommitments}
          </div>
        );
      } else {
        return (
          <Link to={item.route} tabIndex="0" key={i}>
            {item.title}
          </Link>
        );
      }
    } else {
      return (
        <Link to={item.route} tabIndex="0" key={i}>
          {item.title}
        </Link>
      );
    }
  });

  const tourSections = ['tourBusiness', 'tournaments', 'regulations', 'resources'];

  let tourCodes;
  if (type === 'agent') {
    tourCodes = ['R', 'S', 'H', 'Y'];
  } else if (type === 'player') {
    tourCodes = props.profile.tourCodes.filter((code) => !!CODE_LOOKUP[code]);
  }

  const tourItems = tourCodes.map((item, i) => {
    if (item === null || item === undefined) {
      return null;
    }
    let tourName;
    let tour;
    switch (item) {
      case 'R':
        tourName = PGA_TOUR;
        tour = 'pga';
        break;
      case 'S':
        tourName = CHAMPIONS_TOUR;
        tour = 'champions';
        break;
      case 'H':
        tourName = KORN_FERRY_TOUR;
        tour = 'kornFerry';
        break;
      case 'Y':
        tourName = PGA_TOUR_AMERICAS;
        tour = 'americas';
        break;
      default:
        break;
    }

    const tourBusinessItems = NavigationRoles[type].tours[tour][tourSections[0]].map((itemA, a) => {
      if (itemA.props.type === 'external') {
        return (
          <a href={itemA.route} tabIndex="0" key={a}>
            {itemA.props.title}
          </a>
        );
      } else {
        return (
          <Link to={itemA.route} tabIndex="0" key={a}>
            {itemA.props.title}
          </Link>
        );
      }
    });
    const tournamentItems = NavigationRoles[type].tours[tour][tourSections[1]].map((itemB, b) => {
      if (itemB.props.type === 'external') {
        return (
          <a href={itemB.route} tabIndex="0" key={b}>
            {itemB.props.title}
          </a>
        );
      } else {
        return (
          <Link to={itemB.route} tabIndex="0" key={b}>
            {itemB.props.title}
          </Link>
        );
      }
    });
    const regulationItems = NavigationRoles[type].tours[tour][tourSections[2]].map((itemC, c) => {
      if (itemC.props.type === 'external') {
        if (itemC.props.preFlight !== undefined) {
          let fullRoute = itemC.route + props.profile.id;
          return (
            <a href={fullRoute} tabIndex="0" key={c}>
              {itemC.props.title}
            </a>
          );
        } else {
          return (
            <a href={itemC.route} tabIndex="0" key={c}>
              {itemC.props.title}
            </a>
          );
        }
      } else {
        return (
          <Link to={itemC.route} tabIndex="0" key={c}>
            {itemC.props.title}
          </Link>
        );
      }
    });
    const resourceItems = NavigationRoles[type].tours[tour][tourSections[3]].map((itemD, d) => {
      if (itemD.props.type === 'external') {
        return (
          <a href={itemD.route} tabIndex="0" key={d}>
            {itemD.props.title}
          </a>
        );
      } else {
        return (
          <Link to={itemD.route} tabIndex="0" key={d}>
            {itemD.props.title}
          </Link>
        );
      }
    });

    return (
      <div className={styles.SectionRow} key={i}>
        <h2>{tourName} Navigation Items</h2>
        <div
          className={styles.NavigationContainer}
          style={{
            flexFlow: props.globalStyles.isMobileSize ? 'column nowrap' : 'row nowrap',
          }}
        >
          <div
            className={styles.NavigationColumn}
            style={{
              width: props.globalStyles.isMobileSize ? '100%' : '25%',
            }}
          >
            <h3>Tour Business</h3>
            {tourBusinessItems}
          </div>
          <div
            className={styles.NavigationColumn}
            style={{
              width: props.globalStyles.isMobileSize ? '100%' : '25%',
            }}
          >
            <h3>Tournaments</h3>
            {tournamentItems}
          </div>
          <div
            className={styles.NavigationColumn}
            style={{
              width: props.globalStyles.isMobileSize ? '100%' : '25%',
            }}
          >
            <h3>Regulations and Policies</h3>
            {regulationItems}
          </div>
          <div
            className={styles.NavigationColumn}
            style={{
              width: props.globalStyles.isMobileSize ? '100%' : '25%',
            }}
          >
            <h3>Resources</h3>
            {resourceItems}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className={styles.SiteMapContainer}>
      <h1>Sitemap</h1>
      <div className={styles.SectionRow} style={{}}>
        <h2>Main Navigation</h2>
        {topNavItems}
      </div>
      {tourItems}
    </div>
  );
};

const mapStateToProps = (state) => {
  const profile = state.profile;
  return { profile };
};

export default connect(mapStateToProps)(Sitemap);
