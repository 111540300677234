import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navigation from './components/Common/Navigation/Navigation';
import ProtectedRoute from './components/ProtectedRoute';
import { updateAccessTokens } from './actions/tokenActions';
import { CLEAR_ALL_COMMITMENTS, CLEAR_ALL_TOURNAMENT_PAGES, CLEAR_TOKENS } from './util/actionTypes';
import * as oidcApi from './api/oidcApi';
import { IdleTimer } from './components/Common/IdleTimer';
import ScrollToTop from './components/Common/Navigation/ScrollToTop';
import { clearSession, getLastClosed, getTime, isNewTab, setActiveSession, setLastClosed } from './util/generalUtils';
import Loader from './components/Common/Loader/Loader';
import Footer from './components/Common/Footer/Footer';
import { OPEN_ROUTES, PROTECTED_ROUTES } from './util/routes';
import 'antd/dist/antd.css';
import HeaderPreAuth from './components/Common/Headers/HeaderPreAuth';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
      showComponents: false,
      refreshInterval: setInterval(this.timer, 1000 * 60 * 9),
      isRefreshingToken: true,
    };

    this.updateDimensions = this.updateDimensions.bind(this);

    this.idleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
  }

  componentDidMount() {
    localStorage.clear();
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);

    const isARefreshedPage = getTime() - getLastClosed() < 10000;
    const isInitialVisitToTheSite = !isNewTab() && !isARefreshedPage;
    if (isInitialVisitToTheSite) {
      this.props.clearTokens();
      this.setState({ isRefreshingToken: false });
    } else if (isARefreshedPage && this.props.token.refreshToken) {
      this.refreshAccessToken();
    } else {
      this.setState({ isRefreshingToken: false });
    }

    window.addEventListener('storage', (event) => {
      if (event.key === 'activeSession' && event.newValue === 'false') {
        setActiveSession();
      }
    });
    setActiveSession();
    window.addEventListener('unload', () => {
      setLastClosed();
      clearSession();
    });
  }

  timer = () => {
    if (this.props.token !== undefined && this.props.loggedIn) {
      this.refreshAccessToken();
    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    clearInterval(this.state.refreshInterval);
  }

  refreshAccessToken() {
    oidcApi
      .refreshAccessToken(this.props.token.refreshToken)
      .then((res) => {
        if (res.data) {
          this.props.updateTokens(res.data.access_token, res.data.refresh_token, res.data.id_token);
        }
        this.setState({ isRefreshingToken: false });
      })
      .catch((error) => {
        this.setState({ isRefreshingToken: false });
      });
  }

  updateDimensions() {
    const { windowWidth } = this.state;

    let currentWidth = 'full';
    if (windowWidth < 768) {
      currentWidth = 'mobile';
    } else if (windowWidth >= 768 && windowWidth < 1024) {
      currentWidth = 'tablet';
    }

    let newWindowWidth = typeof window !== 'undefined' ? window.innerWidth : 0;

    let newWidth = 'full';
    if (newWindowWidth < 768) {
      newWidth = 'mobile';
    } else if (newWindowWidth >= 768 && newWindowWidth < 1024) {
      newWidth = 'tablet';
    }

    if (currentWidth !== newWidth) {
      this.setState({ windowWidth: newWindowWidth });
    }
  }

  handleOnAction(event) {
    this.idleTimer.reset();
  }

  handleOnActive(event) {
    // Action on returning from idle?
  }

  handleOnIdle(event) {
    this.props.logout(this.props.accessToken);
  }

  render() {
    const { windowWidth, isRefreshingToken } = this.state;
    const { profile, loggedIn, logout, accessToken } = this.props;

    const styles = {
      white: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
      black: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
      navigationHeight: '90px',
      isMobileSize: windowWidth < 768,
      isTabletSize: windowWidth < 1024 && windowWidth > 767,
      windowHeight: 700,
    };

    if (styles.isMobileSize || styles.isTabletSize) {
      styles.navigationHeight = '40px';
    }

    const contentArea = {
      position: 'relative',
      top: styles.navigationHeight,
      left: 0,
      width: '100%',
      // minHeight: styles.windowHeight,
      backgroundColor: '#eaeef0',
      paddingBottom: '6rem',
    };

    if (loggedIn && isRefreshingToken) {
      return (
        <Router>
          <Navigation globalStyles={styles} logout={() => logout(accessToken)} />
          <div>
            <Loader withWrapper />
          </div>
        </Router>
      );
    }

    const protectedRoutes = PROTECTED_ROUTES.map((route, i) => {
      const { path: pathName, component: Component, openBeforeLogin, redirect, adminRequired } = route;
      const theComponent = (props) => <Component globalStyles={styles} {...props} />;
      const open = openBeforeLogin ? !loggedIn : adminRequired ? loggedIn && profile?.adminMode?.hasRole : loggedIn;
      const element = <ProtectedRoute component={theComponent} redirect={redirect} open={open} />;
      return <Route path={pathName} element={element} key={i} />;
    });

    const openRoutes = OPEN_ROUTES.map((route) => {
      if (route.isDevelopmentOnly && process.env.NODE_ENV !== 'development') {
        return null;
      }
      const { path: pathName, component: Component, exact, props } = route;
      const theComponent = <Component globalStyles={styles} {...props} />;
      return <Route path={pathName} element={theComponent} exact={exact} key={pathName} />;
    });

    return (
      <>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          timeout={1000 * 60 * 120}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />
        <Router>
          <ScrollToTop />
          {loggedIn ? (
            <>
              <Navigation globalStyles={styles} logout={() => logout(accessToken)} />
              {profile?.adminMode?.active && (
                <div className="admin-mode">
                  <span>
                    Admin Mode {profile.type} - {profile.fullName}
                  </span>
                </div>
              )}
            </>
          ) : (
            <HeaderPreAuth />
          )}

          <div style={contentArea}>
            <Routes>
              {protectedRoutes}
              {openRoutes}
            </Routes>
            <div
              style={{
                position: 'fixed',
                bottom: '0',
                right: '0',
                fontSize: '8px',
                color: '#000',
                zIndex: '1000',
              }}
            >
              v1.1.2
            </div>
          </div>
          {loggedIn && <Footer globalStyles={styles} />}
        </Router>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.profile,
  token: state.token,
  accessToken: state.token.accessToken,
  refreshToken: state.token.refreshToken,
  loggedIn: !!state.token.accessToken,
});

const mapDispatchToProps = (dispatch) => ({
  logout: (accessToken) => {
    dispatch({ type: CLEAR_TOKENS });
    oidcApi.logout({ accessToken });
  },
  clearTokens: () => {
    dispatch({ type: CLEAR_TOKENS });
    dispatch({ type: CLEAR_ALL_COMMITMENTS });
    dispatch({ type: CLEAR_ALL_TOURNAMENT_PAGES });
  },
  updateTokens: (accessToken, refreshToken, idToken) => {
    dispatch(updateAccessTokens(accessToken, refreshToken, idToken));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
