import React from 'react';
import styles from './Contact.module.css';
import { Link } from 'react-router-dom';
import { withParams } from '../../util/routerHooks';

const ErrorPage = (props) => {
  if (props.type === 'content') {
    return (
      <div className={styles.PageContainer}>
        <div
          style={{
            height: '80vh',
            minHeight: props.globalStyles.windowHeight,
            textAlign: 'center',
          }}
        >
          <h1 className={styles.ErrorHeader}>Content Not Found</h1>
          <h2 className={styles.ErrorHeader2}>{props.params.reportType} report is not available at this time.</h2>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.PageContainer}>
        <div
          style={{
            height: '80vh',
            minHeight: props.globalStyles.windowHeight,
            textAlign: 'center',
          }}
        >
          <h1 className={styles.ErrorHeader}>404</h1>
          <h2 className={styles.ErrorHeader2}>The page you are trying to access does not exist. Please check the URL and try again.</h2>
          <Link to="/" tabIndex="0" className={styles.ErrorHomeLink}>
            Go Home
          </Link>
        </div>
      </div>
    );
  }
};

export default withParams(ErrorPage);
