import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as oidcApi from '../../../api/oidcApi';
import { generateStateAndNonce } from '../../../actions/tokenActions';
import { useLocation } from 'react-router-dom';
import Loader from '../../Common/Loader/Loader';

export default function LoginPage() {
  const returnPath = new URLSearchParams(useLocation().search).get('return');
  const redirectTo = returnPath ? `/${returnPath}` : '/dashboard';
  const { state, nonce } = useSelector((state) => state.token);
  const dispatch = useDispatch();

  if (state === null || nonce === null) {
    dispatch(generateStateAndNonce());
  } else {
    oidcApi.beginAuth({ state, nonce, redirectTo });
  }

  return <Loader withWrapper />;
}
