import React, { useState } from 'react';
import styles from './Admin.module.css';
import AdminModeForm from './AdminModeForm';
import Loader from '../Common/Loader/Loader';
import { useDispatch } from 'react-redux';
import { CLEAR_ALL_COMMITMENTS, CLEAR_ALL_TOURNAMENT_PAGES } from '../../util/actionTypes';

export default function AdminMode() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  function clearCommitments() {
    dispatch({ type: CLEAR_ALL_COMMITMENTS });
    dispatch({ type: CLEAR_ALL_TOURNAMENT_PAGES });
  }

  if (isLoading) {
    return <Loader withWrapper />;
  }

  return (
    <div className={styles.AdminContainer}>
      <h1 className={styles.AdminHeader}>Admin Mode</h1>
      <AdminModeForm type="player" clearCommitments={clearCommitments} setIsLoadingPage={setIsLoading} />
      <AdminModeForm type="agent" clearCommitments={clearCommitments} setIsLoadingPage={setIsLoading} />
      <AdminModeForm type="financial" clearCommitments={clearCommitments} setIsLoadingPage={setIsLoading} />
    </div>
  );
}
