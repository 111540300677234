import styles from './CommitmentWorkSheet.module.css';
import ErrorModal from '../../Common/Modals/ErrorModal';
import WelcomeHeader from '../../Common/Headers/WelcomeHeader';
import React from 'react';

export default function CommitmentContainer({ globalStyles, isError, handleModalClick, children }) {
  return (
    <div className={styles.MainContainer}>
      <ErrorModal open={isError} onClose={handleModalClick} />
      <WelcomeHeader
        globalStyles={globalStyles}
        title="My Commitments"
        tagline="your resource for tournament and event commitments."
        bannerImage="commitment"
      />
      {children}
    </div>
  );
}
