import React from 'react';
import styles from './Reports.module.css';
import TourApi from '../../api/tourApi';
import Loader from '../Common/Loader/Loader';
import { withParams } from '../../util/routerHooks';

class OWGRInfo extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { tournamentId } = this.props.params;

    TourApi.getOWGRPoints(tournamentId).then((owgrRes) => {
      if (owgrRes !== undefined) {
        if (this._isMounted) {
          this.setState({
            isLoading: false,
            owgrPoints: owgrRes,
          });
        }
      }
    });
  }

  render() {
    const { isLoading, owgrPoints } = this.state;

    if (isLoading) {
      return <Loader withWrapper />;
    } else if (owgrPoints === undefined) {
      return (
        <div className={styles.PageContainer}>
          <div
            style={{
              padding: '1rem',
              minHeight: this.props.globalStyles.isTabletSize
                ? this.props.globalStyles.windowHeight + 165
                : this.props.globalStyles.windowHeight + 120,
              textAlign: 'center',
            }}
          >
            <h1>WGR Points Available</h1>
            <p>The estimated OWGR points awarded for this event have not been calculated, or this event is not eligible to receive OWGR points.</p>
          </div>
        </div>
      );
    } else {
      const { owgrPoints } = this.state;

      const points = owgrPoints.tpb.map((item, i) => {
        return (
          <tr key={i}>
            <td>{item.place}</td>
            <td>{item.owgr_points}</td>
          </tr>
        );
      });

      return (
        <div className={styles.MainContainer}>
          <div
            className={styles.ReportContainer}
            style={{
              textAlign: 'center',
              minHeight: this.props.globalStyles.isMobileSize
                ? this.props.globalStyles.windowHeight + 140
                : this.props.globalStyles.windowHeight + 120,
            }}
          >
            <h1>WGR Points Available</h1>
            <table>
              <thead>
                <tr>
                  <th>Place</th>
                  <th>Points Available</th>
                </tr>
              </thead>
              <tbody>{points}</tbody>
            </table>
          </div>
        </div>
      );
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
}

export default withParams(OWGRInfo);
