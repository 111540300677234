import { TOUR_AUTH_URL } from '../url/service-url.js';

async function sendRequest(url, options = {}, retries = 3) {
  const token = await fetch(TOUR_AUTH_URL)
    .then((res) => res.json())
    .then((data) => data.TOKEN);
  return fetch(process.env.REACT_APP_TOUR_BASE_URL + url, {
    headers: new Headers({
      Authorization: 'Bearer ' + token,
    }),
    ...options,
  })
    .then((res) => {
      if ([200, 201, 202, 304].includes(res.status)) {
        return res.json();
      } else if (retries > 0) {
        return sendRequest(url, options, retries - 1);
      } else {
        throw new Error(res.status.toString());
      }
    })
    .catch((error) => console.log(`Error in Tour Api: ${url} ${error}`));
}

export default {
  getTournamentSchedule: (tournamentCode) => {
    return sendRequest(`/MemberTournamentSchedule/1.0.0/?format=json&T_CODE=${tournamentCode}&NUM=25`);
  },
  getFullTournamentSchedule: (tournamentCode) => {
    return sendRequest(`/MemberTournamentSchedule/1.0.0/?format=json&T_CODE=${tournamentCode}&NUM=25`);
  },
  getTournamentDetails: (tournamentId) => {
    return sendRequest('/MemberTournamentDetails/1.0.0/?format=json&T_ID=' + tournamentId);
  },
  getTournamentStatus: (tournamentId) => {
    return sendRequest('/MemberTournamentStatus/2.0.0/?format=json&T_ID=' + tournamentId);
  },
  getTournamentTeeTimes: (tournamentId, roundId) => {
    return sendRequest(`/MemberRoundTeeTimes/1.0.0/?format=json&T_ID=${tournamentId}&R_ID=${roundId}`);
  },
  getPlayerInformation: (playerId) => {
    return sendRequest(`/MemberVideoBoardBullets/1.0.0/?format=json&P_ID=${playerId}`);
  },
  getOWGRPoints: (tournamentId) => {
    return sendRequest(`/OWGRPointsAvailable/1.0.0/?format=json&T_ID=${tournamentId}`);
  },
  getReorderStandings: (tourCode, exemptionNumber) => {
    return sendRequest(`/MemberReorderStandings/1.0.0/?format=json&T_CODE=${tourCode}&E_NUM=${exemptionNumber}`);
  },
};
