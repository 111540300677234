import { ENCODED_ID } from '../url/service-url';

export default {
  getEncodedId: (playerId, years, tourCodes) => {
    return fetch(ENCODED_ID, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ playerId, years, tourCodes }),
    })
      .then((resp) => resp.json())
      .catch((error) => {
        console.log(`Error in encoding ids ${error}`);
      });
  },
};
