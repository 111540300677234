import React from 'react';
import styles from './Reports.module.css';
import CommitmentApi from '../../api/commitmentApi';
import { connect } from 'react-redux';
import Loader from '../Common/Loader/Loader';
import { withLocation } from '../../util/routerHooks';

class ParticipationReport extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      playerName: '',
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { profile } = this.props;

    let playerId;
    if (this.props.profile.type === 'agent') {
      playerId = this.props.location.state.id;
      this.setState({ playerName: profile.players.find((p) => p.playerId.toString() === playerId)?.playerName });
    } else if (this.props.profile.type === 'player') {
      playerId = this.props.profile.id;
      this.setState({ playerName: profile.fullName });
    }

    CommitmentApi.getPlayerStrengthOfFieldReport(playerId, this.props.idToken).then((res) => {
      if (this._isMounted) {
        this.setState({
          isLoading: false,
          participationReport: res.data.participationTournaments,
        });
      }
    });
  }

  render() {
    const { isLoading, playerName } = this.state;

    if (isLoading) {
      return <Loader withWrapper />;
    } else {
      const { participationReport } = this.state;

      const tournaments = participationReport.map((item, i) => {
        const yearItems = item.tournamentYearPosition.map((itemA, a) => {
          return (
            <tr key={a}>
              <td>{itemA.finishPosition}</td>
              <td>{itemA.year}</td>
            </tr>
          );
        });

        return (
          <div key={i}>
            <h2>{item.tournamentName}</h2>
            <table>
              <thead>
                <tr>
                  <th>Finish Position</th>
                  <th>Year</th>
                </tr>
              </thead>
              <tbody>{yearItems}</tbody>
            </table>
          </div>
        );
      });

      return (
        <div className={styles.MainContainer}>
          <div className={styles.ReportContainer}>
            <h1>Participation Report - {playerName}</h1>
            {tournaments}
          </div>
        </div>
      );
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
}

const mapStateToProps = (state) => {
  const profile = state.profile;
  const idToken = state.token.id_token;
  return { profile, idToken };
};

export default withLocation(connect(mapStateToProps)(ParticipationReport));
