import React from 'react';
import Modal from './Modal';

export default function ErrorModal({ open, onClose, isWithdraw }) {
  const action = isWithdraw ? 'withdrawal' : 'commitment';
  return (
    <Modal onSubmit={onClose} title="ERROR!" submitText="OK" open={open} opts={{ disableBackdropClick: true }}>
      <p>There was an issue submitting your {action} request. Please try again.</p>
      <p>
        If this problem persists, please contact <a href="mailto:playertechnology@pgatourhq.com">playertechnology@pgatourhq.com</a> or{' '}
        <a href="tel:18887429558">1-888-742-9558</a> for technical assistance.
      </p>
      <p>
        You can also contact the Competitions Department at <a href="tel:+18007422244">1-800-742-2244</a> to process your {action} request.
      </p>
    </Modal>
  );
}
