import { useLocation, useNavigate, useParams } from 'react-router-dom';

function withNavigation(Component) {
  return (props) => <Component {...props} navigate={useNavigate()} />;
}

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

function withLocation(Component) {
  return (props) => <Component {...props} location={useLocation()} />;
}

function withParamsAndLocation(Component) {
  return (props) => <Component {...props} params={useParams()} location={useLocation()} />;
}

function withParamsAndNavigation(Component) {
  return (props) => <Component {...props} params={useParams()} navigate={useNavigate()} />;
}

function withParamsLocationNavigation(Component) {
  return (props) => <Component {...props} params={useParams()} navigate={useNavigate()} location={useLocation()} />;
}

export { withParams, withNavigation, withLocation, withParamsAndLocation, withParamsAndNavigation, withParamsLocationNavigation };
