import React from 'react';
import styles from './MegaMenu.module.css';
import { Link } from 'react-router-dom';
import NavigationRoles from './NavigationRoles.json';
import { connect } from 'react-redux';

class MegaMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: '',
    };
  }

  render() {
    const role = this.props.role;
    const tour = this.props.type;

    const tourBusinessItems = NavigationRoles[role].tours[tour].tourBusiness.map((item, i) => {
      return (
        <p className={styles.Link} key={i}>
          {item.props.type === 'external' ? (
            <a tabIndex="0" href={item.route} target="_blank" rel="noopener noreferrer">
              {item.props.title}
              <i className="fas fa-external-link-alt"></i>
            </a>
          ) : (
            <Link tabIndex="0" to={item.route} onClick={this.props.clickHandler}>
              {item.props.title}
              <i className="fas fa-arrow-right"></i>
            </Link>
          )}
        </p>
      );
    });

    const tournamentItems = NavigationRoles[role].tours[tour].tournaments.map((item, i) => (
      <p className={styles.Link} key={i}>
        {item.props.type === 'external' ? (
          <a tabIndex="0" href={item.route} target="_blank" rel="noopener noreferrer">
            {item.props.title}
            <i className="fas fa-external-link-alt"></i>
          </a>
        ) : (
          <Link tabIndex="0" to={item.route} onClick={this.props.clickHandler}>
            {item.props.title}
            <i className="fas fa-arrow-right"></i>
          </Link>
        )}
      </p>
    ));

    const regulationItems = NavigationRoles[role].tours[tour].regulations.map((item, i) => {
      if (item.props.type === 'external') {
        if (item.props.preFlight !== undefined) {
          let fullRoute = item.route + this.props.profile.id;
          return (
            <p className={styles.Link} key={i}>
              <a tabIndex="0" href={fullRoute} target="_blank" rel="noopener noreferrer">
                {item.props.title}
                <i className="fas fa-external-link-alt"></i>
              </a>
            </p>
          );
        }
        return (
          <p className={styles.Link} key={i}>
            <a tabIndex="0" href={item.route} target="_blank" rel="noopener noreferrer">
              {item.props.title}
              <i className="fas fa-external-link-alt"></i>
            </a>
          </p>
        );
      } else {
        return (
          <p className={styles.Link} key={i}>
            {' '}
            <Link tabIndex="0" to={item.route} onClick={this.props.clickHandler}>
              {item.props.title}
              <i className="fas fa-arrow-right"></i>
            </Link>
          </p>
        );
      }
    });

    const resourceItems = NavigationRoles[role].tours[tour].resources.map((item, i) => (
      <p className={styles.Link} key={i}>
        {item.props.type === 'external' ? (
          <a tabIndex="0" href={item.route} target="_blank" rel="noopener noreferrer">
            {item.props.title}
            <i className="fas fa-external-link-alt"></i>
          </a>
        ) : (
          <Link tabIndex="0" to={item.route} onClick={this.props.clickHandler}>
            {item.props.title}
            <i className="fas fa-arrow-right"></i>
          </Link>
        )}
      </p>
    ));

    return (
      <div className={styles.MegaMenuContainer}>
        <div className={styles.MenuColumn}>
          <p className={styles.ColumnHeader}>Tournaments</p>
          {tournamentItems}
        </div>
        <div className={styles.MenuColumn}>
          <p className={styles.ColumnHeader}>Regulations and Policies</p>
          {regulationItems}
        </div>
        <div className={styles.MenuColumn}>
          <p className={styles.ColumnHeader}>Resources</p>
          {resourceItems}
        </div>
        <div className={styles.MenuColumn}>
          <p className={styles.ColumnHeader}>Tour Business</p>
          {tourBusinessItems}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const profile = state.profile;
  return { profile };
};

export default connect(mapStateToProps)(MegaMenu);
