import React, { useEffect, useState } from 'react';
import styles from './TeamCommitment.module.css';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CommitmentApi from '../../../api/commitmentApi';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import CommitmentContainer from './CommitmentContainer';
import { playerIdTourCodeLookup } from '../../../reducers/commitmentInfoReducer';
import { decodePlayer, encodePlayer } from '../../../util/titles';
import { receivePostCommitmentResponse } from '../../../actions/commitmentActions';
import Loader from '../../Common/Loader/Loader';

export default function CreateTeamCommitment({ globalStyles }) {
  const [selectionType, setSelectionType] = useState('tourMember');
  const [memberList, setMemberList] = useState([]);
  const [otherPlayerInputValue, setOtherPlayerInputValue] = useState('');
  const [teammatePlayerId, setTeammatePlayerId] = useState(-1);
  const [isError, setIsError] = useState(false);
  const [isLoadingPostResponse, setIsLoadingPostResponse] = useState(false);

  const { profile, token, commitmentInfo } = useSelector((state) => state);
  const encodedPlayerId = new URLSearchParams(useLocation().search).get('player');
  const playerId = encodedPlayerId ? decodePlayer(encodedPlayerId) : profile.id;
  const tokenId = token.id_token;

  const { tournamentScheduleId, mode } = useParams();
  const tourCode = new URLSearchParams(useLocation().search).get('tour') || profile.tourCodes[0];

  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleSelectionChange(e) {
    setSelectionType(e.target.value);
    setTeammatePlayerId(-1);
    setOtherPlayerInputValue('');
  }

  function handleCreateCommitment() {
    setIsLoadingPostResponse(true);
    let data;
    if (selectionType === 'tourMember') {
      data = { playerId, tournamentScheduleId, commitType: 'C', teammatePlayerId };
    } else if (selectionType === 'otherPlayer') {
      data = { playerId, tournamentScheduleId, commitType: 'C', otherTeammate: otherPlayerInputValue };
    } else if (selectionType === 'undecided') {
      data = { playerId, tournamentScheduleId, commitType: 'C' };
    }
    CommitmentApi.postTeamCommitments(data, tokenId)
      .then((resp) => {
        const returnPath = profile.type === 'player' ? '/worksheet' : `/commitments/${encodePlayer(playerId)}`;
        if (resp.data.successCommitCount > 0) {
          dispatch(receivePostCommitmentResponse(resp.data));
          navigate(`${returnPath}?commitSuccess=true`);
        } else {
          setIsError(true);
        }
        setIsLoadingPostResponse(false);
      })
      .catch(() => {
        setIsError(true);
        setIsLoadingPostResponse(false);
      });
  }

  useEffect(() => {
    CommitmentApi.getMemberList(playerId, tournamentScheduleId, tokenId).then((res) => setMemberList(res.data));
  }, [playerId, profile.tourCodes, tokenId, tourCode, tournamentScheduleId]);

  const disableCreateButton =
    (selectionType === 'tourMember' && teammatePlayerId === -1) || (selectionType === 'otherPlayer' && !otherPlayerInputValue) || !memberList.length;
  const commitmentInfoForPlayerTour = commitmentInfo[playerIdTourCodeLookup(playerId, tourCode)];
  const commitmentTournamentInfo = commitmentInfoForPlayerTour.playerCommitmentTournamentList.find(
    (item) => item.tournamentScheduleId.toString() === tournamentScheduleId,
  );

  return (
    <CommitmentContainer globalStyles={globalStyles} isError={isError} handleModalClick={() => setIsError(false)}>
      <div className={styles.MainContent}>
        <div className={styles.SectionContainer}>
          <div className={styles.HeaderText}>Commitment Information</div>
          <table className={styles.Table}>
            <tbody>
              <tr>
                <td>Player</td>
                <td>{commitmentInfoForPlayerTour.playerName}</td>
              </tr>
              <tr>
                <td>Tournament</td>
                <td>{commitmentTournamentInfo?.tournamentScheduleName}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className={styles.SectionContainer}>
          <div className={styles.HeaderText}>Teammate Selection</div>
          <table className={styles.Table}>
            <tbody>
              <tr>
                <td>Instructions</td>
                <td>
                  <div className={styles.SelectionInstructions}>
                    <h4>Please select partner from the following options:</h4>
                    <ul>
                      <li>1) Select PGA TOUR Member, enter name and select player.</li>
                      <li>2) If your partner is not a PGA TOUR Member, select 'Other' and manually enter their name.</li>
                      <li>3) If you do not know your partner yet, please select 'Undecided'.</li>
                      <li>4) You Must click 'Create' to save your entry.</li>
                    </ul>
                    <div>
                      <div>*NOTE: If PGA TOUR Member is not available for selection, they are already on a confirmed team.</div>
                      <div>*NOTE: Teams are not final until confirmed by PGA TOUR Competitions.</div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Selection Type</td>
                <td className={styles.RadioLabels}>
                  <input
                    type="radio"
                    id="tourMember"
                    name="selectionType"
                    value="tourMember"
                    checked={selectionType === 'tourMember'}
                    onChange={handleSelectionChange}
                  />
                  <label htmlFor="tourMember">PGA TOUR Member</label>
                  <input
                    type="radio"
                    id="otherPlayer"
                    name="selectionType"
                    value="otherPlayer"
                    checked={selectionType === 'otherPlayer'}
                    onChange={handleSelectionChange}
                  />
                  <label htmlFor="otherPlayer">Other</label>
                  <input
                    type="radio"
                    id="undecided"
                    name="selectionType"
                    value="undecided"
                    checked={selectionType === 'undecided'}
                    onChange={handleSelectionChange}
                  />
                  <label htmlFor="undecided">Undecided</label>
                </td>
              </tr>
              {selectionType !== 'undecided' && (
                <tr>
                  <td>{selectionType === 'tourMember' ? 'PGA TOUR Member' : 'Other Name'}</td>
                  <td>
                    {!memberList.length ? (
                      <Loader height={34} width={34} />
                    ) : selectionType === 'tourMember' ? (
                      <Autocomplete
                        onChange={(e, obj) => setTeammatePlayerId(obj?.id || -1)}
                        size="small"
                        id="combo-box-demo"
                        options={memberList}
                        getOptionLabel={(option) => option.name}
                        style={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Teammate" />}
                      />
                    ) : (
                      <input
                        className={styles.OtherInput}
                        type="text"
                        value={otherPlayerInputValue}
                        onChange={(e) => setOtherPlayerInputValue(e.target.value)}
                      />
                    )}
                  </td>
                </tr>
              )}
              <tr>
                <td />
                {isLoadingPostResponse ? (
                  <td>
                    <Loader height={34} width={34} />
                  </td>
                ) : (
                  <td>
                    <button disabled={disableCreateButton} className={styles.CreateButton} onClick={handleCreateCommitment}>
                      {mode === 'select' ? 'Select' : 'Commit'}
                    </button>
                    <button
                      className={styles.CancelButton}
                      onClick={() => navigate(profile.type === 'player' ? '/worksheet' : `/commitments/${encodePlayer(playerId)}`)}
                    >
                      Cancel
                    </button>
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </CommitmentContainer>
  );
}
