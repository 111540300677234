import React from 'react';
import styles from './MobileMenu.module.css';
import { Link } from 'react-router-dom';
import NavigationRoles from './NavigationRoles.json';
import { connect } from 'react-redux';
import { CHAMPIONS_TOUR, encodePlayer, KORN_FERRY_TOUR, PGA_TOUR, PGA_TOUR_AMERICAS } from '../../../util/titles';
import { CODE_LOOKUP } from '../../../util/tabMap';

class MobileMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tours: {
        showPlayerCommitments: false,
        showPgaTour: false,
        showChampionsTour: false,
        showKornFerryTour: false,
        showAmericasTour: false,
      },
      pgaTour: {
        showTournaments: false,
        showTourBusiness: false,
        showPlayerResources: false,
        showRegulations: false,
      },
      championsTour: {
        showTournaments: false,
        showTourBusiness: false,
        showPlayerResources: false,
        showRegulations: false,
      },
      kornFerryTour: {
        showTournaments: false,
        showTourBusiness: false,
        showPlayerResources: false,
        showRegulations: false,
      },
      americasTour: {
        showTournaments: false,
        showTourBusiness: false,
        showPlayerResources: false,
        showRegulations: false,
      },
    };
  }

  render() {
    const type = this.props.profile.type;

    const tourSections = ['tourBusiness', 'tournaments', 'regulations', 'resources'];

    var tourCodes;
    if (type === 'agent' || type === 'financial') {
      tourCodes = ['R', 'S', 'H', 'Y'];
    } else if (type === 'player') {
      tourCodes = this.props.profile.tourCodes;
    }

    const tourMenu = tourCodes
      .filter((code) => !!CODE_LOOKUP[code])
      .map((item, i) => {
        if (item === undefined || item === null) {
          return null;
        }
        var tourName;
        var tour;
        var tourState;
        var navTour;
        var stateGroup;
        switch (item) {
          case 'R':
            tourName = PGA_TOUR;
            tour = 'pga';
            navTour = 'pga';
            tourState = 'showPgaTour';
            stateGroup = 'pgaTour';
            break;
          case 'S':
            tourName = CHAMPIONS_TOUR;
            tour = 'champion';
            navTour = 'champions';
            tourState = 'showChampionsTour';
            stateGroup = 'championsTour';
            break;
          case 'H':
            tourName = KORN_FERRY_TOUR;
            tour = 'kornferry';
            navTour = 'kornFerry';
            tourState = 'showKornFerryTour';
            stateGroup = 'kornFerryTour';
            break;
          case 'Y':
            tourName = PGA_TOUR_AMERICAS;
            tour = 'americas';
            navTour = 'americas';
            tourState = 'showAmericasTour';
            stateGroup = 'americasTour';
            break;
          default:
            break;
        }
        const tourBusinessInfo = NavigationRoles[type].tours[navTour][tourSections[0]].map((itemA, a) => {
          return (
            <div className={styles.MobileMenuThird} key={a}>
              {itemA.props.type === 'external' ? (
                <a tabIndex="0" href={itemA.route} target="_blank" rel="noopener noreferrer">
                  {itemA.props.title}
                  <i className="fas fa-external-link-alt"></i>
                </a>
              ) : (
                <Link tabIndex="0" to={itemA.route} onClick={this.props.clickHandler}>
                  {itemA.props.title}
                  <i className="fas fa-arrow-right"></i>
                </Link>
              )}
            </div>
          );
        });

        const tournamentInfo = NavigationRoles[type].tours[navTour][tourSections[1]].map((itemB, b) => {
          return (
            <div className={styles.MobileMenuThird} key={b}>
              {itemB.props.type === 'external' ? (
                <a tabIndex="0" href={itemB.route} target="_blank" rel="noopener noreferrer">
                  {itemB.props.title}
                  <i className="fas fa-external-link-alt"></i>
                </a>
              ) : (
                <Link tabIndex="0" to={itemB.route} onClick={this.props.clickHandler}>
                  {itemB.props.title}
                  <i className="fas fa-arrow-right"></i>
                </Link>
              )}
            </div>
          );
        });

        const regulationsInfo = NavigationRoles[type].tours[navTour][tourSections[2]].map((itemC, c) => {
          if (itemC.props.type === 'external') {
            if (itemC.props.preFlight !== undefined) {
              let fullRoute = itemC.route + this.props.profile.id;
              return (
                <div className={styles.MobileMenuThird} key={c}>
                  <a tabIndex="0" href={fullRoute} target="_blank" rel="noopener noreferrer">
                    {itemC.props.title}
                    <i className="fas fa-external-link-alt"></i>
                  </a>
                </div>
              );
            } else {
              return (
                <div className={styles.MobileMenuThird} key={c}>
                  <a tabIndex="0" href={itemC.route} target="_blank" rel="noopener noreferrer">
                    {itemC.props.title}
                    <i className="fas fa-external-link-alt"></i>
                  </a>
                </div>
              );
            }
          } else {
            return (
              <div className={styles.MobileMenuThird} key={c}>
                <Link tabIndex="0" to={itemC.route} onClick={this.props.clickHandler}>
                  {itemC.props.title}
                  <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            );
          }
        });

        const resourcesInfo = NavigationRoles[type].tours[navTour][tourSections[3]].map((itemD, d) => {
          return (
            <div className={styles.MobileMenuThird} key={d}>
              {itemD.props.type === 'external' ? (
                <a tabIndex="0" href={itemD.route} target="_blank" rel="noopener noreferrer">
                  {itemD.props.title}
                  <i className="fas fa-external-link-alt"></i>
                </a>
              ) : (
                <Link tabIndex="0" to={itemD.route} onClick={this.props.clickHandler}>
                  {itemD.props.title}
                  <i className="fas fa-arrow-right"></i>
                </Link>
              )}
            </div>
          );
        });

        return (
          <div className={styles.MobileMenuSection} key={i}>
            {this.state.tours[tourState] ? (
              <div>
                <div className={[styles.MobileMenuParent, styles.MobileMenuParent_Active].join(' ')} onClick={() => this.expandOptions(tour)}>
                  {tourName}
                  <i className="fas fa-chevron-up"></i>
                </div>
                {this.state[stateGroup].showTournaments ? (
                  <div className={styles.MobileMenuSection}>
                    <div
                      className={[styles.MobileMenuSecond, styles.MobileMenuSecond_Active].join(' ')}
                      onClick={() => this.expandOptions(tour + '-tournaments')}
                    >
                      Tournaments
                      <i className="fas fa-chevron-up"></i>
                    </div>
                    {tournamentInfo}
                  </div>
                ) : (
                  <div className={styles.MobileMenuSection}>
                    <div className={styles.MobileMenuSecond} onClick={() => this.expandOptions(tour + '-tournaments')}>
                      Tournaments
                      <i className="fas fa-chevron-down"></i>
                    </div>
                  </div>
                )}
                {this.state[stateGroup].showTourBusiness ? (
                  <div className={styles.MobileMenuSection}>
                    <div
                      className={[styles.MobileMenuSecond, styles.MobileMenuSecond_Active].join(' ')}
                      onClick={() => this.expandOptions(tour + '-tourbusiness')}
                    >
                      Tour Business
                      <i className="fas fa-chevron-up"></i>
                    </div>
                    {tourBusinessInfo}
                  </div>
                ) : (
                  <div className={styles.MobileMenuSection}>
                    <div className={styles.MobileMenuSecond} onClick={() => this.expandOptions(tour + '-tourbusiness')}>
                      Tour Business
                      <i className="fas fa-chevron-down"></i>
                    </div>
                  </div>
                )}
                {this.state[stateGroup].showRegulations ? (
                  <div className={styles.MobileMenuSection}>
                    <div
                      className={[styles.MobileMenuSecond, styles.MobileMenuSecond_Active].join(' ')}
                      onClick={() => this.expandOptions(tour + '-regulations')}
                    >
                      Regulations
                      <i className="fas fa-chevron-up"></i>
                    </div>
                    {regulationsInfo}
                  </div>
                ) : (
                  <div className={styles.MobileMenuSection}>
                    <div className={styles.MobileMenuSecond} onClick={() => this.expandOptions(tour + '-regulations')}>
                      Regulations and Policies
                      <i className="fas fa-chevron-down"></i>
                    </div>
                  </div>
                )}
                {this.state[stateGroup].showPlayerResources ? (
                  <div className={styles.MobileMenuSection}>
                    <div
                      className={[styles.MobileMenuSecond, styles.MobileMenuSecond_Active].join(' ')}
                      onClick={() => this.expandOptions(tour + '-resources')}
                    >
                      Resources
                      <i className="fas fa-chevron-up"></i>
                    </div>
                    {resourcesInfo}
                  </div>
                ) : (
                  <div className={styles.MobileMenuSection}>
                    <div className={styles.MobileMenuSecond} onClick={() => this.expandOptions(tour + '-resources')}>
                      Resources
                      <i className="fas fa-chevron-down"></i>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className={styles.MobileMenuParent} onClick={() => this.expandOptions(tour)}>
                {tourName}
                <i className="fas fa-chevron-down"></i>
              </div>
            )}
          </div>
        );
      });

    if (this.props.globalStyles.isMobileSize) {
      var commitmentInfo;
      if (type === 'player') {
        commitmentInfo = (
          <div className={styles.MobileMenuSection}>
            <div className={styles.MobileMenuParent}>
              <Link tabIndex="0" to="/worksheet" onClick={this.props.clickHandler}>
                My Commitments
              </Link>
              <i className="fas fa-arrow-right"></i>
            </div>
          </div>
        );
      } else if (type === 'agent') {
        const playerItems = this.props.profile.players.map((itemP, p) => {
          var encodedString = encodePlayer(itemP.playerId);

          return (
            <div className={styles.MobileMenuThird} key={p}>
              <Link to={'/commitments/' + encodedString}>{itemP.playerName}</Link>
              <i className="fas fa-arrow-right"></i>
            </div>
          );
        });

        commitmentInfo = (
          <div className={styles.MobileMenuSection}>
            {this.state.showPlayerCommitments ? (
              <div>
                <div className={styles.MobileMenuParent} onClick={() => this.expandOptions('commitments')}>
                  My player's commitments
                  <i className="fas fa-chevron-up"></i>
                </div>
                {playerItems}
              </div>
            ) : (
              <div className={styles.MobileMenuSection}>
                <div className={styles.MobileMenuParent} onClick={() => this.expandOptions('commitments')}>
                  My player's commitments
                  <i className="fas fa-chevron-down"></i>
                </div>
              </div>
            )}
          </div>
        );
      }
      return (
        <div className={styles.MobileMenuContainer}>
          {commitmentInfo} {tourMenu}
        </div>
      );
    } else {
      return <div className={styles.MobileMenuContainer}>{tourMenu}</div>;
    }
  }

  expandOptions(type) {
    switch (type) {
      case 'commitments':
        this.setState({
          showPlayerCommitments: !this.state.showPlayerCommitments,
          tours: {
            showPgaTour: false,
            showChampionsTour: false,
            showKornFerryTour: false,
            showAmericasTour: false,
          },
        });
        break;
      case 'pga':
        this.setState({
          showPlayerCommitments: false,
          tours: {
            showPgaTour: !this.state.tours.showPgaTour,
            showChampionsTour: false,
            showKornFerryTour: false,
            showAmericasTour: false,
          },
        });
        break;
      case 'champion':
        this.setState({
          showPlayerCommitments: false,
          tours: {
            showPgaTour: false,
            showChampionsTour: !this.state.tours.showChampionsTour,
            showKornFerryTour: false,
            showAmericasTour: false,
          },
        });
        break;
      case 'kornferry':
        this.setState({
          showPlayerCommitments: false,
          tours: {
            showPgaTour: false,
            showChampionsTour: false,
            showKornFerryTour: !this.state.tours.showKornFerryTour,
            showAmericasTour: false,
          },
        });
        break;
      case 'americas':
        this.setState({
          showPlayerCommitments: false,
          tours: {
            showPgaTour: false,
            showChampionsTour: false,
            showKornFerryTour: false,
            showAmericasTour: !this.state.tours.showAmericasTour,
          },
        });
        break;
      case 'pga-tournaments':
        this.setState({
          pgaTour: {
            showTournaments: !this.state.pgaTour.showTournaments,
            showTourBusiness: false,
            showPlayerResources: false,
            showRegulations: false,
          },
        });
        break;
      case 'pga-tourbusiness':
        this.setState({
          pgaTour: {
            showTournaments: false,
            showTourBusiness: !this.state.pgaTour.showTourBusiness,
            showPlayerResources: false,
            showRegulations: false,
          },
        });
        break;
      case 'pga-resources':
        this.setState({
          pgaTour: {
            showTournaments: false,
            showTourBusiness: false,
            showPlayerResources: !this.state.pgaTour.showPlayerResources,
            showRegulations: false,
          },
        });
        break;
      case 'pga-regulations':
        this.setState({
          pgaTour: {
            showTournaments: false,
            showTourBusiness: false,
            showPlayerResources: false,
            showRegulations: !this.state.pgaTour.showRegulations,
          },
        });
        break;
      case 'champion-tournaments':
        this.setState({
          championsTour: {
            showTournaments: !this.state.championsTour.showTournaments,
            showTourBusiness: false,
            showPlayerResources: false,
            showRegulations: false,
          },
        });
        break;
      case 'champion-tourbusiness':
        this.setState({
          championsTour: {
            showTournaments: false,
            showTourBusiness: !this.state.championsTour.showTourBusiness,
            showPlayerResources: false,
            showRegulations: false,
          },
        });
        break;
      case 'champion-resources':
        this.setState({
          championsTour: {
            showTournaments: false,
            showTourBusiness: false,
            showPlayerResources: !this.state.championsTour.showPlayerResources,
            showRegulations: false,
          },
        });
        break;
      case 'champion-regulations':
        this.setState({
          championsTour: {
            showTournaments: false,
            showTourBusiness: false,
            showPlayerResources: false,
            showRegulations: !this.state.championsTour.showRegulations,
          },
        });
        break;
      case 'kornferry-tournaments':
        this.setState({
          kornFerryTour: {
            showTournaments: !this.state.kornFerryTour.showTournaments,
            showTourBusiness: false,
            showPlayerResources: false,
            showRegulations: false,
          },
        });
        break;
      case 'kornferry-regulations':
        this.setState({
          kornFerryTour: {
            showTournaments: false,
            showTourBusiness: false,
            showPlayerResources: false,
            showRegulations: !this.state.kornFerryTour.showRegulations,
          },
        });
        break;
      case 'kornferry-resources':
        this.setState({
          kornFerryTour: {
            showTournaments: false,
            showTourBusiness: false,
            showPlayerResources: !this.state.kornFerryTour.showPlayerResources,
            showRegulations: false,
          },
        });
        break;
      case 'kornferry-tourbusiness':
        this.setState({
          kornFerryTour: {
            showTournaments: false,
            showTourBusiness: !this.state.kornFerryTour.showTourBusiness,
            showPlayerResources: false,
            showRegulations: false,
          },
        });
        break;
      case 'americas-tournaments':
        this.setState({
          americasTour: {
            showTournaments: !this.state.americasTour.showTournaments,
            showTourBusiness: false,
            showPlayerResources: false,
            showRegulations: false,
          },
        });
        break;
      case 'americas-tourbusiness':
        this.setState({
          americasTour: {
            showTournaments: false,
            showTourBusiness: !this.state.americasTour.showTourBusiness,
            showPlayerResources: false,
            showRegulations: false,
          },
        });
        break;
      case 'americas-resources':
        this.setState({
          americasTour: {
            showTournaments: false,
            showTourBusiness: false,
            showPlayerResources: !this.state.americasTour.showPlayerResources,
            showRegulations: false,
          },
        });
        break;
      case 'americas-regulations':
        this.setState({
          americasTour: {
            showTournaments: false,
            showTourBusiness: false,
            showPlayerResources: false,
            showRegulations: !this.state.americasTour.showRegulations,
          },
        });
        break;
      default:
        this.setState({
          tours: {
            showPgaTour: false,
            showChampionsTour: false,
            showKornFerryTour: false,
            showAmericasTour: false,
          },
        });
        break;
    }
  }
}

const mapStateToProps = (state) => {
  const profile = state.profile;
  return { profile };
};

export default connect(mapStateToProps)(MobileMenu);
