import { PROFILE_INFO_URL, ENDORSEMENTS_INFO_URL } from '../url/service-url';

export default {
  getProfileInfo: (id, token, type, isAdminMode) => {
    const isAdmin = isAdminMode ? 'true' : 'false';
    return fetch(`${PROFILE_INFO_URL}/${id}/${token}/${isAdmin}${type ? `?type=${type}` : ''}`)
      .then((res) => res.json())
      .catch((error) => console.log(`Error in getting profile info ${error}`));
  },
  getPlayerEndorsements: (playerId) => {
    return fetch(`${ENDORSEMENTS_INFO_URL}/player/${playerId}`)
      .then((res) => res.json())
      .catch((error) => console.log(`Error in getting player endorsements ${error}`));
  },
  getEndorsementsBrands: () => {
    return fetch(`${ENDORSEMENTS_INFO_URL}/brands`)
      .then((res) => res.json())
      .catch((error) => console.log(`Error in getting endorsements brands ${error}`));
  },
  updateSponsorshipDeal: (data) => {
    const body = {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };
    return fetch(`${ENDORSEMENTS_INFO_URL}/sponsors`, body)
      .then((res) => res.json())
      .catch((error) => console.log(`Error in getting endorsements brands ${error}`));
  },
  updateContactLastVerified: (playerId) => {
    const body = {
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ playerId }),
    };
    return fetch(`${ENDORSEMENTS_INFO_URL}/player/verify`, body)
      .then((res) => res.json())
      .catch((error) => console.log(`Error in updating contact last verified ${error}`));
  },
};
