import React from 'react';
import styles from './Reports.module.css';
import { connect } from 'react-redux';
import TourApi from '../../api/tourApi';
import Loader from '../Common/Loader/Loader';
import { withLocation } from '../../util/routerHooks';

class PlayerInfoReport extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      playerName: '',
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { profile, location } = this.props;
    let playerId;

    if (profile.type === 'agent') {
      playerId = location.state.id;
      this.setState({ playerName: profile.players.find((p) => p.playerId.toString() === playerId)?.playerName });
    } else {
      playerId = profile.id;
      this.setState({ playerName: profile.fullName });
    }

    TourApi.getPlayerInformation(playerId).then((result) => {
      if (this._isMounted) {
        this.setState({
          isLoading: false,
          playerInfo: result,
        });
      }
    });
  }

  render() {
    const { isLoading, playerName } = this.state;

    if (isLoading) {
      return <Loader withWrapper />;
    } else {
      const { playerInfo } = this.state;
      var showSocial = false;
      var socialItems;
      if (playerInfo.social !== undefined) {
        showSocial = true;
        socialItems = playerInfo.social.map((item, i) => {
          return (
            <div className={styles.InfoItem} key={i}>
              <div>
                <strong>{item.SocialPlatform}</strong>
              </div>
              <div>{item.socialURL}</div>
            </div>
          );
        });
      }

      var showBullets = false;
      var bullets;
      if (playerInfo.bullets !== undefined) {
        showBullets = true;
        bullets = playerInfo.bullets.map((item, i) => {
          return <li key={i}>{item.bullet}</li>;
        });
      }

      return (
        <div className={styles.MainContainer}>
          <div className={styles.ReportContainer}>
            <div
              style={{
                minHeight: this.props.globalStyles.isTabletSize
                  ? this.props.globalStyles.windowHeight + 130
                  : this.props.globalStyles.windowHeight + 90,
              }}
            >
              <h1>Player Information - {playerName}</h1>
              <div className={styles.InstructionText}>
                <p>
                  Below is the information we have on file for your Social Media accounts and the biographical information displayed on the electronic
                  scoreboards onsite.
                </p>
                <p>
                  For any questions, or to request any updates, please contact the Communications department at{' '}
                  <a href="mailto:r_playerbullets@pgatourhq.com"> r_playerbullets@pgatourhq.com</a>.
                </p>
              </div>
              {showSocial ? (
                <div>
                  <h2>Social Media</h2>
                  <div className={styles.InfoItems}>{socialItems}</div>
                </div>
              ) : (
                <h2>No Social Media</h2>
              )}
              {showBullets ? (
                <div>
                  <h2>Bullets</h2>
                  <div className={styles.BulletItems}>
                    <ul>{bullets}</ul>
                  </div>
                </div>
              ) : (
                <h2>No Information Bullets</h2>
              )}
            </div>
          </div>
        </div>
      );
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
}

const mapStateToProps = (state) => {
  const profile = state.profile;
  return { profile };
};

export default withLocation(connect(mapStateToProps)(PlayerInfoReport));
