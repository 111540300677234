import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import store from './reducers/_store';
import SharepointApi from './api/sharepointApi';
import * as serviceWorker from './serviceWorker';
import { createTheme, ThemeProvider } from '@mui/material';

const persistor = persistStore(store);

const theme = createTheme({
  palette: {
    primary: {
      main: '#013d7c',
    },
  },
});

const container = document.getElementById('root');
const appRoot = createRoot(container);
appRoot.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </PersistGate>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Intercept outside SharePoint links to download content pdf
const root = document.getElementById('root');
root.addEventListener('click', (e) => {
  if (e.target.tagName !== 'A') {
    return;
  }

  if (e.target.href.includes('PGATOURLINKS/SiteAssets')) {
    const indexOfFirst = e.target.href.indexOf('SiteAssets/');
    const pathToFile = e.target.href.substring(indexOfFirst + 'SiteAssets/'.length);
    e.preventDefault();
    const windowReference = window.open('about:blank', '_blank');
    SharepointApi.getDownloadLink(pathToFile).then((result) => {
      if (result.error !== undefined) {
        if (result.error.code === 'itemNotFound') {
          windowReference.location = '/content-error/' + pathToFile;
        }
      } else {
        fetch(result['@microsoft.graph.downloadUrl'])
          .then((response) => response.blob())
          .then((data) => {
            windowReference.location = URL.createObjectURL(data);
          });
      }
    });
  } else if (window.location.hash.length !== 0 && e.target.hash !== '') {
    const scrollHeight = document.querySelector(e.target.hash).offsetTop - 90;
    window.scroll(0, scrollHeight);
    e.preventDefault();
  } else {
    window.scroll(0, 0);
  }
});
