import React, { useEffect, useRef, useState } from 'react';
import styles from './Reports.module.css';
import { useSelector } from 'react-redux';
import { TOUR_NAMES_BY_CODE } from '../../util/titles';
import { EARNING_STATEMENT_URL, YEAR_END_STATEMENT_SERVICE_URL } from '../../url/service-url';
import { getFixedLength, randomBase64String } from '../../util/generalUtils';
import SharepointApi from '../../api/sharepointApi';
import { useLocation } from 'react-router-dom';
import EarningsApi from '../../api/earningsApi';
import Loader from '../Common/Loader/Loader';

const AVAILABLE_YEARS = [2022, 2023, 2024];

export default function EarningsStatements({ globalStyles, reportType }) {
  const [{ encodedPlayerId, encodedPrefixes }, setEncodedValues] = useState({});
  const [selectedTourIndex, setSelectedTourIndex] = useState(0);
  const [sharepointToken, setSharepointToken] = useState('');
  const [unavailableYearTours, setUnavailableYearTours] = useState(null);
  const profile = useSelector((state) => state.profile);
  const availableTourCodes = useRef([]);
  const location = useLocation();

  useEffect(() => {
    let playerId;
    if (profile.type === 'agent' || profile.type === 'financial') {
      playerId = getFixedLength(location.state.id, 5);
      availableTourCodes.current = profile.players.find((player) => player.playerId === location.state.id)?.tourCodes;
    } else if (profile.type === 'player') {
      playerId = getFixedLength(profile.id, 5);
      availableTourCodes.current = profile.tourCodes;
    }
    let theEncodedPlayerId, theEncodedPrefixes, theSharepointToken;
    const tokenPromises = [
      EarningsApi.getEncodedId(playerId, AVAILABLE_YEARS, availableTourCodes.current).then(({ data }) => {
        theEncodedPlayerId = data.playerId;
        theEncodedPrefixes = data.prefixes;
        setEncodedValues({
          encodedPlayerId: theEncodedPlayerId,
          encodedPrefixes: theEncodedPrefixes,
        });
      }),
      SharepointApi.getToken().then((response) => {
        theSharepointToken = response.TOKEN.access_token;
        setSharepointToken(theSharepointToken);
      }),
    ];
    Promise.all(tokenPromises).then(() => {
      let failedTourYears = [];
      let requestTestPromises = [];
      for (let i = 0; i < AVAILABLE_YEARS.length; i++) {
        for (let j = 0; j < availableTourCodes.current.length; j++) {
          requestTestPromises.push(
            fetch(`${EARNING_STATEMENT_URL}/${theEncodedPrefixes[i][j]}/${theEncodedPlayerId}/${theSharepointToken}/player-earnings-statement`).then(
              (resp) => {
                if (resp.status === 250) {
                  failedTourYears.push(`${availableTourCodes.current[j]}_${AVAILABLE_YEARS[i]}`);
                }
              },
            ),
          );
        }
      }
      Promise.all(requestTestPromises).then(() => setUnavailableYearTours(failedTourYears));
    });
  }, [location, location.state, profile]);

  const tourOptions = availableTourCodes.current.map((tourCode, i) => (
    <div className={selectedTourIndex === i ? styles.Active : ''} onClick={() => setSelectedTourIndex(i)} key={tourCode}>
      {TOUR_NAMES_BY_CODE[tourCode]}
    </div>
  ));

  let reportContent;
  if (reportType === 'ye-statements') {
    reportContent = (
      <>
        <h1>PGA TOUR Financial Review</h1>
        <a
          href={`${YEAR_END_STATEMENT_SERVICE_URL}/${randomBase64String()}/${encodedPlayerId}`}
          style={{ fontSize: '1rem', paddingLeft: '0.25rem' }}
          target="_blank"
          rel="noreferrer noopener"
        >
          Click to view your PGA TOUR Year End Financial Review
        </a>
      </>
    );
  } else {
    reportContent = (
      <>
        <h1>
          Earnings Statements -
          {profile.type === 'agent' || profile.type === 'financial'
            ? profile.players.find((player) => player.playerId === location.state.id)?.playerName
            : profile.fullName}
        </h1>
        <div className={styles.StatementOptions}>
          <div className={styles.NoPointer}>Select Tour:</div>
          {tourOptions}
        </div>
        <ul>
          {unavailableYearTours ? (
            AVAILABLE_YEARS.map((year, i) => {
              if (unavailableYearTours.includes(`${availableTourCodes.current[selectedTourIndex]}_${year}`)) {
                return null;
              } else {
                return (
                  <li key={year}>
                    <a
                      href={`${EARNING_STATEMENT_URL}/${encodedPrefixes[i][selectedTourIndex]}/${encodedPlayerId}/${sharepointToken}/player-earnings-statement`}
                      className={styles.EarningsLink}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {TOUR_NAMES_BY_CODE[availableTourCodes.current[selectedTourIndex]]} Earnings Statement - {year}
                    </a>
                  </li>
                );
              }
            })
          ) : (
            <Loader height={60} width={60} />
          )}
          {AVAILABLE_YEARS.length === unavailableYearTours?.filter((yt) => yt.includes(availableTourCodes.current[selectedTourIndex])).length && (
            <li>No earnings statements available for {TOUR_NAMES_BY_CODE[availableTourCodes.current[selectedTourIndex]]}</li>
          )}
        </ul>
      </>
    );
  }

  return (
    <div className={styles.MainContainer}>
      <div
        className={styles.ReportContainer}
        style={{
          textAlign: 'center',
          minHeight: globalStyles.windowHeight + (globalStyles.isMobileSize ? 20 : 0),
        }}
      >
        {reportContent}
      </div>
    </div>
  );
}
