import React from 'react';
import styles from './Login.module.css';
import { Link } from 'react-router-dom';
import Logo from '../img/logo_pgacolor.png';
import pgaTourLogo from '../img/logo_pgabandw.png';
import championsTourLogo from '../img/logo_champions.png';
import kornFerryTourLogo from '../img/logo_kornferry.png';
import americasTourLogo from '../img/logo_americas.png';

const LogoutPage = () => {
  return (
    <div className={styles.LoginContainer} style={{ zIndex: '1000', position: 'fixed', top: '0', left: '0', overflowY: 'scroll' }}>
      <div className={styles.BackgroundImage} />
      <div className={styles.Shade} />
      <img className={styles.Logo} src={Logo} alt="Logo of PGA" />
      <h1 className={styles.HeaderText}>You have been logged out.</h1>
      <h2 className={styles.HeaderTextH2}>
        Please close this window or go back to the <Link to="/">Home</Link> page.
      </h2>
      <div className={styles.FooterContainer}>
        <div className={styles.LandingLogos}>
          <img src={championsTourLogo} alt="The PGA Champions Tour logo" />
          <img src={kornFerryTourLogo} alt="The PGA Korn Ferry Tour logo" />
          <img src={pgaTourLogo} alt="The PGA Tour logo" />
          <img src={americasTourLogo} alt="The PGA Canada Tour logo" />
        </div>
      </div>
    </div>
  );
};

export default LogoutPage;
