import {
  CHAMP_TOUR_ANNOUNCEMENTS,
  CHAMP_TOUR_ANNOUNCEMENTS_AGT,
  CHAMPIONS_TOUR_CONTACTS,
  KF_TOUR_ANNOUNCEMENTS,
  KF_TOUR_ANNOUNCEMENTS_AGT,
  KF_TOUR_CONTACTS,
  PGA_TOUR_ANNOUNCEMENTS,
  PGA_TOUR_ANNOUNCEMENTS_AGT,
  PGA_TOUR_CONTACTS,
  PGA_TOUR_AMERICAS_ANNOUNCEMENTS,
  PGA_TOUR_AMERICAS_ANNOUNCEMENTS_AGT,
  PGA_TOUR_AMERICAS_CONTACTS,
  SHAREPOINT_AUTHENTICATION_URL,
  SHAREPOINT_BASE_URL,
} from '../url/service-url';

function saveSharepointToken(token) {
  const expireTime = new Date();
  expireTime.setSeconds(parseInt(token.expires_in) - 60);
  localStorage.setItem('sharepointToken', JSON.stringify({ ...token, expireTime }));
}

async function getSharePointToken(genNewToken = false) {
  const storedToken = localStorage.getItem('sharepointToken');
  const currentToken = JSON.parse(storedToken || '{}');
  if (currentToken.expireTime) {
    currentToken.expireTime = new Date(currentToken.expireTime);
  }
  if (genNewToken || !currentToken.access_token || currentToken.expireTime < new Date()) {
    return fetch(SHAREPOINT_AUTHENTICATION_URL)
      .then((res) => res.json())
      .then((data) => {
        saveSharepointToken(data.TOKEN);
        return data.TOKEN.access_token;
      });
  } else {
    return currentToken.access_token;
  }
}

async function sendRequest(url, authPrefix = '', genNewToken = false) {
  const token = await getSharePointToken(genNewToken);
  return fetch(url, {
    headers: new Headers({ Authorization: authPrefix + token }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (!genNewToken && res?.error?.code.includes('InvalidAuthentication')) {
        return sendRequest(url, authPrefix, true); // try again in case of 401 error
      } else {
        return res;
      }
    })
    .catch((error) => console.log(`Error in Sharepoint Api: ${url} ${error}`));
}

async function findPageByName(url, pageName) {
  const pageList = await sendRequest(url, 'Bearer ');
  const pages = pageList.value;
  if (!pages) {
    return undefined;
  }
  const nextLink = pageList['@odata.nextLink'];
  const pageFound = pages.find((page) => page.name === pageName);
  if (pageFound) {
    return pageFound;
  } else if (nextLink) {
    return findPageByName(nextLink, pageName);
  } else {
    return undefined;
  }
}

async function getAllPages(url, pageList = []) {
  const pageResponse = await sendRequest(url, 'Bearer ');
  const pages = pageResponse.value;
  if (!pages) {
    return pageList;
  } else {
    pageList.push(...pages);
  }
  const nextLink = pageResponse['@odata.nextLink'];
  if (nextLink) {
    return getAllPages(nextLink, pageList);
  } else {
    return pageList;
  }
}

export default {
  getToken: () => {
    return fetch(SHAREPOINT_AUTHENTICATION_URL)
      .then((res) => res.json())
      .catch((error) => console.log(`Error in SharePoint API call: auth ${error}`));
  },
  getAnnouncements: (tourCode, profileType) => {
    const urls = {
      player: {
        R: PGA_TOUR_ANNOUNCEMENTS,
        S: CHAMP_TOUR_ANNOUNCEMENTS,
        H: KF_TOUR_ANNOUNCEMENTS,
        Y: PGA_TOUR_AMERICAS_ANNOUNCEMENTS,
      },
      agent: {
        R: PGA_TOUR_ANNOUNCEMENTS_AGT,
        S: CHAMP_TOUR_ANNOUNCEMENTS_AGT,
        H: KF_TOUR_ANNOUNCEMENTS_AGT,
        Y: PGA_TOUR_AMERICAS_ANNOUNCEMENTS_AGT,
      },
    };
    return sendRequest(urls[profileType][tourCode] + '/microsoft.graph.sitePage/webparts', 'Bearer ');
  },
  getContent: (contentId) => sendRequest(SHAREPOINT_BASE_URL + contentId + '/microsoft.graph.sitePage/webparts'),
  getFile: (contentId) => sendRequest(contentId),
  getAllPages: () => getAllPages(SHAREPOINT_BASE_URL + 'pages?select=id,name,title'),
  findPageByName: (pageName) => findPageByName(SHAREPOINT_BASE_URL + 'pages?select=id,name,title', pageName),
  getDownloadLink: (path) => {
    const url = SHAREPOINT_BASE_URL + 'drives/b!kAa_2jJkLkKXDNxgdbDQ_CbKkbOFPQlEqyM91nR4o_ChlQim8JowRasR3EpfC3fE/root:/' + path;
    return sendRequest(url, 'Bearer ');
  },
  getContactInfoForTour: (tourCode) => {
    const urlsByCode = {
      R: PGA_TOUR_CONTACTS,
      S: CHAMPIONS_TOUR_CONTACTS,
      H: KF_TOUR_CONTACTS,
      Y: PGA_TOUR_AMERICAS_CONTACTS,
    };
    return sendRequest(urlsByCode[tourCode] + '/microsoft.graph.sitePage/webparts', 'Bearer ');
  },
};
