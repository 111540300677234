import React from 'react';
import styles from './Alerts.module.css';
import Parser from 'html-react-parser';

const StyledAlert = (props) => {
  const alertMessage = props.alertContent;

  const alertMessageItem = Parser(alertMessage, { replace: (node) => replaceTournamentInfoPageLinks(node) });

  return (
    <div className={styles.AlertWindow}>
      <div className={styles.HornIcon}>
        <i className="fas fa-bullhorn" />
      </div>
      <div className={styles.AlertMessage}>{alertMessageItem}</div>
      <div className={styles.CloseButton} onClick={props.handler}>
        <i className="fas fa-times" />
      </div>
    </div>
  );
};

function replaceTournamentInfoPageLinks(domNode) {
  if (domNode.name === 'a' && domNode.attribs.href.includes('sites/PGATOURLINKS')) {
    const split = domNode.attribs.href.substring(domNode.attribs.href.indexOf('sites/PGATOURLINKS/SiteAssets' + 1));
    const text = findText(domNode);
    return (
      <a href={`/${split}`} target="_blank" rel="noreferrer">
        {text}
      </a>
    );
  }
}

function findText(startNode) {
  let currentNode = startNode;
  while (currentNode) {
    if (currentNode.type === 'text') {
      return currentNode.data;
    }
    currentNode = currentNode.children[0];
  }
  return '';
}

export default StyledAlert;
