export const PGA_TOUR = 'PGA TOUR';
export const CHAMPIONS_TOUR = 'PGA TOUR Champions';
export const KORN_FERRY_TOUR = 'Korn Ferry Tour';
export const PGA_TOUR_AMERICAS = 'PGA Tour Americas';

export const TOUR_NAMES_BY_CODE = {
  R: PGA_TOUR,
  S: CHAMPIONS_TOUR,
  H: KORN_FERRY_TOUR,
  Y: PGA_TOUR_AMERICAS,
};
export const ALL_TOUR_CODES = Object.keys(TOUR_NAMES_BY_CODE);
export const TOUR_CODE_BY_SHORTNAME = {
  pga: 'R',
  champions: 'S',
  kornFerry: 'H',
  americas: 'Y',
};

export const encodePlayer = (playerId) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let j = 0; j < 8; j++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  const playerIdString = result + '-' + playerId;
  return btoa(playerIdString);
};

export const decodePlayer = (encodedString) => {
  const decodedString = atob(encodedString);
  const playerIdParts = decodedString.split('-');
  return playerIdParts[1];
};
