import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

export default function SearchCreate({ value, options, onValueChange, label }) {
  return (
    <Autocomplete
      value={value}
      className="mui-search-create"
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          onValueChange({
            ...value,
            isNew: true,
            name: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          onValueChange({
            ...value,
            name: newValue.inputValue,
            isNew: true,
          });
        } else {
          onValueChange({
            ...value,
            ...newValue,
            name: newValue?.name || '',
            id: newValue?.id || -1,
            isNew: newValue?.isNew || false,
          });
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.name);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            isNew: true,
            inputValue,
            name: `Create "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="combo-box-demo"
      options={options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      renderOption={(props, option) => (
        <li {...props} style={option.color ? { color: option.color } : {}} key={option.id}>
          {option.name}
        </li>
      )}
      sx={{ width: 300 }}
      size="small"
      freeSolo
      renderInput={(params) => {
        return <TextField variant="outlined" {...params} InputLabelProps={{ shrink: false }} label={params?.inputProps?.value ? ' ' : label} />;
      }}
    />
  );
}
