import React, { useEffect, useState } from 'react';
import styles from './CommitmentWorkSheet.module.css';
import WelcomeHeader from '../../Common/Headers/WelcomeHeader';
import FieldAlert from '../../Common/Alerts/FieldAlert';
import ErrorModal from '../../Common/Modals/ErrorModal';
import CommitmentApi from '../../../api/commitmentApi';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { TOUR_NAMES_BY_CODE } from '../../../util/titles';
import { receiveCommitmentInfo, receiveTeamField } from '../../../actions/commitmentActions';
import Loader from '../../Common/Loader/Loader';
import Modal from '../../Common/Modals/Modal';
import { canWithdrawFromTour, getCommitmentData } from '../../../util/commitmentUtils';

function PlayerCommitments({ globalStyles }) {
  const { profile, token } = useSelector((state) => state);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [commitmentData, setCommitmentData] = useState({});
  const [commitToTournaments, setCommitToTournaments] = useState([]);
  const [isError, setIsError] = useState(false);
  const [showWithdrawConfirmModal, setShowWithdrawConfirmModal] = useState(false);
  const [tourCode, setTourCode] = useState(profile?.tourCodes[0]);
  const [fetchWorksheetData, setFetchWorksheetData] = useState(false);
  const canWithdrawFromThisTour = canWithdrawFromTour(tourCode);

  const searchParams = new URLSearchParams(location.search);
  const justCommittedSuccess = searchParams.get('commitSuccess') === 'true';
  const isWithdrawPage = searchParams.get('withdraw') === 'true';

  useEffect(() => {
    CommitmentApi.getPlayerCommitmentWorksheet(profile.id, tourCode, token.id_token).then((response) => {
      dispatch(receiveCommitmentInfo(response.data));
      setCommitmentData(response.data);
      setIsLoading(false);
      for (let tournament of response.data.playerCommitmentTournamentList) {
        if (tournament.officialTeamFormat) {
          const tournamentScheduleId = tournament.tournamentScheduleId;
          CommitmentApi.getTeamField(tournamentScheduleId, token.id_token).then((teamResp) => {
            dispatch(receiveTeamField(tournamentScheduleId, teamResp.data));
          });
        }
      }
    });
  }, [dispatch, profile.id, profile.tourCodes, tourCode, token.id_token, fetchWorksheetData]);

  function postCommitment(isWithdrawPage) {
    setShowWithdrawConfirmModal(false);
    setIsLoading(true);
    const data = {
      playerId: profile.id,
      [isWithdrawPage ? 'withdrawTournamentScheduleIds' : 'tournamentScheduleIds']: commitToTournaments,
      modifiedBy: `${profile.fullName} ${profile.email}`,
      modifiedFrom: 'websiteapp',
    };
    CommitmentApi.postPlayerCommitments(data, token.id_token)
      .then((response) => setIsError(!!response.ERROR))
      .catch(() => setIsError(true))
      .finally((response) => {
        setCommitToTournaments([]);
        setFetchWorksheetData((prev) => !prev);
        navigate('/worksheet');
        window.scrollTo(0, 0);
      });
  }

  function handleTourChange(event) {
    const newTour = event.target.value || tourCode;
    setIsLoading(true);
    setTourCode(newTour);
  }

  function handleCommitmentChange(event) {
    const eventName = event.target.name;
    if (event.target.checked) {
      setCommitToTournaments((prev) => [...prev, eventName]);
    } else {
      setCommitToTournaments(commitToTournaments.filter((value) => value !== eventName));
    }
  }

  function handleCommitWithdrawClick(event) {
    const isWithdraw = event.target.innerHTML.toLowerCase() === 'withdraw';
    if (isWithdraw) {
      setShowWithdrawConfirmModal(true);
    } else {
      postCommitment(false);
    }
  }

  function handleWithdrawToggle(e) {
    e.target.blur();
    setCommitToTournaments([]);
  }

  const commitmentTournamentList = commitmentData.playerCommitmentTournamentList || [];
  const strengthOfField = commitmentData.hasMetStrengthOfFieldRequirement;
  const theCommitmentData = getCommitmentData(commitmentTournamentList, isWithdrawPage, handleCommitmentChange, profile, tourCode);
  const lastUpdatedDate = new Intl.DateTimeFormat('en-US').format(new Date());

  const teamEventNamesList = commitmentTournamentList.filter((t) => t.officialTeamFormat).map((t) => t.tournamentScheduleName);
  const symbolStyle = globalStyles.isMobileSize ? styles.MobileSymbolItem : styles.SymbolItem;
  const tourCodeDropdown = profile.tourCodes.filter((tCode) => !isWithdrawPage || canWithdrawFromTour(tCode));

  const mainContent = (
    <>
      <div className={styles.CommitmentWorksheet}>
        <div className={styles.SectionHeader}>
          {profile.fullName} {isWithdrawPage ? 'Withdrawals' : 'Commitments'}
          <h6>Commitments Worksheet as of {lastUpdatedDate}. To commit to an event(s), check the corresponding box and click Commit.</h6>
        </div>
        {justCommittedSuccess && <FieldAlert type="commit-success" />}
        {tourCode === 'R' ? <div>{strengthOfField ? <FieldAlert type="positive" /> : <FieldAlert />}</div> : null}
        <div className={styles.Commitments}>
          <div className={styles.Filters}>
            <div className={globalStyles.isMobileSize ? styles.MobileFilter : styles.Filter}>
              <label>Tour</label>
              <select name="tour" value={tourCode} onChange={handleTourChange}>
                {tourCodeDropdown.map((code) => (
                  <option key={code} value={code}>
                    {TOUR_NAMES_BY_CODE[code]}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {commitmentTournamentList.length > 0 ? (
            <div>
              <div className={styles.SymbolKey}>
                <div className={styles.SymbolHeader}>Symbol Key</div>
                <div className={styles.Symbols} style={{ flexFlow: globalStyles.isMobileSize ? 'column nowrap' : 'row nowrap' }}>
                  <div className={symbolStyle}>
                    <i className="fas fa-check-square" style={{ color: '#9AA5B3' }} />
                    Player is committed.
                  </div>
                  {tourCode === 'R' && (
                    <div className={symbolStyle}>
                      <i className="fas fa-info-circle" style={{ color: '#167F06' }} />
                      Satisfies the Strength of Field requirement for the player.
                    </div>
                  )}
                  <div className={symbolStyle}>
                    <i className="fas fa-question-circle" />
                    Player is committed to two tournaments during the same dates.
                  </div>
                  {teamEventNamesList.length > 0 && (
                    <div className={symbolStyle}>
                      <i className="fas fa-check-circle " style={{ color: '#167f06' }} />
                      Player's team has been confirmed for the {teamEventNamesList.join(' and ')}.
                    </div>
                  )}
                </div>
              </div>
              <div className={globalStyles.isMobileSize ? styles.MobileCommitmentsTable : styles.CommitmentsTable}>
                {canWithdrawFromThisTour && (
                  <div className={styles.WithdrawInfo}>
                    {isWithdrawPage ? (
                      <p>
                        To return to the Commitment Worksheet,{' '}
                        <Link to="/worksheet" onClick={handleWithdrawToggle}>
                          click here
                        </Link>
                        .
                      </p>
                    ) : (
                      <p>
                        To withdraw from a tournament,{' '}
                        <Link to="/worksheet?withdraw=true" onClick={handleWithdrawToggle}>
                          click here
                        </Link>
                        .
                      </p>
                    )}
                    <p>
                      To withdraw after the deadline, please contact the Competitions Department at <a href="tel:+18007422244">1-800-742-2244</a>.
                    </p>
                  </div>
                )}
                <table>
                  <thead>
                    <tr>
                      <th style={isWithdrawPage ? { color: 'red' } : {}}>{isWithdrawPage ? 'Withdraw' : 'Commit'}</th>
                      <th>Dates</th>
                      <th>Tournament</th>
                      <th>#</th>
                      <th>Position / Field</th>
                      {(tourCode === 'R' || tourCode === 'H') && <th>Pro-Am?</th>}
                    </tr>
                  </thead>
                  <tbody>{theCommitmentData}</tbody>
                </table>
              </div>
            </div>
          ) : (
            <p>0 Results</p>
          )}
        </div>
        <button className={styles.CommitButton} onClick={handleCommitWithdrawClick} disabled={commitToTournaments.length < 1}>
          {isWithdrawPage ? 'Withdraw' : 'Commit'}
        </button>
      </div>
    </>
  );

  if (isLoading || !commitmentTournamentList) {
    return <Loader withWrapper />;
  }

  const withdrawModalTitle = (
    <>
      Withdraw <span className={styles.Lighter}>{profile.fullName}</span> from {''}
      {commitToTournaments.length} tournament{commitToTournaments.length > 1 && 's'}?
    </>
  );
  const withdrawModalPrompt = <p>Are you sure you want to withdraw from the following tournament{commitToTournaments.length > 1 && 's'}?</p>;

  return (
    <div className={styles.MainContainer}>
      <ErrorModal open={isError} onClose={() => setIsError(false)} isWithdraw={isWithdrawPage} />
      <Modal
        open={showWithdrawConfirmModal}
        submitText="Withdraw"
        onCancel={() => setShowWithdrawConfirmModal(false)}
        onSubmit={() => postCommitment(true)}
        title={withdrawModalTitle}
      >
        {withdrawModalPrompt}
        <ul>
          {commitmentTournamentList
            .filter((t) => commitToTournaments.includes(t.tournamentScheduleId.toString()))
            .map((t) => (
              <li key={t.tournamentScheduleId}>{t.tournamentScheduleName}</li>
            ))}
        </ul>
      </Modal>
      <WelcomeHeader
        globalStyles={globalStyles}
        title="My Commitments"
        tagline="your resource for tournament and event commitments."
        bannerImage="commitment"
      />
      {mainContent}
    </div>
  );
}

export default PlayerCommitments;
