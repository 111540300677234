import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

function Video({ globalStyles: { isMobileSize } }) {
  const profile = useSelector((state) => state.profile);
  if (profile?.type === 'player') {
    return (
      <div style={{ textAlign: 'center', marginTop: '3rem' }}>
        <iframe
          title="pga-tour-video"
          className="sproutvideo-player"
          src="https://videos.sproutvideo.com/embed/069edcbc1b1cedc08f/2dfab400eb88b4cc"
          width={isMobileSize ? '100%' : '630'}
          height="354"
          frameBorder="0"
          allowFullScreen
        />
      </div>
    );
  } else {
    return <Navigate to="/dashboard/agent" />;
  }
}

export default Video;
