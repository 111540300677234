import { CLEAR_ALL_TOURNAMENT_PAGES, RECEIVE_TOURNAMENT_INFO_PAGE } from '../util/actionTypes';

const tournamentInfoPageReducer = (state = null, { type, pageList }) => {
  switch (type) {
    case RECEIVE_TOURNAMENT_INFO_PAGE:
      let updatedState = {};
      for (let page of pageList) {
        updatedState[page.name] = page;
      }
      return {
        ...state,
        ...updatedState,
      };
    case CLEAR_ALL_TOURNAMENT_PAGES:
      return {};
    default:
      return state;
  }
};

export default tournamentInfoPageReducer;
