import { CLEAR_ALL_COMMITMENTS, CLEAR_PLAYER_COMMITMENT, RECEIVE_COMMITMENT_INFO } from '../util/actionTypes';

export const playerIdTourCodeLookup = (playerId, tourCode) => `${playerId}${tourCode}`;

const commitmentInfoReducer = (state = null, { type, payload }) => {
  switch (type) {
    case RECEIVE_COMMITMENT_INFO:
      return {
        ...state,
        [playerIdTourCodeLookup(payload.playerId, payload.tourCode)]: {
          ...payload,
        },
      };
    case CLEAR_PLAYER_COMMITMENT:
      return {
        ...state,
        [playerIdTourCodeLookup(payload.playerId, payload.tourCode)]: null,
      };
    case CLEAR_ALL_COMMITMENTS:
      return {};
    default:
      return state;
  }
};

export default commitmentInfoReducer;
